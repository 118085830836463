import React from 'react';
import { useIntl } from 'react-intl';

import { Box, HStack, Text, VStack } from '@fhs-legacy/universal-components';
import {
  cardBrandToNameMapping,
  formatExpirationDate,
} from 'components/payments/components/payment-method-option/payment-method-option-types/utils';
import {
  NativePaymentMethod,
  SchemePaymentMethod,
} from 'components/payments/integrations/hooks/types';
import { isCreditCardMethod, isNativeMethod } from 'components/payments/integrations/hooks/utils';
import { theme } from 'styles/configure-theme';

import { CreditBrandIcon } from '../icons/card-icon/card-icon';

interface ICreditOptionTypeProps {
  disableMethod: boolean;
  fullWidthDescription: boolean;
  method: SchemePaymentMethod | NativePaymentMethod;
}

export const CreditOption: React.FC<ICreditOptionTypeProps> = ({
  fullWidthDescription,
  disableMethod,
  method,
}) => {
  const { formatMessage } = useIntl();

  const textColor = disableMethod ? theme.token('text-disabled') : theme.token('text-default');
  const isCreditCard = isCreditCardMethod(method);
  const isNativePaymentMethod = isNativeMethod(method);

  let alias;
  let cardBrand;
  let formattedCardBrand;
  let expirationDate;
  if (isCreditCard) {
    const { brand, expiryMonth, expiryYear, last4 } = method;
    alias = `${formatMessage({ id: 'endsIn' }).toLowerCase()} ${last4}`;
    cardBrand = method.brand;
    formattedCardBrand = cardBrandToNameMapping[brand];
    expirationDate = formatExpirationDate({ expiryMonth, expiryYear, formatMessage });
  } else if (isNativePaymentMethod) {
    alias = {
      APPLE_PAY: formatMessage({ id: 'applePay' }),
      GOOGLE_PAY: formatMessage({ id: 'googlePay' }),
    }[method.type];
  }

  return (
    <HStack space={2}>
      <Box justifyContent="center">
        <CreditBrandIcon brand={cardBrand} type={method.type} />
      </Box>
      {fullWidthDescription ? (
        <HStack alignItems="center" space={2}>
          {isCreditCard && (
            <Text color={textColor} testID="payment-method-card">
              {formattedCardBrand || 'CARD'}
            </Text>
          )}
          <Text color={textColor} textTransform="capitalize">
            {alias}
          </Text>
          {isCreditCard && (
            <Text
              color={textColor}
              testID="payment-method-expirationDate"
              textTransform="capitalize"
            >
              {expirationDate}
            </Text>
          )}
        </HStack>
      ) : (
        <VStack>
          <HStack alignItems="center" space={2}>
            {isCreditCard && (
              <Text color={textColor} testID="payment-method-card">
                {formattedCardBrand || 'CARD'}
              </Text>
            )}
            <Text color={textColor} textTransform="capitalize">
              {alias}
            </Text>
          </HStack>
          {isCreditCard && (
            <HStack>
              <Text
                color={textColor}
                testID="payment-method-expirationDate"
                textTransform="capitalize"
                fontSize="xs"
              >
                {expirationDate}
              </Text>
            </HStack>
          )}
        </VStack>
      )}
    </HStack>
  );
};
