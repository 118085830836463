import { router, useFocusEffect } from 'expo-router';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { OrderDisplayType } from '@fhs/ui/src/components/order-display/types';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useReorder } from '@fhs-legacy/frontend/src/state/order/hooks/use-reorder';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { IHistoricalOrder } from '@rbi-ctg/menu';
import { useGetUserRecentOrder } from 'hooks/use-get-user-recent-order';
import { useOrderDetails } from 'hooks/use-order-details';
import { useUIContext } from 'state/ui';
import logger from 'utils/logger';

import {
  REORDER_DIFFERENT_STORE_SELECTED_LOG,
  REORDER_NO_PREVIOUS_STORE_SELECT_LOG,
} from '../constants/reorder-logs';
import { freshOrderTransformer } from '../fresh-order-transformer';

import { useHomepageReorderEnabled } from './use-homepage-reorder-enabled';

export const useUserRecentOrder = ({
  singleOrder,
  singleOrderId,
}: {
  singleOrder?: IHistoricalOrder;
  singleOrderId?: string;
}) => {
  const { buildImageUrl } = useUIContext();
  const { formatMessage } = useIntl();
  const { store, noStoreSelected, fetchStore, resetStore, isFetchingStore } = useStoreContext();
  const isHomepageReorderEnabled = useHomepageReorderEnabled();

  const shouldSkipRecentOrderQuery = !!singleOrder || !!singleOrderId;
  const shouldSkipOrderDetailsQuery = !isHomepageReorderEnabled || !!singleOrder || !singleOrderId;
  const isHomepage = !singleOrder && !singleOrderId;

  const hideDeliveryCTA = useFlag(LaunchDarklyFlag.TEMP_HIDE_DELIVERY_CTA);
  const deliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  const shouldIgnoreDelivery = hideDeliveryCTA || !deliveryEnabled;

  const {
    loading: recentOrderLoading,
    refetch: refetchUserRecentOrder,
    historicalOrder,
    storeName,
    orderType,
  } = useGetUserRecentOrder({ shouldIgnoreDelivery }, 'no-cache', shouldSkipRecentOrderQuery);

  const {
    loading: orderDetailsLoading,
    order: orderDetails,
    refetch: refetchOrderDetails,
  } = useOrderDetails(singleOrderId, shouldSkipOrderDetailsQuery);

  const selectDeliveryAddress = useCallback(
    ({ address = '', orderId = '' }: { address?: string; orderId?: string }) => {
      router.navigate({
        pathname: formatMessage({ id: 'routes.storeLocator.address' }),
        params: { address, reorderOrderId: orderId },
      });
    },
    [formatMessage]
  );

  useFocusEffect(
    useCallback(() => {
      if (!shouldSkipRecentOrderQuery) {
        refetchUserRecentOrder();
      }

      if (!shouldSkipOrderDetailsQuery) {
        refetchOrderDetails();
      }
    }, [
      refetchUserRecentOrder,
      refetchOrderDetails,
      shouldSkipRecentOrderQuery,
      shouldSkipOrderDetailsQuery,
    ])
  );

  const firstAvailableOrderToReorder = useMemo(() => {
    // Return singleOrder if specified
    if (singleOrder) {
      return singleOrder;
    }

    // Return orderDetails if just the order id was specified
    if (singleOrderId) {
      return orderDetails;
    }

    // Return first order available to reorder
    return historicalOrder;
  }, [historicalOrder, singleOrderId, singleOrder, orderDetails]);

  const recentStoreNumber =
    firstAvailableOrderToReorder?.cart.storeDetails?.storeNumber ||
    firstAvailableOrderToReorder?.cart.storeId;

  const { handleReorderClick, reordering } = useReorder(firstAvailableOrderToReorder);

  const handleStoreSelectAndReorder = useCallback(
    async (storeNumber: string) => {
      resetStore();

      await fetchStore(storeNumber);

      setTimeout(() => {
        /*
        This timeout is to avoid a race condition when switching stores and retrieving
        the updated enable-product-customization-send-defaults flag value.

        This flag can have different values depending on the store, and even if we wait for the store to load, we may
        have a delay for the flag value to be updated.
      */
        handleReorderClick({ skipStoreLocatorRedirect: true, switchingStore: true });
      }, 300);
    },
    [resetStore, fetchStore, handleReorderClick]
  );

  const handlePickupReorder = useCallback(
    (handleDifferentStoreSelectCallback: () => void) => {
      if (noStoreSelected && recentStoreNumber) {
        logger.info({
          message: REORDER_NO_PREVIOUS_STORE_SELECT_LOG,
          context: {
            recentStoreNumber,
          },
        });

        handleStoreSelectAndReorder(recentStoreNumber.toString());
        return;
      }

      if (!!store && store.number !== recentStoreNumber) {
        logger.info({
          message: REORDER_DIFFERENT_STORE_SELECTED_LOG,
          context: {
            recentStoreNumber,
          },
        });

        handleDifferentStoreSelectCallback();
        return;
      }

      handleReorderClick({ skipStoreLocatorRedirect: true });
    },
    [recentStoreNumber, noStoreSelected, store, handleReorderClick, handleStoreSelectAndReorder]
  );

  const handleDeliveryReorder = useCallback(
    (handleDifferentStoreSelectCallback: () => void) => {
      if (noStoreSelected && recentStoreNumber) {
        logger.info({
          message: REORDER_NO_PREVIOUS_STORE_SELECT_LOG,
          context: {
            recentStoreNumber,
          },
        });

        const address = firstAvailableOrderToReorder?.cart.storeAddress.addressLine1 ?? '';
        selectDeliveryAddress({ address, orderId: firstAvailableOrderToReorder?.rbiOrderId });
        return;
      }

      if (!!store && store.number !== recentStoreNumber) {
        logger.info({
          message: REORDER_DIFFERENT_STORE_SELECTED_LOG,
          context: {
            recentStoreNumber,
          },
        });

        handleDifferentStoreSelectCallback();
        return;
      }

      handleReorderClick();
    },
    [
      recentStoreNumber,
      noStoreSelected,
      store,
      handleReorderClick,
      selectDeliveryAddress,
      firstAvailableOrderToReorder,
    ]
  );

  const transformedOrder = useMemo(
    () =>
      freshOrderTransformer({
        storeName,
        orderType: isHomepage ? (orderType as OrderDisplayType) : OrderDisplayType.REORDER,
        serverOrder: firstAvailableOrderToReorder,
        buildImageUrl,
        formatMessage,
      }),
    [storeName, orderType, buildImageUrl, firstAvailableOrderToReorder, formatMessage, isHomepage]
  );

  return {
    store,
    isReorderingLoading: reordering || isFetchingStore,
    firstAvailableOrderToReorder,
    recentOrder: transformedOrder,
    handleStoreSelectAndReorder,
    handleDeliveryReorder,
    handlePickupReorder,
    handleReorderAfterStoreSelect: handleReorderClick,
    selectDeliveryAddress,
    recentOrderLoading,
    recentStoreNumber,
    orderDetailsLoading,
  };
};
