import { useGlobalSearchParams } from 'expo-router';

import { usePathname } from 'hooks/use-pathname';
import { IAuthRouteParams } from 'state/auth/types';
import { routes } from 'utils/routing';

import { useAuthFlowStaticPages } from '../hooks/use-auth-flow-static-pages';

type IUseAuthFlow = () => {
  isInAuthFlow: boolean;
  activeRouteIsSignIn: boolean;
  showOtpForm: boolean;
  attemptedSignUpWithExistingEmail: boolean;
  hasSignedOut: boolean;
  errorMessage: string | undefined;
  triggerSignInAccessibility: boolean;
  email: string | undefined;
};

const useAuthFlow: IUseAuthFlow = () => {
  const params = useGlobalSearchParams<IAuthRouteParams>();
  const pathname = usePathname();
  const authFlowStaticPages = useAuthFlowStaticPages();

  // @ts-ignore
  const staticPageParams: { staticPagePath?: string } = params?.params || params || {};
  const isModalStaticPageRoute =
    pathname.startsWith(routes.staticPage) ||
    pathname.startsWith(routes.modalStaticPage) ||
    !!staticPageParams.staticPagePath;

  const isAuthFlowStaticPage = authFlowStaticPages.includes(pathname);

  const isSignUp = pathname === routes.signUp;
  const isSignIn = pathname === routes.signIn || params?.activeRouteIsSignIn === 'true';
  const showOtpForm = pathname === routes.confirmOtp;

  const attemptedSignUpWithExistingEmail = params?.attemptedSignUpWithExistingEmail === 'true';
  const hasSignedOut = params?.hasSignedOut === 'true';
  const errorMessage = params?.errorMessage;
  const triggerSignInAccessibility = params?.triggerSignInAccessibility === 'true';
  const email = params?.email?.toString();

  return {
    isInAuthFlow: isSignUp || isSignIn || isModalStaticPageRoute || isAuthFlowStaticPage,
    activeRouteIsSignIn: isSignIn,
    showOtpForm,
    attemptedSignUpWithExistingEmail,
    hasSignedOut,
    errorMessage: errorMessage?.toString(),
    triggerSignInAccessibility,
    email: email?.toString(),
  };
};

export default useAuthFlow;
