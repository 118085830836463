import React from 'react';

import { Spinner } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import { BlankScreen } from 'components/blank-screen';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useUserPaymentInformationQuery } from 'generated/graphql-gateway';

import { confirmPickupIntegrationMapping } from './types';

export interface IConfirmPickupEntryPointProps {
  serverOrder: IServerOrder;
}

export const ConfirmPickupEntryPoint: React.FC<IConfirmPickupEntryPointProps> = ({
  serverOrder,
}) => {
  const { data, loading } = useUserPaymentInformationQuery();

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  const paymentIntegration = data?.paymentConfig.frontendIntegration;
  if (!paymentIntegration) {
    return <BlankScreen context={{ reason: 'paymentIntegration is null' }} />;
  }

  // Determine which payment integration to display for handling a user's payment methods.
  const ConfirmPickupIntegration = confirmPickupIntegrationMapping[paymentIntegration];

  return <ConfirmPickupIntegration serverOrder={serverOrder} />;
};
