import React, { useState } from 'react';

import { Pressable } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import {
  PaymentMethod,
  PaymentMethodOptionTypes,
} from 'components/payments/integrations/hooks/types';
import { addMethodWhen, isGiftCardMethod } from 'components/payments/integrations/hooks/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { PaymentMethodOption } from '../payment-method-option';
import { addCreditCardDetails, addGiftCardDetails } from '../payment-method-option/constants';

import { PaymentMethodDropdownToggle } from './payment-method-dropdown-toggle/payment-method-dropdown-toggle';
import {
  StyledPaymentMethodDropdown,
  StyledPaymentMethodOptionDivider,
  StyledPaymentMethodOptionWrapper,
  StyledToggleWrapper,
} from './styled';

interface IReducePaymentMethods {
  unselectedPaymentMethods: PaymentMethod[];
  userHasGiftCard: boolean;
}

interface IPaymentMethodDropdownProps {
  handleSelection: (method: PaymentMethod) => void;
  loading: boolean;
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod;
  enableAddCreditCardOption?: boolean;
  enableAddGiftCardOption?: boolean;
}

export const PaymentMethodDropdown: React.FC<IPaymentMethodDropdownProps> = ({
  loading,
  handleSelection,
  paymentMethods,
  selectedPaymentMethod,
  enableAddCreditCardOption = true,
  enableAddGiftCardOption = true,
}) => {
  // State
  const [collapsed, setCollapsed] = useState(true);

  // Get the list of payment methods that are not selected and if the user has a gift card
  const { unselectedPaymentMethods, userHasGiftCard } =
    paymentMethods.reduce<IReducePaymentMethods>(
      (acc, method) => {
        if (method.id !== selectedPaymentMethod.id) {
          acc.unselectedPaymentMethods.push(method);
        }
        if (isGiftCardMethod(method)) {
          acc.userHasGiftCard = true;
        }
        return acc;
      },
      { unselectedPaymentMethods: [], userHasGiftCard: false }
    );

  // Determine if the user is allowed to select the add credit card option
  const isAddCreditCardOptionAvailable =
    enableAddCreditCardOption &&
    selectedPaymentMethod.id !== PaymentMethodOptionTypes.ADD_CREDIT_CARD;

  // Determine if the user is allowed to select the add gift card option (only one gift card per user is allowed at this time)
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const isAddGiftCardOptionAvailable =
    enableAddGiftCardOption &&
    enableGiftCard &&
    !userHasGiftCard &&
    selectedPaymentMethod.id !== PaymentMethodOptionTypes.ADD_GIFT_CARD;

  // Add the add payment method options to the end of the options list
  const unselectedPaymentMethodOptions = [
    ...unselectedPaymentMethods,
    ...addMethodWhen(isAddCreditCardOptionAvailable, addCreditCardDetails),
    ...addMethodWhen(isAddGiftCardOptionAvailable, addGiftCardDetails),
  ];

  const toggleCollapsed = () => {
    // only toggle dropdown if there are other payment method options available
    if (unselectedPaymentMethodOptions.length) {
      setCollapsed(collapsed => !collapsed);
    }
  };

  // Selects a payment method option (either a user's existing payment method or allows the user to add a payment method)
  const onPress = (method: PaymentMethod) => {
    handleSelection(method);
    setCollapsed(true);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  return (
    <StyledPaymentMethodDropdown>
      <Pressable onPress={toggleCollapsed}>
        <PaymentMethodOption
          disableDelete
          paymentMethod={selectedPaymentMethod}
          onPress={toggleCollapsed}
          selected
        />
        {unselectedPaymentMethodOptions.length && (
          <StyledToggleWrapper>
            <PaymentMethodDropdownToggle collapsed={!collapsed} onPress={toggleCollapsed} />
          </StyledToggleWrapper>
        )}
      </Pressable>
      {!collapsed &&
        unselectedPaymentMethodOptions.map(method => (
          <StyledPaymentMethodOptionWrapper key={method.id}>
            <StyledPaymentMethodOptionDivider />
            <PaymentMethodOption
              paymentMethod={method}
              onPress={onPress}
              hideBorder
              disableDelete
            />
          </StyledPaymentMethodOptionWrapper>
        ))}
    </StyledPaymentMethodDropdown>
  );
};
