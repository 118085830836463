import { router, useLocalSearchParams } from 'expo-router';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { StoredPaymentMethodsDocument, useMergePrepaidMutation } from 'generated/graphql-gateway';
import useErrorModal from 'hooks/use-error-modal';
import { useToast } from 'hooks/use-toast';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';

export const useFirstDataSuccessTransferGiftCard = () => {
  const [newBalance, setNewBalance] = useState<number | null>(null);
  const { feCountryCode } = useLocale();
  const { formatMessage } = useIntl();
  const params = useLocalSearchParams<{
    transferCardBalance?: string;
    cardNumber?: string;
    currentBalance?: string;
  }>();
  const toast = useToast();

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
  });

  const handleDismiss = useCallback(() => {
    router.back();
  }, []);

  const [mergePrepaidMutation, { loading }] = useMergePrepaidMutation({
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  const transferCard = useCallback(async () => {
    try {
      const { data: mergePrepaidData } = await mergePrepaidMutation({
        variables: { input: { sourceCardNumber: params.cardNumber || '' } },
      });

      const newBalance = mergePrepaidData?.mergePrepaid.prepaidDetails.balance ?? null;
      setNewBalance(newBalance);

      toast.show({
        text: formatMessage({ id: 'transferBalanceCompletedMessage' }),
        variant: 'positive',
      });

      handleDismiss();
    } catch (error) {
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
      openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
      logger.error({ error, message: 'Error transfer gift card' });
    }
  }, [
    formatMessage,
    handleDismiss,
    mergePrepaidMutation,
    openErrorDialog,
    params.cardNumber,
    toast,
  ]);

  return {
    transferCard,
    isLoading: loading,
    ErrorDialog,
    handleDismiss,
    transferCardBalance:
      typeof params.transferCardBalance === 'string'
        ? parseInt(params.transferCardBalance, 10)
        : undefined,
    cardNumber: params.cardNumber,
    currentBalance:
      typeof params.currentBalance === 'string' ? parseInt(params.currentBalance, 10) : undefined,
    newBalance,
  };
};
