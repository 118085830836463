import { ApolloError } from '@apollo/client';

import { ICartEntry } from '@rbi-ctg/menu';
import {
  ILoyaltyBenefit,
  ILoyaltyBenefitSwap,
  ILoyaltyUserQuery,
  IPromotionFragment,
  IRewardFragment,
  IRuleConditionEvaluation,
  LoyaltyBenefitType,
  OfferRedemptionType,
  PaymentMethod,
  useLoyaltyUserQuery,
} from 'generated/graphql-gateway';
import {
  INotificationDetailsFragment,
  IRewardOrSystemwideOfferOrSystemwidePromotion,
  useFeatureSortedLoyaltyOffersQuery,
} from 'generated/sanity-graphql';

import { IEngineRewardsMap, LoyaltyAppliedOffer, LoyaltyOffer } from '../types';

export type LoyaltyUser = ILoyaltyUserQuery['loyaltyUserV2'];

export type AvailableRewards = {
  [id: string]: IRewardFragment;
};

export interface IUseLoyalty {
  loyaltyEnabled: boolean;
  loyaltyLoading: boolean;
  loyaltyUser: LoyaltyUser | null;
  refetchLoyaltyUser: ReturnType<typeof useLoyaltyUserQuery>['refetch'];
  claimPointsEnabled: boolean;
}

export type UseLoyaltyRewards = (loyaltyUser: LoyaltyUser | null) => IUseLoyaltyRewards;

export interface IAppliedReward {
  timesApplied: number;
  rewardId: string;
  pointCost: number;
  rewardBenefitId: string;
  sanityId?: string;
}

export interface IAppliedRewards {
  [cartId: string]: IAppliedReward;
}
export interface IParsedLoyaltyReward {
  cartId: string;
  rewardId: string;
  timesApplied: number;
  sanityId?: string;
}

export interface IParseAppliedRewards {
  appliedRewardsArray: IParsedLoyaltyReward[];
  timesRewardApplied: { [rewardId: string]: number };
}

export interface IEvaluateLoyaltyUserIncentivesOptions {
  loyaltyId: string;
  appliedLoyaltyOffers: LoyaltyAppliedOffer[];
  cartEntries?: ICartEntry[];
  appliedRewards?: IAppliedRewards;
  subtotalAmount?: number;
  paymentMethod?: PaymentMethod | null;
  skipTimeValidation?: boolean;
  pickUpTime?: string;
  appliedLoyaltyDiscountOffer?: LoyaltyOffer;
}

export interface IEvaluateEligibleItemsOptions {
  loyaltyId: string;
  appliedLoyaltyOffers: LoyaltyAppliedOffer[];
  cartEntries: ICartEntry[];
  appliedRewards: IAppliedRewards;
}
export interface IUseLoyaltyOffers {
  queryLoyaltyUserStandardOffers: (options: IQueryLoyaltyUserOffersOptions) => void;
  queryLoyaltyUserSurpriseOffers: (options: IQueryLoyaltyUserOffersOptions) => void;
  loyaltyOffersEnabled: boolean;
  loyaltySurpriseOffersEnabled: boolean;
  queryLoyaltyOffers: (options: { subtotalAmount?: number }) => void;
  evaluateLoyaltyUserIncentives: (
    options: IEvaluateLoyaltyUserIncentivesOptions
  ) => Promise<IncentiveEvaluationMap | null>;
  evaluateEligibleItems: (
    options: IEvaluateEligibleItemsOptions
  ) => Promise<IncentiveEligibleItem[]>;
  refetchCmsOffers: ReturnType<typeof useFeatureSortedLoyaltyOffersQuery>['refetch'];
  cmsOffersLoading: boolean;
  isOfferValidationError: boolean;
  isOfferEvaluating: boolean;
  setOfferValidationError: (hasError: boolean) => void;
}

export interface IEvaluateLoyaltyUserRewardsParams {
  loyaltyId: string;
  appliedLoyaltyRewards: IAppliedRewards;
  cartEntries: ICartEntry[];
  subtotalAmount: number;
  paymentMethod?: PaymentMethod | null;
}

export interface IUseLoyaltyRewards {
  refetchRewards: (loyaltyId: string) => void;
  engineRewardsMap: IEngineRewardsMap;
  evaluateLoyaltyUserRewards: (
    props: IEvaluateLoyaltyUserRewardsParams
  ) => Promise<IncentiveEvaluationMap | null>;
}

export interface IUseGetAvailableRewards {
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => IRewardFragment | undefined;
}

export interface IRewardAction {
  cartId: string;
  rewardBenefitId: string;
  quantity?: number;
}

export interface IRemoveAppliedRewardsAction {
  cartIds: string[];
}

// Swap type guard
export const isSwap = (benefit: ILoyaltyBenefit): benefit is ILoyaltyBenefitSwap => {
  return (benefit as ILoyaltyBenefitSwap)?.value && benefit.type === LoyaltyBenefitType.SWAP;
};

export type NotificationDetails = INotificationDetailsFragment & {
  id: IRewardOrSystemwideOfferOrSystemwidePromotion['loyaltyEngineId'];
};

export interface IUseLoyaltyNotifications {
  notifications: NotificationDetails[];
  loadingNotifications: boolean;
}
export interface IQueryLoyaltyUserOffersOptions {
  loyaltyId: string;
  cartEntries?: ICartEntry[];
  appliedRewards?: IAppliedRewards;
  subtotalAmount?: number;
  redemptionTypes?: OfferRedemptionType[];
  appliedLoyaltyOffers?: LoyaltyAppliedOffer[];
}

export type LoyaltyNotifications = (
  loyaltyUserId: string,
  loyaltyOffersEnabled: boolean
) => IUseLoyaltyNotifications;

export type RedeemableItem = {
  readonly id: string;
};

export type RedeemableItems = readonly (RedeemableItem | null)[] | null;

export type IIncentiveEvaluationResult = Omit<IRuleConditionEvaluation, '__typename'>;

export type IncentiveEvaluationMap = { [incentiveId: string]: IIncentiveEvaluationResult[] };

export type IncentiveEligibleItem = { lineId: string; incentiveId: string; timesApplied: number };

export enum IncentiveEvaluationErrorCodes {
  UNSATISFIED_CART_REQUIREMENTS = 'unsatisfied-cart-requirements',
  WITHIN_COOL_DOWN_PERIOD = 'within-cool-down-period',
  EXCEEDS_LIMIT_PER_ORDER = 'exceeds-limit-per-order',
  INVALID_SERVICE_MODE = 'invalid-service-mode',
  INVALID_STORE_ID = 'invalid-store-id',
  NOT_FIRST_ORDER = 'not-first-order',
  NOT_USER_BIRTHDAY = 'not-user-birthday',
  INVALID_END_DATE = 'invalid-end-date',
  INVALID_START_DATE = 'invalid-start-date',
  INVALID_DAY_OF_THE_WEEK = 'invalid-day-of-the-week',
  INVALID_STACKING = 'invalid-stacking',
  EXCEEDS_MAX_REDEMPTION = 'exceeds-max-redemption',
  BELOW_MINIMUM_SPEND = 'below-minimum-spend',
  INVALID_PAYMENT_METHOD = 'invalid-payment-method',
  INSUFFICIENT_POINT_BALANCE = 'insufficient-point-balance',
  INVALID_SWAPS = 'invalid-swaps',
  INVALID_TIME_SPAN = 'invalid-time-span',
  USER_ENROLLMENT = 'user-enrollment',
  OUT_OF_DAY_PART = 'out-of-day-part',
  NOT_AVAILABLE_IN_STORE = 'not-available-in-store',
  OFFER_NOT_AVAILABLE = 'offer-not-available',
}

export const FixableEvaluationErrors = new Set([
  IncentiveEvaluationErrorCodes.BELOW_MINIMUM_SPEND,
  IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS,
  IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD,
  IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE,
]);

export type IUseLoyaltyUserPromotions = () => {
  availablePromotionsMap: Record<string, IPromotionFragment>;
};

export interface IUseLoyaltyUserState {
  loyaltyUser: LoyaltyUser;
  error: ApolloError | undefined;
  refetch: ReturnType<typeof useLoyaltyUserQuery>['refetch'];
  loading: boolean;
  loyaltyUserReady: boolean;
}
