import { useCallback } from 'react';

import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { splitExpiry } from 'components/payments/components/add-credit-card-form-inputs/utils';
import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import {
  IEncryptionConfig,
  IEncryptionResult,
} from 'components/payments/integrations/orbital/components/encryption/types';
import { IOrder, IPaymentInput } from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { IOrderInformation } from 'pages/cart/payments/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IRequestOneTimePayment {
  creditCardFormValues: IAddCreditCardFormValues;
  encryptionData: IEncryptionResult;
  orderInformation: IOrderInformation;
}

export const useOrbitalOneTimePayment = (rbiOrderId: string) => {
  const encryptionConfig: IEncryptionConfig = useConfigValue({
    key: 'orbital',
    defaultValue: null,
    isRegionalized: true,
  });
  const { handleCommitOrder } = useCommitOrder(rbiOrderId);
  const oneTimePaymentFlowEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT
  );
  const requestOneTimePayment = useCallback(
    async ({
      creditCardFormValues,
      encryptionData,
      orderInformation,
    }: IRequestOneTimePayment): Promise<IOrder | undefined> => {
      // Build the payment input for a one time payment method
      const { cardBrand, cardNumber, expiry, nameOnCard } = creditCardFormValues;
      const { expiryMonth, expiryYear } = splitExpiry(expiry ?? '');
      const orbitalPaymentInput: IPaymentInput = {
        orbitalInput: {
          encryptedPayload: {
            bin: cardNumber.slice(0, 6),
            cardBrand: cardBrand.toLowerCase(),
            encryptedCardNum: encryptionData.cryptCard,
            encryptedCvv: encryptionData.cryptCvv,
            expiryMonth,
            expiryYear,
            pieFormat: encryptionConfig.pieFormat,
            pieIntegrityCheck: encryptionData.integrityCheck,
            pieKeyID: encryptionData.keyId,
            pieMode: encryptionData.mode,
            piePhaseId: String(encryptionData.phase),
            pieSubscriberId: encryptionData.subscriberId,
          },
          savePaymentMethod: oneTimePaymentFlowEnabled ? creditCardFormValues.saveCard : false,
        },
        fullName: nameOnCard ?? '',
      };

      // Handle committing the order
      return handleCommitOrder({
        cardBrand,
        orderInformation,
        payment: orbitalPaymentInput,
      });
    },
    [encryptionConfig, handleCommitOrder]
  );

  return {
    requestOneTimePayment,
  };
};
