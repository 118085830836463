import { useIntl } from 'react-intl';

import { Badge, CalendarIcon, Label, Wrapper } from './styled';

interface IFireOrderBage {
  isDelivery: boolean;
}

export const FireOrderBadge = ({ isDelivery = false }: IFireOrderBage) => {
  const { formatMessage } = useIntl();

  const labelText = isDelivery
    ? formatMessage({ id: 'scheduleForDelivery' })
    : formatMessage({ id: 'scheduleForPickup' });

  return (
    <Wrapper>
      <Badge>
        <CalendarIcon />
        <Label>{labelText}</Label>
      </Badge>
    </Wrapper>
  );
};
