import React from 'react';

import { PaymentMethodFlatListWithButton } from 'components/payments/components/payment-method-flat-list-with-button';
import { usePaymentMethodsAdapter } from 'components/payments/integrations/hooks/use-payment-methods-adapter';
import { useUpdateDefaultPaymentMethod } from 'components/payments/integrations/hooks/use-update-default-payment-method';

import { useDeletePaymentMethod } from '../hooks/use-delete-payment-method';

const OrbitalPaymentMethods: React.FC = () => {
  // Hooks
  const { availablePaymentMethods, defaultPaymentMethod, loading } = usePaymentMethodsAdapter();

  const { isDeleting, deletePaymentMethod } = useDeletePaymentMethod();
  const { updateDefaultPaymentMethod } = useUpdateDefaultPaymentMethod();

  return (
    <>
      <PaymentMethodFlatListWithButton
        availablePaymentMethods={availablePaymentMethods}
        defaultPaymentMethod={defaultPaymentMethod}
        deletePaymentMethod={deletePaymentMethod}
        loading={loading || isDeleting}
        updateDefaultPaymentMethod={updateDefaultPaymentMethod}
      />
    </>
  );
};

export default OrbitalPaymentMethods;
