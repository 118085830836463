import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Checkbox } from '@fhs-legacy/universal-components';
import { CountrySelect } from 'components/country-select';
import { CreditBrandIcon } from 'components/payments/components/payment-method-option/payment-method-option-types';
import { TextInput, TextInputMask } from 'components/ucl/text-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useCreditCardFormProps from 'hooks/form/use-credit-card-form-props';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';

import { SAVE_CARD_DISCLAIMER } from './constants';
import { creditCardNumberMask, expiryMask } from './masks';
import {
  CardNumberWrapper,
  FormContainer,
  StyledBillingAptWrapper,
  StyledBillingCityWrapper,
  StyledBillingStateWrapper,
  StyledBillingZipWrapper,
  StyledCountrySelectWrapper,
  StyledCvvWrapper,
  StyledExpiryCVVWrapper,
  StyledExpiryWrapper,
  StyledNameOnCardWrapper,
  StyledSaveCardContainer,
  StyledStreetAddressWrapper,
} from './styled';
import { IAddCreditCardFormErrors, IAddCreditCardFormValues } from './types';
import { excludeNumeric, getErrorMessage } from './utils';

export interface IAddCreditCardFormInputsProps {
  errors: IAddCreditCardFormErrors;
  isDelivery?: boolean;
  onChange: (name: keyof IAddCreditCardFormValues, value: string) => void;
  showSaveCard?: boolean;
  values: IAddCreditCardFormValues;
  $withBorder?: boolean;
  $withPadding?: boolean;
}

export const AddCreditCardFormInputs: FC<IAddCreditCardFormInputsProps> = ({
  errors,
  values,
  isDelivery = false,
  onChange,
  showSaveCard = true,
  $withBorder = false,
  $withPadding = false,
}) => {
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const { feCountryCode } = useLocale();
  // If the country field is hidden from the form, use the user's current locale
  const country = paymentFieldVariations.country ? values.billingCountry : feCountryCode;

  // retrieve additional credit card form props
  const { postalCodeInputProps, regionInputProps } = useCreditCardFormProps(country ?? undefined);
  const { formatMessage } = useIntl();

  const errorMessage = getErrorMessage({ errors, formatMessage });

  // NativeBase is having a weird behavior with the Checkbox
  // The onChange handler passed to it is always stale, unless we force a re-render by changing its key
  const checkboxKey = React.useMemo(() => JSON.stringify(values), [values]);

  return (
    <>
      {!!errorMessage && <VisuallyHidden accessibilityLabel={errorMessage} role="alert" />}
      <FormContainer
        $withPadding={$withPadding}
        $withBorder={$withBorder}
        accessibilityLabel="credit-card-form-inputs"
        marginTop="$6"
      >
        {paymentFieldVariations.name && (
          <StyledNameOnCardWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'nameOnCard' })}
              onChangeText={(value: string) => onChange('nameOnCard', value)}
              label={formatMessage({ id: 'nameOnCard' })}
              value={values.nameOnCard}
              required
              testID="nameOnCard"
              errorMessage={errors.nameOnCard}
              autoComplete="name"
            />
          </StyledNameOnCardWrapper>
        )}
        {paymentFieldVariations.cardNumber && (
          <CardNumberWrapper>
            <TextInputMask
              aria-label={formatMessage({ id: 'creditCardNumber' })}
              onChangeText={value => onChange('cardNumber', value)}
              label={formatMessage({ id: 'creditCardNumber' })}
              value={values.cardNumber}
              required
              testID="cardNumber"
              errorMessage={errors.cardBrand || errors.cardNumber}
              autoComplete="cc-number"
              keyboardType="numeric"
              // @ts-expect-error TS(2322) FIXME: Type '(cardNumber: string) => any[]' is not assign... Remove this comment to see the full error message
              mask={creditCardNumberMask}
              endIcon={
                values.isCardNumberValid ? <CreditBrandIcon brand={values.cardBrand} /> : null
              }
            />
          </CardNumberWrapper>
        )}
        <StyledExpiryCVVWrapper>
          {paymentFieldVariations.expiration && (
            <StyledExpiryWrapper>
              <TextInputMask
                autoComplete="cc-exp"
                errorMessage={errors.expiry}
                keyboardType="numeric"
                label={formatMessage({ id: 'cardExpiration' })}
                mask={expiryMask}
                onChangeText={(value: string) => {
                  onChange('expiry', value);
                }}
                required
                testID="expiry"
                value={values.expiry || ''}
              />
            </StyledExpiryWrapper>
          )}
          {paymentFieldVariations.cvv && (
            <StyledCvvWrapper>
              <TextInput
                aria-label={formatMessage({ id: 'cvv' })}
                onChangeText={value => onChange('cvv', value)}
                label={formatMessage({ id: 'cvv' })}
                value={values.cvv || ''}
                required
                testID="cvv"
                errorMessage={errors.cvv}
                autoComplete="cc-csc"
                keyboardType="numeric"
                // TODO - RN input should support pattern
                // pattern="[0-9]*"
                maxLength={4}
              />
            </StyledCvvWrapper>
          )}
        </StyledExpiryCVVWrapper>
        {isDelivery && (
          <Checkbox
            key={checkboxKey}
            onChange={value => onChange('billingAddressSameAsDelivery', value ? 'true' : 'false')}
            value={String(values.billingAddressSameAsDelivery)}
            isChecked={values.billingAddressSameAsDelivery}
            testID="billing-same-as-delivery"
          >
            {formatMessage({ id: 'billingAddressIsSameAsDeliveryAddress' })}
          </Checkbox>
        )}
      </FormContainer>
      <FormContainer $withPadding={$withPadding} $withBorder={$withBorder}>
        {paymentFieldVariations.addressLine1 && (
          <StyledStreetAddressWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'streetAddress' })}
              onChangeText={value => onChange('billingStreetAddress', value)}
              label={formatMessage({ id: 'streetAddress' })}
              value={values.billingStreetAddress || ''}
              required
              testID="billingStreetAddress"
              errorMessage={errors.billingStreetAddress}
              autoComplete="street-address"
              isDisabled={isDelivery && values.billingAddressSameAsDelivery}
            />
          </StyledStreetAddressWrapper>
        )}
        {paymentFieldVariations.addressLine2 && (
          <StyledBillingAptWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'apartment' })}
              onChangeText={value => onChange('billingApt', value)}
              label={formatMessage({ id: 'aptOrSuite' })}
              value={values.billingApt || ''}
              testID="billingApt"
              errorMessage={errors.billingApt}
              autoComplete="street-address"
              isDisabled={isDelivery && values.billingAddressSameAsDelivery}
            />
          </StyledBillingAptWrapper>
        )}
        {paymentFieldVariations.city && (
          <StyledBillingCityWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'city' })}
              label={formatMessage({ id: 'city' })}
              onChangeText={value => onChange('billingCity', value)}
              value={values.billingCity || ''}
              required
              testID="billingCity"
              errorMessage={errors.billingCity}
              autoComplete="postal-address-locality"
              isDisabled={isDelivery && values.billingAddressSameAsDelivery}
            />
          </StyledBillingCityWrapper>
        )}
        {paymentFieldVariations.state && (
          <StyledBillingStateWrapper>
            <TextInput
              accessibilityLabel={formatMessage({ id: 'state' })}
              onChangeText={value => onChange('billingState', value)}
              value={excludeNumeric(values.billingState ?? '')}
              required
              testID="billingState"
              errorMessage={errors.billingState}
              autoComplete="postal-address-region"
              isDisabled={isDelivery && values.billingAddressSameAsDelivery}
              {...regionInputProps}
            />
          </StyledBillingStateWrapper>
        )}
        {paymentFieldVariations.zip && (
          <StyledBillingZipWrapper>
            {/* Additional props are spread: label, maxLength, pattern */}
            <TextInput
              accessibilityLabel={formatMessage({ id: 'zipCode' })}
              onChangeText={value => onChange('billingZip', value)}
              value={values.billingZip}
              required
              testID="billingZip"
              errorMessage={errors.billingZip}
              autoComplete="postal-code"
              maxLength={10}
              isDisabled={isDelivery && values.billingAddressSameAsDelivery}
              {...postalCodeInputProps}
            />
          </StyledBillingZipWrapper>
        )}
        {paymentFieldVariations.country && (
          <StyledCountrySelectWrapper>
            <CountrySelect
              label={formatMessage({ id: 'country' })}
              name="billingCountry"
              testID="billingCountry"
              required
              value={values.billingCountry ?? ''}
              onChange={value => onChange('billingCountry', value)}
              disabled={!!(isDelivery && values.billingCountry)}
            />
          </StyledCountrySelectWrapper>
        )}
      </FormContainer>
      {showSaveCard && (
        <StyledSaveCardContainer>
          <Checkbox
            testID="saveCard"
            key={checkboxKey}
            value={String(values.saveCard)}
            isChecked={values.saveCard}
            onChange={value => onChange('saveCard', value ? 'true' : 'false')}
            accessibilityLabel={formatMessage({ id: SAVE_CARD_DISCLAIMER })}
          >
            {formatMessage({ id: SAVE_CARD_DISCLAIMER })}
          </Checkbox>
        </StyledSaveCardContainer>
      )}
    </>
  );
};
