import { type ReactNode, createContext, forwardRef, useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable, type PressableProps } from '../pressable/pressable';

import { ButtonActivityIndicator } from './button-activity-indicator';
import type { ButtonSize, ButtonType } from './types';

export type ButtonContextType = {
  type: ButtonType;
  size: ButtonSize;
  disabled: boolean;
  loading: boolean;
};

const ButtonContext = createContext<ButtonContextType>({
  type: 'solid',
  size: 'md',
  disabled: false,
  loading: false,
});

export function useButtonContext() {
  return useContext(ButtonContext);
}

export type ButtonProps = Omit<PressableProps, 'children'> & {
  children: ReactNode;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  sharedTransitionTag?: string;
};

export const ButtonFrame = forwardRef<View, ButtonProps>(function ButtonFrame(
  {
    type = 'solid',
    size = 'md',
    disabled = false,
    loading = false,
    children,
    ...pressableProps
  }: ButtonProps,
  forwardedRef
) {
  return (
    <ButtonContext.Provider
      value={useMemo(
        () => ({
          type,
          size,
          disabled,
          loading,
        }),
        [type, size, disabled, loading]
      )}
    >
      <Pressable
        ref={forwardedRef}
        {...pressableProps}
        disabled={disabled || loading}
        style={[
          buttonStyles.baseline,
          buttonStyles[type],
          buttonStyles[size],
          pressableProps.style,
        ]}
        borderRadius={800}
        hoveredStyle={[buttonHoveredStyles[type], pressableProps.hoveredStyle]}
        pressedStyle={[buttonPressedOrFocusedStyles[type], pressableProps.pressedStyle]}
        focusedStyle={[buttonPressedOrFocusedStyles[type], pressableProps.focusedStyle]}
        disabledStyle={!loading && [buttonDisabledStyles[type], pressableProps.disabledStyle]}
      >
        {loading && <ButtonActivityIndicator size={size} type={type} />}
        {children}
      </Pressable>
    </ButtonContext.Provider>
  );
});

const buttonStyles = StyleSheet.create({
  baseline: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
  solid: {
    backgroundColor: tokens.colors.$houseRedDarken,
    borderColor: tokens.colors.$houseRedDarken,
  },
  outline: {
    backgroundColor: tokens.colors.$transparent,
    borderColor: tokens.colors.$houseRedDarken,
  },
  ghost: {
    backgroundColor: tokens.colors.$transparent,
    borderColor: tokens.colors.$transparent,
  },
  xs: {
    paddingHorizontal: 8,
    minHeight: 31,
  },
  sm: {
    paddingHorizontal: 8,
    minHeight: 34,
  },
  md: {
    paddingHorizontal: 8,
    minHeight: 37,
  },
  lg: {
    paddingHorizontal: 8,
    minHeight: 40,
  },
  xl: {
    paddingHorizontal: 12,
    minHeight: 48,
  },
});

const buttonHoveredStyles = StyleSheet.create({
  solid: {
    backgroundColor: tokens.colors.$houseRed,
    borderColor: tokens.colors.$houseRed,
  },
  outline: {
    backgroundColor: tokens.colors.$houseRed10,
  },
  ghost: {
    backgroundColor: tokens.colors.$houseRed10,
  },
});

const buttonPressedOrFocusedStyles = StyleSheet.create({
  solid: {
    backgroundColor: tokens.colors.$houseRedDarken,
    borderColor: tokens.colors.$houseRedDarken,
  },
  outline: {
    backgroundColor: tokens.colors.$transparent,
  },
  ghost: {
    backgroundColor: tokens.colors.$transparent,
  },
});

const buttonDisabledStyles = StyleSheet.create({
  solid: {
    backgroundColor: tokens.colors.$black10,
    borderColor: tokens.colors.$black10,
  },
  outline: {
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$transparent,
  },
  ghost: {},
});
