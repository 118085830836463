import { useEffect, useMemo, useState } from 'react';

import useEffectOnce from 'hooks/use-effect-once';
import en from 'state/translations/en.json';
import * as DatadogLogger from 'utils/datadog';
import { ISOs } from 'utils/form/constants';
import { getMessagesForLanguage, loadLanguage } from 'utils/intl/language';
import { loadRegion } from 'utils/intl/region';

import { LOCALE_DATA } from './constants';
import { LOCALES } from './types';

/*
 * This Region hook has a few specs to it:
 *
 * 1. It is preloaded with the users region from their Localization settings
 * 2. It exposes APIs to allows the UI to update the region manually - however they are not enabled in native to do anything.
 * 3. The preloaded locale (language + region) must exist in our list of `SORTED_PROD_SUPPORTED_LOCALES` or fallback to a value on that list.
 */
const useIntl = () => {
  const language = useMemo(() => loadLanguage(), []);
  const region = useMemo(() => loadRegion(), []);
  const locale = `${language}-${region}` as LOCALES;

  const [messages, setMessages] = useState<Record<string, string>>(en);

  // We track the user's locale (region + language) in logger errors
  // some errors might occur in specific langauges, so its crucial
  // to know all info on how to reproduce.
  useEffect(() => {
    DatadogLogger.addContext('page_locale', locale);
  }, [locale]);

  useEffectOnce(() => {
    getMessagesForLanguage(language, region).then(setMessages);
  });

  const { dateFormat } = LOCALE_DATA[locale];

  const value = useMemo(
    () => ({
      region,
      feCountryCode: ISOs[region],
      language,
      locale,
      messages,
      dateFormat,
    }),
    [region, language, locale, messages, dateFormat]
  );

  return value;
};

export default useIntl;
