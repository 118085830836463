import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Text } from '@fhs/ui';
import ConfirmDialog from 'components/confirm-dialog';
import { useOrderContext } from 'state/order';

type SwitchServiceModeDialogProps = {
  showDialog: boolean;
  onConfirm?: () => void;
  onDismiss: () => void;
};

export const SwitchServiceModeDialog = ({
  showDialog,
  onConfirm = () => {},
  onDismiss,
}: SwitchServiceModeDialogProps) => {
  const { formatMessage } = useIntl();
  const { emptyCart } = useOrderContext();

  const handleChangeServiceModeConfirmation = () => {
    emptyCart();

    onDismiss();
    onConfirm();
  };

  return (
    <ConfirmDialog
      showDialog={showDialog}
      onConfirm={handleChangeServiceModeConfirmation}
      onDismiss={onDismiss}
      onCancel={onDismiss}
      confirmLabel={formatMessage({ id: 'changeServiceModeConfirmationConfirm' })}
      cancelLabel={formatMessage({ id: 'cancel' })}
      modalAppearanceEventMessage={formatMessage({ id: 'changeServiceModeConfirmationTitle' })}
      headingComponent={
        <Text.Heading type="one">
          {formatMessage({ id: 'changeServiceModeConfirmationTitle' })}
        </Text.Heading>
      }
      bodyComponent={
        <Text style={styles.dialogMessage}>
          {formatMessage({ id: 'changeServiceModeConfirmationMessage' })}
        </Text>
      }
    />
  );
};

const styles = StyleSheet.create({
  dialogMessage: {
    marginVertical: 16,
    lineHeight: 24,
  },
});
