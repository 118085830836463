import { flatMap, isEmpty, isUndefined } from 'lodash';

import { ICartEntry, IDiscount, IItemGroupDiscount, IOfferDiscount } from '@rbi-ctg/menu';
import { DiscountTypes, MenuObjectTypes, OfferDiscountTypes } from 'enums/menu';
import { IAppliedRewards, IncentiveEligibleItem } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { ISelectedOfferPersistedState } from 'state/offers/hooks/types';
import { dollarsToCents } from 'utils';
import { priceForCartEntry } from 'utils/menu/price';

interface ICartEntryForPricing extends Pick<ICartEntry, 'price' | 'quantity' | 'cartId'> {}

interface LoyaltyInfo {
  loyaltyEnabled: boolean;
  appliedLoyaltyRewards?: IAppliedRewards;
  appliedLoyaltyOfferDiscount?: IOfferDiscount | null | undefined;
  offersEligibleItems?: IncentiveEligibleItem[];
  loyaltySelectedOffer?: LoyaltyOffer;
}

export const getIndividualItemPrice = ({
  cartEntry,
}: {
  cartEntry: ICartEntryForPricing;
}): number => (cartEntry.price || 0) / cartEntry.quantity;

export const getItemLoyaltyRewardDiscount = ({
  cartEntry,
  appliedLoyaltyRewards,
}: {
  cartEntry: ICartEntryForPricing;
  appliedLoyaltyRewards: IAppliedRewards;
}) => {
  const individualItemPrice = getIndividualItemPrice({ cartEntry });
  const timesRewardApplied = appliedLoyaltyRewards[cartEntry.cartId]?.timesApplied ?? 0;
  return individualItemPrice * timesRewardApplied;
};

const getFinalDiscount = (price: number, discount: number) =>
  price - discount < 0 ? price : discount;

const calculateItemGroupDiscount = ({
  discountType,
  discountValue,
  itemPrice,
}: {
  discountType: OfferDiscountTypes;
  discountValue: number;
  itemPrice: number;
}) => {
  if (discountType === OfferDiscountTypes.AMOUNT) {
    const discountValueInCents = Math.abs(dollarsToCents(discountValue));
    return getFinalDiscount(itemPrice, discountValueInCents);
  }

  if (discountType === OfferDiscountTypes.PERCENTAGE) {
    const discountPercentage = discountValue / 100;
    // If the discount percentage is over 100% the order is free...
    if (discountPercentage >= 1) {
      return itemPrice;
    }
    const discountValueInCents = Math.round(itemPrice * discountPercentage);

    return discountValueInCents;
  }

  return 0;
};

export const getItemOfferDiscount = ({
  cartEntry,
  offer,
  timesApplied,
}: {
  cartEntry: ICartEntryForPricing;
  offer: LoyaltyOffer;
  timesApplied: number;
}) => {
  const individualItemPrice = getIndividualItemPrice({ cartEntry });
  let discount = 0;

  offer?.incentives?.forEach(incentive => {
    switch (incentive?._type) {
      case MenuObjectTypes.ITEM_GROUP_DISCOUNT: {
        const appliedIncentive = incentive as IItemGroupDiscount;
        const discountType = appliedIncentive.discountType;
        const discountValue = appliedIncentive.discountValue;

        if (discountType && discountValue) {
          discount += calculateItemGroupDiscount({
            discountType,
            discountValue,
            itemPrice: individualItemPrice,
          });
        }

        break;
      }
    }
  });

  return discount * timesApplied;
};

export const getCartEntryBenefit = (
  cartEntries: ICartEntry[],
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => void,
  rewardBenefitId: string | undefined
) =>
  cartEntries?.find(entry => {
    const { _id } = entry ?? {};
    // in the case of pickers, the `_id` won't match `rewardBenefitId`
    // however we can derive the resolved item/combo using the `url` property on the cartEntry
    return _id === rewardBenefitId || getAvailableRewardFromCartEntry(entry);
  });

export const getLoyaltyRewardsDiscount = ({
  cartEntries,
  loyaltyEnabled,
  appliedLoyaltyRewards,
}: {
  cartEntries: ICartEntryForPricing[];
  loyaltyEnabled: boolean;
  appliedLoyaltyRewards?: IAppliedRewards;
}) =>
  !loyaltyEnabled || isUndefined(appliedLoyaltyRewards) || isEmpty(appliedLoyaltyRewards)
    ? 0
    : cartEntries.reduce((totalRewardsDiscount, cartEntry) => {
        const discount = getItemLoyaltyRewardDiscount({ cartEntry, appliedLoyaltyRewards });
        return totalRewardsDiscount + discount;
      }, 0);

export const getLoyaltyOffersDiscount = ({
  cartEntries,
  loyaltyInfo: { loyaltyEnabled, offersEligibleItems, loyaltySelectedOffer },
}: {
  cartEntries: ICartEntryForPricing[];
  loyaltyInfo: LoyaltyInfo;
}) =>
  !loyaltyEnabled ||
  isUndefined(loyaltySelectedOffer) ||
  isEmpty(loyaltySelectedOffer) ||
  isEmpty(offersEligibleItems)
    ? 0
    : cartEntries.reduce((totalOffersDiscount, cartEntry) => {
        let discount = 0;
        const eligibleItem = offersEligibleItems?.find(
          item =>
            item.incentiveId === loyaltySelectedOffer.loyaltyEngineId &&
            item.lineId === cartEntry.cartId
        );
        if (eligibleItem) {
          discount = getItemOfferDiscount({
            cartEntry,
            offer: loyaltySelectedOffer,
            timesApplied: eligibleItem.timesApplied,
          });
        }
        return totalOffersDiscount + discount;
      }, 0);

export const getLoyaltyOfferDiscount = ({
  loyaltyEnabled,
  appliedLoyaltyOfferDiscount,
}: {
  loyaltyEnabled: boolean;
  appliedLoyaltyOfferDiscount?: IOfferDiscount | null | undefined;
}) => {
  return loyaltyEnabled && isOfferDiscount(appliedLoyaltyOfferDiscount)
    ? appliedLoyaltyOfferDiscount
    : undefined;
};

export const computeTotalWithOfferDiscount = (
  totalCents: number,
  offerDiscount?: IOfferDiscount
) => {
  const { discountValue, discountType } = offerDiscount ?? {};

  // We can't apply a discount if we don't have a discountValue
  if (!discountValue || discountValue === 0) {
    return totalCents;
  }

  if (discountType === OfferDiscountTypes.AMOUNT) {
    const discountValueInCents = Math.abs(dollarsToCents(discountValue));
    // Negative values are allowed, but the consumer of this method should handle them properly
    return totalCents - discountValueInCents;
  }

  if (discountType === OfferDiscountTypes.PERCENTAGE) {
    const discountPercentage = discountValue / 100;
    // If the discount percentage is over 100% the order is free...
    if (discountPercentage >= 1) {
      return 0;
    }
    const discountValueInCents = Math.round(totalCents * discountPercentage);
    return totalCents - discountValueInCents;
  }
  // there is a discountType we are not accounting for... Consider a log here to track this?
  return totalCents;
};

export const computeCartTotal = (
  offerDetails: ISelectedOfferPersistedState,
  cartEntries: ICartEntry[],
  loyaltyInfo: LoyaltyInfo
) => {
  const { selectedOffer, selectedOfferCartEntry, selectedOfferPrice } = offerDetails;

  // When the offer option is OfferDiscount we discount the entire total not change the price of the offer. See below.
  const cartEntriesWithOffer =
    selectedOffer && selectedOffer.option?._type !== MenuObjectTypes.OFFER_DISCOUNT
      ? [
          ...cartEntries,
          {
            ...selectedOfferCartEntry,
            price: selectedOfferPrice || 0,
          },
        ]
      : cartEntries;

  const loyaltyRewardsDiscount = getLoyaltyRewardsDiscount({
    cartEntries,
    ...loyaltyInfo,
  });

  const loyaltyOffersDiscount = getLoyaltyOffersDiscount({
    cartEntries,
    loyaltyInfo,
  });

  const totalWithRewardsAndOffersApplied =
    Math.max(
      cartEntriesWithOffer.reduce((totalPrice, entry) => priceForCartEntry(entry) + totalPrice, 0),
      0
    ) -
    loyaltyRewardsDiscount -
    loyaltyOffersDiscount;

  // When the offer has an offerDiscount calculate the discount off the entire order
  const offerDiscount = isOfferDiscount(selectedOffer?.option)
    ? selectedOffer?.option
    : getLoyaltyOfferDiscount(loyaltyInfo);

  if (offerDiscount) {
    const donationItemInCart = cartEntriesWithOffer.find(item => item.isDonation);
    // This is to exclude the donation item from the offer discount calculation
    // It is then added to the subTotal once the discount is already applied to the other cart entries
    if (donationItemInCart && donationItemInCart.price) {
      return (
        computeTotalWithOfferDiscount(
          totalWithRewardsAndOffersApplied - donationItemInCart.price,
          offerDiscount
        ) + donationItemInCart.price
      );
    }
    return computeTotalWithOfferDiscount(totalWithRewardsAndOffersApplied, offerDiscount);
  }
  return totalWithRewardsAndOffersApplied;
};

export const computeOtherDiscountAmount = (allDiscounts?: IDiscount[]) => {
  if (!allDiscounts) {
    return 0;
  }
  return allDiscounts
    .filter(discount => discount.name !== DiscountTypes.REWARDS_DISCOUNTS)
    .reduce((acc, curr) => curr.value + acc, 0);
};

export const computeTotalDiscountAmount = (allDiscounts?: IDiscount[]) => {
  if (!allDiscounts) {
    return 0;
  }
  return allDiscounts.reduce((acc, curr) => curr.value + acc, 0);
};

export const computeDeliveryFee = ({
  feeCents,
  feeDiscountCents,
  serviceFeeCents,
  smallCartFeeCents,
  geographicalFeeCents,
}: {
  feeCents: number | null;
  feeDiscountCents: number | null;
  serviceFeeCents: number | null;
  smallCartFeeCents: number | null;
  geographicalFeeCents: number | null;
}) => {
  // The value "feeCents" includes the additional fees "serviceFeeCents" and "smallCartFeeCents"
  return Math.max(
    (feeCents || 0) -
      (feeDiscountCents || 0) -
      (serviceFeeCents || 0) -
      (smallCartFeeCents || 0) -
      (geographicalFeeCents || 0),
    0
  );
};

export const isOfferDiscount = (option: any): option is IOfferDiscount =>
  option?._type === MenuObjectTypes.OFFER_DISCOUNT;

export const isItemGroupDiscount = (option: any): option is IOfferDiscount =>
  option?._type === MenuObjectTypes.ITEM_GROUP_DISCOUNT;

/**
 * Find first OfferDiscount benefit inside an Offers list, if any
 *
 * @param offers A LoyaltyOffer array
 */
export const getAppliedLoyaltyOfferDiscount = (
  offers?: LoyaltyOffer[]
): IOfferDiscount | undefined => {
  const benefits = flatMap(offers, offer => offer.incentives);
  const [firstOfferDiscount] = benefits.filter(isOfferDiscount) as IOfferDiscount[];
  return firstOfferDiscount;
};

/**
 * Computes cart total without including discount offers
 *
 * @param cartEntries
 * @param loyaltyInfo loyalty required info including applied rewards to calculate total
 */
export const computeTotalWithoutOffers = (
  cartEntries: ICartEntry[],
  loyaltyInfo: {
    loyaltyEnabled: boolean;
    appliedLoyaltyRewards?: IAppliedRewards;
  }
) => {
  return computeCartTotal({ selectedOffer: null }, cartEntries, loyaltyInfo);
};
