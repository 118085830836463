import { useCallback, useMemo } from 'react';

import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { transformPaymentMethodValues } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/utils';
import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { useGetEncryptionDetailsMutation } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { IAuthorizedOneTimePaymentMethod, IOrderInformation } from 'pages/cart/payments/types';
import { getNonce } from 'remote/api/first-data';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';

export const useFirstDataOneTimePaymentMethod = (rbiOrderId: string) => {
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);

  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const fdUrl = useMemo(() => urlsConfig.firstData, [urlsConfig.firstData]);
  const { handleCommitOrder } = useCommitOrder(rbiOrderId);
  const [encryptionDetailsMutation] = useGetEncryptionDetailsMutation();

  const getAuthorizedOneTimePaymentMethod = useCallback(
    async (
      paymentMethodValues: IAddCreditCardFormValues
    ): Promise<IAuthorizedOneTimePaymentMethod> => {
      const formattedCreditCardFormValues = transformPaymentMethodValues({
        paymentMethodValues,
        paymentFieldVariations,
      });

      try {
        const encryptionDetailsResponse = await encryptionDetailsMutation();
        const encryptionDetails = encryptionDetailsResponse?.data?.encryptionDetails;

        if (!encryptionDetails) {
          throw new Error('Missing encryption details');
        }

        const { fdPublicKey, fdApiKey, fdAccessToken, fdCustomerId, algorithm } = encryptionDetails;

        const nonceResponse = await getNonce(
          formattedCreditCardFormValues,
          fdPublicKey,
          fdApiKey,
          fdAccessToken,
          fdCustomerId || null,
          fdUrl,
          onlySendPostalCode,
          algorithm || undefined
        );

        const { token } = await nonceResponse.json();
        return {
          accessToken: fdAccessToken,
          token: token.tokenId,
        };
      } catch (error) {
        // TODO: Payments Refactor - Properly handle error dialogues across operations to avoid redundant error handling
        // openErrorDialog({
        //   message: formatMessage({ id: 'paymentAddingError' }),
        //   modalAppearanceEventMessage: 'Error: Fetching Encryption Details Failure',
        //   // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        //   error,
        // });
        throw new Error('Missing encryption details');
      }
    },
    [fdUrl, encryptionDetailsMutation, onlySendPostalCode, paymentFieldVariations]
  );

  const requestOneTimePayment = useCallback(
    async ({
      creditCardFormValues,
      orderInformation,
    }: {
      creditCardFormValues: IAddCreditCardFormValues;
      orderInformation: IOrderInformation;
    }) => {
      // Get an authorized one time payment method from FirstData
      const authorizationDetails = await getAuthorizedOneTimePaymentMethod(creditCardFormValues);

      // Build the payment input for a one time payment method
      const firstDataPaymentInput = {
        firstDataInput: {
          fdAccessToken: authorizationDetails.accessToken,
          fdNonce: authorizationDetails.token,
        },
        fullName: creditCardFormValues.nameOnCard ?? '',
      };

      // Handle committing the order
      return handleCommitOrder({
        cardBrand: creditCardFormValues.cardBrand,
        orderInformation,
        payment: firstDataPaymentInput,
      });
    },
    [getAuthorizedOneTimePaymentMethod, handleCommitOrder]
  );

  return {
    requestOneTimePayment,
  };
};
