import { Image, ImageProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { LtoContentUS } from '../lto-content/lto-content-us';

export type TopMarketingImageProps = Omit<ImageProps, 'children' | 'source'>;

export function TopMarketingImage(props: TopMarketingImageProps) {
  return (
    <LtoContentUS
      poweradeVictoryBundleContent={
        <Image
          source={require('./powerade-top.webp')}
          placeholder={{ thumbhash: 'I3qGFQImR3qQiISJiUmw9KRMBYlka2u7Zg' }}
          alt="Powerade Victory Bundle"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
      captainsClubContent={
        <Image
          source={require('./captains-club-top.webp')}
          placeholder={{ thumbhash: 'XXqGFQIml3eBiob3t5hwqgjnJ1dwiJXoJw' }}
          alt="Captain's Club"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  marketingImage: {
    aspectRatio: 800 / 811,
    width: '100%',
    height: '100%',
    minHeight: 200,
    alignSelf: 'center',
  },
});
