/**
 * For documentation on masks, visit:
 *
 * https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 */

const nDigits = (n: number) => new Array(n).fill(/\d/);

export const creditCardNumberMask = (cardNumber: string) => {
  /**
   * You may be wondering why we're using the unicode representation of a
   * whitespace here. Well, I'll tell you. It's because of a bug in the
   * react-text-mask library (see: https://github.com/text-mask/text-mask/issues/483)
   * that breaks backspace functionality. Not great. By using unicode characters
   * for the placeholders here, in combination with showing the guide and setting
   * the `placeholderChar` on the mask component to a literal whitespace, we're
   * able to get around this problem.
   */

  /**
   * Amex card numbers are formatted differently from other cards
   * They begin with either '34' or '37'
   */
  if (/^(3[47])/.test(cardNumber)) {
    return [...nDigits(4), '\u2000', ...nDigits(6), '\u2000', ...nDigits(5)];
  }
  return [...nDigits(4), '\u2000', ...nDigits(4), '\u2000', ...nDigits(4), '\u2000', ...nDigits(4)];
};

export const expiryMask = [/\d/, /\d/, '/', /\d/, /\d/];
