import { useCallback } from 'react';

import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { splitExpiry } from 'components/payments/components/add-credit-card-form-inputs/utils';
import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { IOrder, IPaymentInput } from 'generated/graphql-gateway';
import { IOrderInformation } from 'pages/cart/payments/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { IEncryptionResult } from '../components/encryption/types';

interface IRequestOneTimePayment {
  creditCardFormValues: IAddCreditCardFormValues;
  encryptionData: IEncryptionResult;
  orderInformation: IOrderInformation;
}

export const useWorldpayOneTimePayment = (rbiOrderId: string) => {
  const { handleCommitOrder } = useCommitOrder(rbiOrderId);
  const oneTimePaymentFlowEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT
  );

  const requestOneTimePayment = useCallback(
    async ({
      creditCardFormValues,
      encryptionData,
      orderInformation,
    }: IRequestOneTimePayment): Promise<IOrder | undefined> => {
      // Build the payment input for a one time payment method
      const { cardBrand, nameOnCard, expiry } = creditCardFormValues;
      const { expiryMonth, expiryYear } = splitExpiry(expiry || '');
      const worldpayPaymentInput: IPaymentInput = {
        worldpayInput: {
          lowValueToken: encryptionData.lowValueToken,
          storePaymentMethod: oneTimePaymentFlowEnabled ? creditCardFormValues.saveCard : false,
        },
        fullName: nameOnCard ?? '',
        ccMetadata: {
          ccLast4: creditCardFormValues.cardNumber.slice(-4),
          ccBin: creditCardFormValues.cardNumber.slice(0, 6),
          ccExpiryMonth: expiryMonth,
          ccExpiryYear: expiryYear,
        },
      };

      // Handle committing the order
      return handleCommitOrder({
        cardBrand,
        orderInformation,
        payment: worldpayPaymentInput,
      });
    },
    [handleCommitOrder, oneTimePaymentFlowEnabled]
  );

  return {
    requestOneTimePayment,
  };
};
