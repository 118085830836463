import { Box, HStack } from '@fhs-legacy/universal-components';

const FORM_SPACING = '$4';

export const StyledSaveCardContainer = Box.withConfig({
  paddingX: FORM_SPACING,
  paddingBottom: '$2',
});

export const StyledBillingCityWrapper = Box.withConfig({
  flexBasis: {
    base: '60%',
    desktop: '30%',
  },

  marginRight: {
    base: '5%',
    desktop: '5%',
  },
  marginBottom: FORM_SPACING,
});

export const StyledBillingStateWrapper = Box.withConfig({
  flexBasis: {
    base: '35%',
    desktop: '16%',
  },

  marginRight: {
    desktop: '5%',
  },
  marginBottom: FORM_SPACING,
});

export const StyledStreetAddressWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

export const StyledBillingAptWrapper = Box.withConfig({
  flexBasis: {
    base: '45%',
    desktop: '19%',
  },
  flexGrow: 1,
  marginRight: {
    desktop: '5%',
  },
  marginBottom: FORM_SPACING,
});

export const StyledBillingZipWrapper = Box.withConfig({
  flexBasis: {
    base: '45%',
    desktop: '20%',
  },
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});

export const StyledNameOnCardWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

export const StyledCountrySelectWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

interface IFormContainer {
  $withPadding?: boolean;
  $withBorder: boolean;
  isHidden?: boolean;
}

export const FormContainer = Box.withConfig<IFormContainer>(p => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  backgroundColor: {
    base: 'transparent',
    desktop: p.$withBorder ? Styles.color.white : 'transparent',
  },
  visibility: p.isHidden ? 'hidden' : 'visible',
  padding: { desktop: p.$withPadding ? '$4' : 0 },
  borderWidth: { desktop: p.$withBorder ? '1px' : undefined },
  borderColor: { desktop: p.$withBorder ? Styles.color.grey.four : undefined },
}));

// Credit card form inputs
export const StyledExpiryCVVWrapper = HStack.withConfig({
  justifyContent: 'space-between',
  width: 'full',
});

export const StyledExpiryWrapper = Box.withConfig({
  marginRight: '$4',
  marginBottom: FORM_SPACING,
});

export const StyledCvvWrapper = Box.withConfig({
  flexShrink: 1,
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});

export const CardNumberWrapper = Box.withConfig({
  position: 'relative',
  flexBasis: {
    base: '100%',
    desktop: '50%',
  },
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});
