import { GraphQLError } from 'graphql/index';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { DEFAULT_BILLING_COUNTRY } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/constants';
import { AddCreditCardError } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/errors';
import {
  AddPaymentMethod,
  IAddPaymentMethod,
} from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/types';
import { transformPaymentMethodValues } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/utils';
import {
  AddPaymentMethodMutationResult,
  isAddPaymentMethodSuccess,
  isSchemeStoredPaymentMethodResponse,
} from 'components/payments/integrations/hooks/types';
import {
  IAddPaymentMethodInput,
  StoredPaymentMethodsDocument,
  useAddPaymentMethodMutation,
} from 'generated/graphql-gateway';
import {
  IGetEncryptionDetailsMutation,
  useGetEncryptionDetailsMutation,
} from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { getNonce } from 'remote/api/first-data';
import { HttpErrorCodes } from 'remote/constants';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import logger from 'utils/logger';

interface IUseAddPaymentMethod {
  addPaymentMethod: AddPaymentMethod;
  loading: boolean;
}

export const useAddPaymentMethod = (): IUseAddPaymentMethod => {
  // Context
  const { formatMessage } = useIntl();
  const { feCountryCode } = useLocale();

  // GQL
  const [getEncryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [addPaymentMethodMutation] = useAddPaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  // LD Flags
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);

  // State
  const [loading, setLoading] = useState(false);

  // Utils
  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const fdUrl = useMemo(() => urlsConfig.firstData, [urlsConfig.firstData]);
  const fdAccountIdReference = useRef<string | null | undefined>(null);

  const getEncryptionDetails = useCallback(async (): Promise<
    IGetEncryptionDetailsMutation['encryptionDetails']
  > => {
    const encryptionDetailsResponse = await getEncryptionDetailsMutation();

    if (!encryptionDetailsResponse?.data?.encryptionDetails) {
      throw new Error('Missing encryption details');
    }

    return encryptionDetailsResponse.data.encryptionDetails;
  }, [getEncryptionDetailsMutation]);

  const logNewPaymentMethod = (success: boolean, context: Record<string, any> = {}) => {
    if (success) {
      logger.info({
        message: 'Payment method added',
        context,
      });
    } else {
      logger.error({
        message: 'Error adding payment method',
        context,
      });
    }
  };

  const addPaymentMethod = useCallback(
    async ({ paymentMethodValues, addPaymentMethodOptions }: IAddPaymentMethod) => {
      const formattedCreditCardFormValues = transformPaymentMethodValues({
        paymentMethodValues,
        paymentFieldVariations,
      });
      const { billingAddress } = formattedCreditCardFormValues;
      const { accountToDelete: _accountToDelete, ...cleanedPaymentMethodValues } =
        formattedCreditCardFormValues;
      const skipErrorDialogOnError = addPaymentMethodOptions?.skipErrorDialogOnError || false;

      try {
        // Move this to a new useCallback??
        const {
          fdPublicKey,
          fdApiKey,
          fdAccessToken,
          fdCustomerId, // Necessary second request
          algorithm,
        } = await getEncryptionDetails();

        fdAccountIdReference.current = fdCustomerId;

        const nonceResponse = await getNonce(
          cleanedPaymentMethodValues,
          fdPublicKey,
          fdApiKey,
          fdAccessToken,
          fdCustomerId || null,
          fdUrl,
          onlySendPostalCode,
          algorithm || undefined
        );

        const { token } = await nonceResponse.json();

        const input: IAddPaymentMethodInput = {
          paymentMethods: JSON.stringify({
            FIRSTDATA: {
              type: 'scheme',
              subtype: 'onetime',
              accessToken: fdAccessToken,
              token: token.tokenId,
            },
          }),
          billingAddress: {
            locality: billingAddress.locality,
            postalCode: billingAddress.postalCode,
            regionCode: billingAddress.region || DEFAULT_BILLING_COUNTRY,
            streetNumber: billingAddress.streetAddress,
          },
        };
        const { data } = await addPaymentMethodMutation({
          variables: {
            input,
          },
        });

        if (!data) {
          throw Error('Something wrong happened');
        }

        const addPaymentMethodResult: AddPaymentMethodMutationResult = data.addPaymentMethod;

        // checking types
        if (isAddPaymentMethodSuccess(addPaymentMethodResult)) {
          const storedPaymentMethod = addPaymentMethodResult.storedPaymentMethod;

          if (!isSchemeStoredPaymentMethodResponse(storedPaymentMethod)) {
            // TODO: Payments Refactor - Improve error message for this error response.
            throw Error('Invalid card');
          }

          logNewPaymentMethod(true, { accountIdentifier: storedPaymentMethod.id });

          // Success response for a schemeStoredPaymentMethod
          setLoading(false);
          return storedPaymentMethod;
        }

        throw Error('Error adding a payment method');
      } catch (error) {
        logger.error({
          error,
          message: 'Error adding payment method',
        });

        setLoading(false);

        logNewPaymentMethod(false, {
          chaseProfileId: undefined,
          accountIdentifier: undefined,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
        });

        if (!skipErrorDialogOnError) {
          const statusCode:
            | number
            // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
            | undefined = error.graphQLErrors?.find((graphQLError: GraphQLError) =>
            Number.isInteger(graphQLError?.extensions?.statusCode)
          )?.extensions?.statusCode;
          const message =
            statusCode === HttpErrorCodes.TooManyRequests
              ? formatMessage({ id: 'tooManyAddAccountAttempts' })
              : formatMessage({ id: 'paymentAddingError' });
          const modalAppearanceEventMessage = 'Error: Adding Payment Method Failure';
          throw new AddCreditCardError(message, modalAppearanceEventMessage);
        }
        throw new Error('Add Account Failure');
      }
    },
    [
      addPaymentMethodMutation,
      fdUrl,
      formatMessage,
      getEncryptionDetails,
      onlySendPostalCode,
      paymentFieldVariations,
    ]
  );

  return {
    addPaymentMethod,
    loading,
  };
};
