import { router } from 'expo-router';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { Divider, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import AccountLayout from 'components/layout/account';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import {
  AddGiftCardButton,
  TransferGiftCardButton,
} from 'components/payments/components/gift-card-button';
import {
  DeletePaymentMethodHandler,
  PaymentMethod,
} from 'components/payments/integrations/hooks/types';
import { updateDefaultPaymentMethodHandler } from 'components/payments/integrations/hooks/use-update-default-payment-method';
import {
  getGiftCardMethod,
  isCashMethod,
  isNativeMethod,
} from 'components/payments/integrations/hooks/utils';
import UserVerificationModal from 'components/user-verification-modal';
import useUserHasToVerify from 'hooks/use-user-has-to-verify';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { PaymentMethodOption } from '../payment-method-option';

import { ActionButtonsContainer, DefaultText, DefaultTextWrapper, Form } from './styled';

export interface IPaymentMethodFlatListWithButtonProps {
  availablePaymentMethods: PaymentMethod[];
  defaultPaymentMethod: PaymentMethod | undefined;
  deletePaymentMethod: DeletePaymentMethodHandler | undefined;
  loading: boolean;
  updateDefaultPaymentMethod: updateDefaultPaymentMethodHandler;
}

export const PaymentMethodFlatListWithButton: React.FC<IPaymentMethodFlatListWithButtonProps> = ({
  availablePaymentMethods,
  defaultPaymentMethod,
  deletePaymentMethod,
  loading,
  updateDefaultPaymentMethod,
}) => {
  const { formatMessage } = useIntl();
  const [showUserVerificationModal, setShowVerificationModal] = useState(false);
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const enableUnverifiedSavedPayments = useFlag(LaunchDarklyFlag.ENABLE_UNVERIFIED_SAVED_PAYMENTS);
  const userHasToVerify = useUserHasToVerify(enableUnverifiedSavedPayments);
  const prepaidMethod = getGiftCardMethod(availablePaymentMethods);

  // TODO: Payments Refactor - 'onPress' handler for ActionButton components
  // @ts-ignore

  const handleAddCard = (event: GestureResponderEvent) => {
    if (userHasToVerify) {
      event?.stopPropagation();
      setShowVerificationModal(true);
    }

    router.navigate(routes.addCard);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  return (
    <AccountLayout header={formatMessage({ id: 'paymentMethods' })}>
      {availablePaymentMethods.length !== 0 && (
        <DefaultTextWrapper>
          <DefaultText>{formatMessage({ id: 'accountDefault' })}</DefaultText>
        </DefaultTextWrapper>
      )}
      <Form testID="account-saved-payments">
        {availablePaymentMethods.length ? (
          availablePaymentMethods.map(method => (
            <React.Fragment key={`payment-method-${method.id}`}>
              <PaymentMethodOption
                paymentMethod={method}
                fullWidthDescription={false}
                disableDelete={isNativeMethod(method) || isCashMethod(method)}
                deletePaymentMethod={deletePaymentMethod}
                selected={method.id === defaultPaymentMethod?.id}
                showDefault
                onPress={() => {
                  updateDefaultPaymentMethod(method);
                }}
              />
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <Text>{formatMessage({ id: 'noPaymentMethodOnFile' })}</Text>
        )}
      </Form>
      <ActionButtonsContainer>
        <ActionButton
          fullWidth
          variant={ActionButtonVariants.OUTLINE}
          onPress={handleAddCard}
          testID="add-new-payment-method"
        >
          {formatMessage({ id: 'addNewPaymentMethod' })}
        </ActionButton>
        {enableGiftCard && !prepaidMethod && <AddGiftCardButton />}
        {enableGiftCard && prepaidMethod && (
          <TransferGiftCardButton prepaidMethod={prepaidMethod} />
        )}
      </ActionButtonsContainer>
      {showUserVerificationModal && (
        <UserVerificationModal showDialog onDismiss={() => setShowVerificationModal(false)} />
      )}
    </AccountLayout>
  );
};

export default PaymentMethodFlatListWithButton;
