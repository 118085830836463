import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { Text, XStack, YStack } from '@fhs/ui';
import { Icon } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { geographicalFeeLabelIdMap } from 'pages/account/account-orders/constants';
import { CurrencyLineSkeleton } from 'pages/cart/currency-line-skeleton';
import { useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { IconContainer } from '../styled';

import { totalSectionStyles } from './styled-base';
import TaxesRow from './taxes-row';

interface TaxesAndFeesProps {
  deliveryFee: number | null;
  deliveryFeeDiscount: number | null;
  dropoffState?: string | null;
  serviceFeeCents: number;
  smallCartFeeCents: number;
  geographicalFeeCents: number;
  baseDeliveryFeeCents: number;
  deliverySurchargeFeeCents: number;
  taxCents: number;
  serviceMode: ServiceMode | null;
  subTotalCents: number;
  isLoading?: boolean;
  isCartPage?: boolean;
}

const BreakdownFee = ({ fee, label, testId }) => {
  return (
    <XStack style={totalSectionStyles.feeBreakdownRow}>
      <Text style={totalSectionStyles.feeBreakdownLabel}>{label}</Text>
      <XStack testID={testId}>
        <Currency amount={fee} textProps={totalSectionStyles.feeBreakdownLabel} />
      </XStack>
    </XStack>
  );
};

const TaxesAndFees: React.FC<React.PropsWithChildren<TaxesAndFeesProps>> = ({
  isLoading,
  deliveryFee,
  dropoffState,
  serviceFeeCents,
  smallCartFeeCents,
  geographicalFeeCents,
  baseDeliveryFeeCents,
  deliverySurchargeFeeCents,
  taxCents,
  isCartPage,
}) => {
  const hasFiredAmplitudeEventRef = useRef(false);
  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const enableDeliveryFeesInfoButton = useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEES_INFO);
  const shouldShowTaxLine = !enableHideTaxLine && !isCartPage;
  const { formatMessage } = useIntl();
  const { setShowFeeWarning } = useServiceModeContext();
  const { logDeliveryFeesSectionDisplayed } = useCRMEventsContext();

  const isFreeBaseDeliveryFee = baseDeliveryFeeCents === 0 && !isLoading;

  const showDeliveryFeesBreakdown =
    serviceFeeCents || smallCartFeeCents || geographicalFeeCents || baseDeliveryFeeCents;

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const onPressDeliveryFees = () => {
    setShowFeeWarning(true);
  };

  const renderFeesBreakdown = () => {
    if (!hasFiredAmplitudeEventRef.current) {
      hasFiredAmplitudeEventRef.current = true;
      logDeliveryFeesSectionDisplayed(Boolean(showDeliveryFeesBreakdown));
    }

    if (!showDeliveryFeesBreakdown) {
      return;
    }

    if (isLoading) {
      return <CurrencyLineSkeleton />;
    }

    return (
      <YStack style={totalSectionStyles.feeBreakdown}>
        <XStack style={totalSectionStyles.feeBreakdownRow}>
          <Text style={totalSectionStyles.feeBreakdownLabel}>
            {formatMessage({
              id: 'deliveryFeeBase',
            })}
          </Text>
          <XStack style={totalSectionStyles.baseFeeRow} testID="base-fee-amount">
            <Currency
              amount={baseDeliveryFeeCents}
              textProps={totalSectionStyles.feeBreakdownLabel}
              isFree={!!isFreeBaseDeliveryFee}
            />
          </XStack>
        </XStack>

        {deliverySurchargeFeeCents > 0 && (
          <BreakdownFee
            fee={deliverySurchargeFeeCents}
            label={formatMessage({
              id: 'deliveryFeeLongDistance',
            })}
            testId='long-distance-fee-amount"'
          />
        )}
        {geographicalFeeCents > 0 && (
          <BreakdownFee
            fee={deliverySurchargeFeeCents}
            label={formatMessage({
              id: geographicalFeeLabelId,
            })}
            testId="geographical-fee-amount"
          />
        )}
        {serviceFeeCents > 0 && (
          <BreakdownFee
            fee={serviceFeeCents}
            label={formatMessage({
              id: 'serviceFee',
            })}
            testId="service-fee-amount"
          />
        )}

        {smallCartFeeCents > 0 && (
          <BreakdownFee
            fee={smallCartFeeCents}
            label={formatMessage({
              id: 'smallCartFee',
            })}
            testId="small-cart-fee-amount"
          />
        )}
      </YStack>
    );
  };

  const deliveryFeesLabelId = showDeliveryFeesBreakdown ? 'deliveryFees' : 'deliveryFee';

  return (
    <>
      <XStack style={totalSectionStyles.container}>
        {isLoading ? (
          <CurrencyLineSkeleton />
        ) : (
          <>
            <XStack style={totalSectionStyles.feeRow}>
              <XStack style={{ alignItems: 'center' }}>
                <Text style={totalSectionStyles.feeBreakdownLabel}>
                  {formatMessage({
                    id: deliveryFeesLabelId,
                  })}
                </Text>
                {enableDeliveryFeesInfoButton && (
                  <IconContainer
                    accessibilityLabel={formatMessage({
                      id: 'taxesAndFeesButtonAriaLabel',
                    })}
                    onPress={onPressDeliveryFees}
                  >
                    <Icon variant="info" size="$5" {...hiddenAccessibilityPlatformProps} />
                  </IconContainer>
                )}
              </XStack>
              {/* We just show the total delivery fee if the breakdown fees are not displayed */}
              {!showDeliveryFeesBreakdown && (
                <XStack aria-labelledby="delivery-fee-amount-label">
                  <Currency
                    amount={deliveryFee || 0}
                    textProps={totalSectionStyles.feeBreakdownLabel}
                  />
                </XStack>
              )}
            </XStack>
          </>
        )}
      </XStack>
      {renderFeesBreakdown()}
      {isLoading ? (
        <CurrencyLineSkeleton />
      ) : (
        shouldShowTaxLine && <TaxesRow taxCents={taxCents} isLoading={isLoading} />
      )}
    </>
  );
};

export default TaxesAndFees;
