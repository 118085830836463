import { Icon } from '@fhs-legacy/universal-components';
import { CardBrand, PaymentMethodType } from 'generated/graphql-gateway';

export const cardBrandToIcon = {
  [CardBrand.AMEX]: <Icon title="Amex" variant="amex" />,
  [CardBrand.DISCOVER]: <Icon title="Discover" variant="discover" />,
  [CardBrand.MASTERCARD]: <Icon title="Mastercard" variant="mastercard" />,
  [CardBrand.VISA]: <Icon title="Visa" variant="visa" />,
  [CardBrand.DINERS_CLUB]: <Icon title="Diners Club" variant="diners" />,
  [CardBrand.JCB]: <Icon title="JCB" variant="jcb" />,
};

export const nativeTypeToIcon = {
  [PaymentMethodType.APPLE_PAY]: <Icon title="Apple Pay" variant="applepay" />,
  [PaymentMethodType.GOOGLE_PAY]: <Icon title="Google Pay" variant="googlePay" />,
};
