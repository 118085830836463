import { GraphQLError } from 'graphql/index';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AddPaymentMethodMutationResult,
  isAddPaymentMethodSuccess,
  isSchemeStoredPaymentMethodResponse,
} from 'components/payments/integrations/hooks/types';
import { AddCreditCardError } from 'components/payments/integrations/worldpay/components/worldpay-add-credit-card-modal/errors';
import {
  AddPaymentMethod,
  IAddPaymentMethod,
} from 'components/payments/integrations/worldpay/components/worldpay-add-credit-card-modal/types';
import {
  StoredPaymentMethodsDocument,
  useAddPaymentMethodMutation,
} from 'generated/graphql-gateway';
import { HttpErrorCodes } from 'remote/constants';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';

interface IUseAddPaymentMethod {
  addPaymentMethod: AddPaymentMethod;
  loading: boolean;
}

export const useAddPaymentMethod = (): IUseAddPaymentMethod => {
  // Context
  const { formatMessage } = useIntl();
  const { feCountryCode } = useLocale();

  // GQL
  const [addPaymentMethodMutation] = useAddPaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  // State
  const [loading, setLoading] = useState(false);

  // Utils
  const logNewPaymentMethod = (success: boolean, context: Record<string, any> = {}) => {
    if (success) {
      logger.info({
        message: 'Payment method added',
        context,
      });
    } else {
      logger.error({
        message: 'Error adding payment method',
        context,
      });
    }
  };

  const addPaymentMethod = useCallback(
    async ({ input, options }: IAddPaymentMethod) => {
      try {
        const { data } = await addPaymentMethodMutation({
          variables: {
            input,
          },
        });

        if (!data) {
          throw new Error('Mutation response error');
        }

        const addPaymentMethodResult: AddPaymentMethodMutationResult = data.addPaymentMethod;

        // checking types
        if (isAddPaymentMethodSuccess(addPaymentMethodResult)) {
          const storedPaymentMethod = addPaymentMethodResult.storedPaymentMethod;

          if (!isSchemeStoredPaymentMethodResponse(storedPaymentMethod)) {
            // TODO: Payments Refactor - Improve error message for this error response.
            throw Error('Invalid card');
          }

          logNewPaymentMethod(true, { accountIdentifier: storedPaymentMethod.id });

          // Success response for a schemeStoredPaymentMethod
          setLoading(false);
          return storedPaymentMethod;
        }

        throw Error('Error adding a payment method');
      } catch (error) {
        logger.error({
          error,
          message: 'Error adding payment method',
        });

        setLoading(false);

        logNewPaymentMethod(false, {
          chaseProfileId: undefined,
          accountIdentifier: undefined,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
        });

        if (!options.skipErrorDialogOnError) {
          const statusCode:
            | number
            // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
            | undefined = error.graphQLErrors?.find((graphQLError: GraphQLError) =>
            Number.isInteger(graphQLError?.extensions?.statusCode)
          )?.extensions?.statusCode;
          const message =
            statusCode === HttpErrorCodes.TooManyRequests
              ? formatMessage({ id: 'tooManyAddAccountAttempts' })
              : formatMessage({ id: 'paymentAddingError' });
          const modalAppearanceEventMessage = 'Error: Adding Payment Method Failure';
          throw new AddCreditCardError(message, modalAppearanceEventMessage);
        }
        throw new Error('Add Account Failure');
      }
    },
    [addPaymentMethodMutation, formatMessage]
  );

  return {
    addPaymentMethod,
    loading,
  };
};
