import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  addPaymentAuthorizationClosedListener,
  addPaymentAuthorizationListener,
  canMakePayments,
  isSupported,
  makePaymentRequest,
} from '@rbilabs/expo-applepay';
import {
  CartPaymentCardType,
  CountryCode,
  IApplePay,
  IOrder,
  IReloadPrepaidInput,
  IReloadPrepaidMutation,
  Platform,
  useReloadPrepaidMutation,
} from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { IOrderInformation } from 'pages/cart/payments/types';
import { useCartContext } from 'state/cart';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { fullBrandName } from 'utils/environment';
import { sanitizeAlphanumeric } from 'utils/form';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import { buildFormattedBillingAddress } from 'utils/native-actions';
import noop from 'utils/noop';
import { parseStringifiedJSON } from 'utils/parse-string';

import { IRequestApplePayPayment, IRequestApplePayReload, IUseApplePay } from './types';
import { useCommitOrder } from './use-commit-order';

export default function useApplePay({ prepaid = false }: IUseApplePay) {
  const { formatMessage } = useIntl();
  const { serverOrder } = useCartContext();
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const [requestingPayment, setRequestingPayment] = useState(false);

  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';

  const { handleCommitOrder } = useCommitOrder(serverOrder?.rbiOrderId ?? '');

  const [reloadPrepaidMutation] = useReloadPrepaidMutation();

  const applePayPluginAvailable = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY) && isSupported();
  const migrationEnabled = useFlag(LaunchDarklyFlag.ENABLE_DIGITAL_WALLET_MIGRATION);

  const appleConfig = useConfigValue({ key: 'apple', defaultValue: {}, isRegionalized: false });
  const paymentsNetworks = appleConfig.paymentsNetworks;
  const merchantId =
    migrationEnabled && appleConfig.migrationMerchantId
      ? appleConfig.migrationMerchantId
      : appleConfig.merchantId;
  const prepaidMerchantId =
    migrationEnabled && appleConfig.migrationPrepaidMerchantId
      ? appleConfig.migrationPrepaidMerchantId
      : appleConfig.prepaidMerchantId;

  // Device canUseApplePay if apple pay available and least one card in wallet
  useEffect(() => {
    if (applePayPluginAvailable) {
      canMakePayments({
        networks: paymentsNetworks,
      })
        .then(({ available = false, hasCards = false }) => {
          const deviceCanMakePaymentsResult = available && hasCards;
          logger.info(`Device can${deviceCanMakePaymentsResult ? '' : 'not'} make payment`);
          setCanUseApplePay(deviceCanMakePaymentsResult);
        })
        .catch(() => {
          logger.info('Device can not make payment - api rejected');
          setCanUseApplePay(false);
        });
    }
  }, [applePayPluginAvailable, paymentsNetworks]);

  const onSuccessListener = useRef({ remove: noop });
  const onFinishListener = useRef({ remove: noop });

  const cleanupHandlers = useCallback(() => {
    setRequestingPayment(false);
    [onFinishListener, onSuccessListener].forEach(({ current }) => current && current.remove());
  }, [onFinishListener, onSuccessListener]);

  useEffect(() => {
    return cleanupHandlers;
  }, [cleanupHandlers]);

  const handleApplePayPaymentDetails = useCallback(
    async ({
      data: applePayDetails,
      error,
      orderInformation,
    }: {
      data?: IApplePay;
      error?: string;
      orderInformation: IOrderInformation;
    }): Promise<IOrder | undefined> => {
      if (applePayDetails) {
        return handleCommitOrder({
          cardBrand: CartPaymentCardType.APPLE_PAY,
          orderInformation,
          payment: {
            applePayDetails,
            fullName: '',
          },
        });
      }
      logger.warn('No apple pay debundle returned. Are you on simulator?');

      if (error) {
        logger.warn(error);
        throw error;
      }
      return undefined;
    },
    [handleCommitOrder]
  );

  const handleApplePayReloadDetails = useCallback(
    async ({
      data: applePayDetails,
      billingCountry,
      error,
      reloadInformation,
    }: {
      data?: IApplePay;
      error?: string;
      billingCountry: ISOs;
      reloadInformation: {
        reloadAmount: number;
        destinationPaymentMethodId: string;
      };
    }): Promise<IReloadPrepaidMutation | undefined> => {
      if (applePayDetails) {
        const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
        const { destinationPaymentMethodId, reloadAmount } = reloadInformation;
        const reloadInput: IReloadPrepaidInput = {
          amount: reloadAmount,
          currency: currencyCode,
          destinationPaymentMethodId,
          billingAddress: {
            regionCode: ISOsToRegions[billingCountry],
            postalCode: applePayDetails?.billingAddress?.postalCode,
          },
          paymentMethod: JSON.stringify({
            type: 'applepay',
            token: applePayDetails.paymentData,
          }),
          riskData: JSON.stringify({
            cookie: forterToken,
            mobileId: forterDeviceId,
          }),
          platform: Platform.APP,
        };

        const reloadPrepaidResult = await reloadPrepaidMutation({
          variables: {
            input: reloadInput,
          },
        });
        return reloadPrepaidResult.data || undefined;
      }

      if (error) {
        logger.warn(error);
        throw error;
      }
      return undefined;
    },
    [forterDeviceId, forterToken, reloadPrepaidMutation]
  );

  // Request Apple Pay Debundle - resolves with Apple Pay details
  const requestApplePay = useCallback(
    ({
      billingCountry,
      total,
    }: {
      billingCountry: ISOs;
      total: number;
    }): Promise<IApplePay | undefined> => {
      return new Promise((resolve, reject) => {
        if (!canUseApplePay || requestingPayment) {
          return;
        }
        setRequestingPayment(true);
        onFinishListener.current = addPaymentAuthorizationClosedListener(() => {
          cleanupHandlers();
        });

        onSuccessListener.current = addPaymentAuthorizationListener(
          ({ data: authorizedPaymentData }) => {
            if (!authorizedPaymentData) {
              return;
            }

            const { paymentData, billingInformation, paymentType, displayName } =
              authorizedPaymentData;
            cleanupHandlers();
            if (!paymentData) {
              reject('Did not receive apple pay data');
              return;
            }

            if (!billingInformation) {
              reject('Did not receive Apple Pay billing information');
              return;
            }

            const [parsedPaymentData, parsedBillingInformation] = [
              parseStringifiedJSON({
                value: paymentData,
                onError: e => reject(`Invalid information received from Apple Pay: ${e}`),
              }),
              parseStringifiedJSON({
                value: billingInformation,
                onError: e => reject(`Invalid information received from Apple Pay: ${e}`),
              }),
            ];

            if (!parsedPaymentData || !parsedBillingInformation) {
              // reject would have already triggered in the parse function so no need to call it again here
              return;
            }

            const {
              signature,
              version,
              data,
              header: { ephemeralPublicKey, publicKeyHash, transactionId },
            } = parsedPaymentData;
            const applicationData = '';
            const {
              city,
              street,
              country,
              postalCode,
              state,
              administrativeArea: region,
            } = parsedBillingInformation;

            const formattedBillingAddress = buildFormattedBillingAddress({
              street: String(street),
              city: String(city),
              state: String(state),
              postalCode: String(postalCode),
              country: String(country),
            });

            const billingAddress = {
              locality: city,
              postalCode: sanitizeAlphanumeric(postalCode),
              region: state,
              streetAddress: street,
            };

            const decoratedPaymentData: IApplePay = {
              signature,
              applicationData,
              country: CountryCode[region],
              data,
              decryptAlias: prepaid ? prepaidMerchantId : merchantId,
              ephemeralPublicKey,
              formatted: formattedBillingAddress,
              paymentData,
              primary: true,
              publicKeyHash,
              transactionId,
              type: 'home',
              version,
              billingAddress,
              PaymentMethodData: {
                paymentType,
                displayName,
              },
            };

            resolve(decoratedPaymentData);
          }
        );

        const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
        const payeeName = fullBrandName();
        const itemLabel = formatMessage({ id: 'applePay' });

        makePaymentRequest({
          countryCode,
          currencyCode,
          total: total / 100,
          payeeName,
          merchantId: prepaid ? prepaidMerchantId : merchantId,
          itemLabel,
        });
      });
    },
    [
      canUseApplePay,
      requestingPayment,
      formatMessage,
      prepaid,
      prepaidMerchantId,
      merchantId,
      cleanupHandlers,
    ]
  );

  const requestApplePayPayment = useCallback(
    async ({
      billingCountry,
      orderInformation,
    }: IRequestApplePayPayment): Promise<IOrder | undefined> => {
      const data = await requestApplePay({ billingCountry, total: orderInformation.total });
      return handleApplePayPaymentDetails({
        data,
        orderInformation,
      });
    },
    [requestApplePay, handleApplePayPaymentDetails]
  );

  const requestApplePayPrepaidReload = useCallback(
    async ({ billingCountry, reloadInformation }: IRequestApplePayReload) => {
      const data = await requestApplePay({ billingCountry, total: reloadInformation.reloadAmount });
      return handleApplePayReloadDetails({
        data,
        billingCountry,
        reloadInformation,
      });
    },
    [handleApplePayReloadDetails, requestApplePay]
  );

  return {
    canUseApplePay,
    requestingPayment,
    requestApplePayPayment,
    requestApplePayPrepaidReload,
  };
}
