import {
  setCustomUserAttribute,
  setEmail,
  setFirstName,
  setLanguage,
  setPhoneNumber,
} from './braze-api';

export const setUserAttributes = (
  attributesToSet: Record<string, string | boolean | number | Date>
) => {
  Object.keys(attributesToSet).forEach(attribute => {
    const value = attributesToSet[attribute];

    switch (attribute) {
      case 'name': {
        setFirstName(value.toString());
        return;
      }

      case 'phoneNumber': {
        setPhoneNumber(value.toString());
        return;
      }

      case 'language': {
        setLanguage(value.toString());
        return;
      }

      case 'email': {
        setEmail(value.toString());
        return;
      }

      default: {
        setCustomUserAttribute(attribute, value);
        return;
      }
    }
  });
};
