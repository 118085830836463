import { type ElementRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  Platform,
  TextInput as RnTextInput,
  TextInputProps as RnTextInputProps,
  StyleSheet,
} from 'react-native';

import { useMergedEventHandlers } from '@fhs/utils';

import { tokens } from '../..//tokens';
import { Pressable } from '../pressable';

export type FormControlTextInputProps = Omit<
  RnTextInputProps,
  'editable' | 'placeholderTextColor'
> & {
  readOnly?: boolean;
  pressedStyle?: RnTextInputProps['style'];
  focusedStyle?: RnTextInputProps['style'];
  hoveredStyle?: RnTextInputProps['style'];
  readOnlyStyle?: RnTextInputProps['style'];
};

export const FormControlTextInput = forwardRef<RnTextInput, FormControlTextInputProps>(
  function FormControlTextInput(props, forwardedRef) {
    const inputRef = useRef<ElementRef<typeof RnTextInput> | null>(null);
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleOnFocus = useMergedEventHandlers(props.onFocus, () => setFocused(true));
    const handleOnBlur = useMergedEventHandlers(props.onBlur, () => setFocused(false));

    // rome-ignore lint/style/noNonNullAssertion: inputRef is always forwarded
    useImperativeHandle(forwardedRef, () => inputRef.current!);

    return (
      <Pressable
        accessible={false}
        disabled={props.readOnly}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        {...Platform.select({ web: { tabIndex: -1 } })}
      >
        {({ pressed }) => (
          <RnTextInput
            {...props}
            readOnly={props.readOnly}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            placeholderTextColor={tokens.colors.$placeholderText}
            style={[
              styles.baseline,
              props.style,
              pressed && [styles.pressed, props.pressedStyle],
              focused && [styles.focused, props.focusedStyle],
              hovered && [styles.hovered, props.hoveredStyle],
              props.readOnly && [styles.readOnly, props.readOnlyStyle],
            ]}
            ref={inputRef}
          />
        )}
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  baseline: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: 20,
    color: tokens.colors.$houseDark,
    backgroundColor: tokens.colors.$white,
    minHeight: 48,
    paddingHorizontal: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    ...Platform.select({
      // remove outline on web
      web: { outlineWidth: 0 },
    }),
  },

  pressed: {
    borderColor: tokens.colors.$houseDark,
  },

  focused: {
    borderColor: tokens.colors.$houseDark,
  },

  hovered: {
    backgroundColor: tokens.colors.$blackOpacity04,
  },

  readOnly: {
    color: tokens.colors.$disabledText,
    borderColor: tokens.colors.$blackOpacity30,
    backgroundColor: tokens.colors.$blackOpacity04,
    ...Platform.select({ web: { cursor: 'not-allowed' } }),
  },
});
