import { Logger } from '../../debugger';
import { CartModel } from '../../types';

import { LegacyPriceOrderInput } from './types';

// TODO: `ICart` is not actually the data type we want...
// we want the data shape that belongs to the mutation type
// figure out how we can get that
export async function transformCartToV1Cart(
  platform: 'web' | 'app',
  cart: CartModel,
  logger: Logger
): Promise<LegacyPriceOrderInput> {
  logger.log('transforming to v1 cart');
  // TODO: Remove this code -- mocking this for now because we dont have the frontend hooked up to set the store
  cart.store = { id: '9949', name: 'foo' };

  // calculate subtotal. should this be a property on the model?
  // I worry that someone could mess that up and have the value in the data.
  const requestedAmountCents = cart.entries.reduce((p, e) => p + e.price, 0);
  const restaurant = await fetchStoreInformation(cart.store.id);

  return {
    storeAddress: {
      addressLine1: restaurant.physicalAddress.address1,
      city: restaurant.physicalAddress.city,
      country: restaurant.physicalAddress.country,
      zip: restaurant.physicalAddress.postalCode,
      state: restaurant.physicalAddress.stateProvince,
      phoneNumber: '5619010188', // where do we get this from?
    },
    cartEntries: convertCartEntries(cart.entries),
    storeId: cart.store.id,
    storePosId: restaurant.posRestaurantId,
    brand: 'FHS',
    platform,
    posVendor: restaurant.pos,
    serviceMode: mapServiceModeToV1(cart.serviceMode),
    requestedAmountCents,
    rewardsApplied: null,
    cartVersion: 1,
    customerLocale: 'en-US',
    customerName: null,
    appliedOffers: [],
    redeemReward: false,
  };
}

function convertCartEntries(entries: CartModel['entries']) {
  // TODO: This only handles items and not combos or offers
  return entries.map(entry => ({
    image: '',
    lineId: entry.lineId,
    name: entry.displayName,
    price: entry.price,
    sanityId: 'e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3', // TODO: need access to this id for now.
    quantity: 1,
    url: '/menu/item-e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3',
    type: 'Item',
    vendorConfigs: {
      ncr: makePlu('41110'),
      ncrDelivery: makePlu('41110'),
      toast: makePlu('900000000526264403/900000000301988522'),
      toastDelivery: makePlu('900000000526264403/900000000301988522'),
    },

    // TODO: Handle modifiers
    children: [],
  }));
}

///------------------------------
/// Map new serviceMode values to the ICart serviceModel enum
function mapServiceModeToV1(serviceMode: CartModel['serviceMode']) {
  switch (serviceMode) {
    case 'delivery':
      return 'DELIVERY';
    case 'pickup':
      return 'TAKEOUT';
  }
}

///------------------------------
/// Get sanity restaurant info to fill out the ICart model
type SanityRestaurant = {
  physicalAddress: {
    address1: string;
    postalCode: string;
    stateProvince: string;
    country: string;
    city: string;
  };
  pos: string;
  posRestaurantId: string;
};
async function fetchStoreInformation(storeId: string): Promise<SanityRestaurant> {
  const query = encodeURIComponent(`
*[number == "${storeId}"][0]{
    physicalAddress {address1,postalCode,stateProvince,country,city},
    'pos': pos.vendor,
    posRestaurantId
}`);
  const res = await fetch(
    `https://czqk28jt.api.sanity.io/v2021-03-25/data/query/dev_fhs_us?query=${query}`
  );
  const json = (await res.json()) as { result: SanityRestaurant };

  return json.result;
}

function makePlu(constantPlu?: string) {
  return {
    pluType: constantPlu ? 'constantPlu' : 'ignore',
    constantPlu: constantPlu || null,
    discountPlu: null,
    pullUpLevels: null,
    sizeBasedPlu: null,
    quantityBasedPlu: null,
    multiConstantPlus: null,
    parentChildPlu: null,
    parentSanityId: null,
  };
}
