import { IntlFormatters } from 'react-intl';

import { CardBrand } from 'generated/graphql-gateway';

export const cardBrandToNameMapping = {
  [CardBrand.AMEX]: 'AMEX',
  [CardBrand.DINERS_CLUB]: 'DINERS CLUB',
  [CardBrand.DISCOVER]: 'DISCOVER',
  [CardBrand.JCB]: 'JCB',
  [CardBrand.MASTERCARD]: 'MASTERCARD',
  [CardBrand.VISA]: 'VISA',
};

export const formatExpirationDate = ({
  expiryMonth,
  expiryYear,
  formatMessage,
}: {
  expiryMonth: string;
  expiryYear: string;
  formatMessage: IntlFormatters['formatMessage'];
}) => {
  if (!expiryYear || !expiryMonth) {
    return;
  }
  const expirationDate = new Date(2000 + parseInt(expiryYear, 10), parseInt(expiryMonth, 10));
  const currentDate = new Date();
  const isValid = expirationDate.getTime() - currentDate.getTime();

  return `${
    isValid ? formatMessage({ id: 'expire' }) : formatMessage({ id: 'expired' })
  } ${expiryMonth}/${expiryYear}`;
};
