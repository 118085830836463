import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { PrepaidPaymentMethod } from 'components/payments/integrations/hooks/types';
import { CARD_ALIAS_TRANSLATION_ID } from 'utils/gift-card/constants';
import { routes } from 'utils/routing';

export interface ITransferGiftCardButtonProps {
  prepaidMethod: PrepaidPaymentMethod | undefined;
}

export const TransferGiftCardButton: React.FC<ITransferGiftCardButtonProps> = ({
  prepaidMethod,
}) => {
  const { formatMessage } = useIntl();

  const handleOnPress = useCallback(() => {
    router.navigate({
      pathname: routes.transferGiftCard,
      params: {
        currentBalance: prepaidMethod?.balance,
      },
    });
  }, [prepaidMethod?.balance]);

  const transferBalanceFromPrepaidCardText = formatMessage(
    { id: 'transferBalanceFromPrepaidCard' },
    { cardAlias: formatMessage({ id: CARD_ALIAS_TRANSLATION_ID }) }
  );

  if (!prepaidMethod) {
    return null;
  }

  return (
    <ActionButton
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; transparent: true; fullW... Remove this comment to see the full error message
      transparent
      fullWidth
      variant={ActionButtonVariants.OUTLINE}
      onPress={handleOnPress}
      testID="transfer-gift-card"
    >
      {transferBalanceFromPrepaidCardText}
    </ActionButton>
  );
};
