import { useCallback } from 'react';

import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { CardBrand, IOrder, IPaymentInput } from 'generated/graphql-gateway';
import { IOrderInformation } from 'pages/cart/payments/types';
import { useAuthContext } from 'state/auth';

interface IMakeVaultedPayment {
  accountIdentifier: string;
  cardBrand: CardBrand;
  orderInformation: IOrderInformation;
}

export const useWorldpayVaultedPayment = (rbiOrderId: string) => {
  const { user } = useAuthContext();
  const { handleCommitOrder } = useCommitOrder(rbiOrderId);
  const requestVaultedPayment = useCallback(
    async ({
      accountIdentifier,
      cardBrand,
      orderInformation,
    }: IMakeVaultedPayment): Promise<IOrder | undefined> => {
      // Build the payment input for a one time payment method
      const worldpayPaymentInput: IPaymentInput = {
        worldpayInput: {
          storedPaymentMethodId: accountIdentifier,
        },
        fullName: user?.details.name ?? '',
      };

      // Handle committing the order
      return handleCommitOrder({
        cardBrand,
        orderInformation,
        payment: worldpayPaymentInput,
      });
    },
    [handleCommitOrder, user?.details.name]
  );

  return {
    requestVaultedPayment,
  };
};
