import { IShowPlaceOrderText } from './types';

export const getPlaceOrderButtonText = ({
  reloadAndPlaceOrder = 0,
  confirmation = false,
  secureOrderText = false,
}: IShowPlaceOrderText) => {
  if (reloadAndPlaceOrder) {
    return confirmation ? 'reloadAndPlaceOrder' : 'reloadAndContinue';
  }
  const placeOrderText = secureOrderText ? 'placeSecureOrder' : 'placeOrder';
  return confirmation ? placeOrderText : 'continue';
};
