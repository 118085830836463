import React from 'react';

import { Icon, Pressable } from '@fhs-legacy/universal-components';
import { RotateAnimatedView } from 'components/ucl';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export type DisplayToggleProps = {
  collapsed: boolean;
  onPress: () => void;
};

export const PaymentMethodDropdownToggle: React.FC<DisplayToggleProps> = ({
  collapsed,
  onPress,
}) => (
  <Pressable
    testID="display-toggle-payment-method"
    accessibilityLabel="Toggle to select payment method"
    accessibilityRole="button"
    onPress={onPress}
  >
    <RotateAnimatedView isRotated={!collapsed}>
      <Icon
        variant="select"
        color={theme.token('icon-form-input-action')}
        width="24px"
        {...hiddenAccessibilityPlatformProps}
      />
    </RotateAnimatedView>
  </Pressable>
);
