import { GestureResponderEvent, StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable } from '../pressable';
import { Text } from '../text';

type RadioButtonProps = {
  label?: string;
  selected: boolean;
  onSelect: (event: GestureResponderEvent) => void;
};

export const RadioButton = ({ label, selected, onSelect }: RadioButtonProps) => {
  return (
    <Pressable
      style={styles.container}
      onPress={onSelect}
      accessibilityRole="radio"
      accessibilityState={{ checked: selected }}
    >
      <View style={[styles.outerCircle, selected && styles.selectedOuterCircle]}>
        {selected ? <View style={styles.innerCircle} /> : null}
      </View>
      {label && <Text style={styles.label}>{label}</Text>}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  outerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  innerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderColor: tokens.colors.$black,
    borderWidth: 6,
    backgroundColor: tokens.colors.$white,
  },
  selectedOuterCircle: {
    borderColor: tokens.colors.$black,
  },
  label: {
    fontSize: 16,
  },
});
