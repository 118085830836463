import { HStack, Pressable } from '@fhs-legacy/universal-components';

export interface IMethodContainerProps {
  ariaLabelledby?: string;
  disableHover?: boolean;
  disableMethod?: boolean;
  hideBorder?: boolean;
  testID?: string;
}

interface IMethodTypeWrapperProps {
  dataPrivate?: boolean;
  disableMethod: boolean;
  onPress?: () => void;
  testID: string;
}

export const StyledMethodContainer = HStack.withConfig<IMethodContainerProps>({
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '$13.5',
  width: 'full',
  padding: '$2',
});

export const StyledMethodTypeWrapper = Pressable.withConfig<IMethodTypeWrapperProps>({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  flexShrink: 1,
});

export const StyledMethodTypeInnerWrapper = HStack.withConfig({
  flex: 1,
  flexWrap: 'wrap',
});
