import React from 'react';

import {
  AddGiftCardModal,
  SuccessTransferGiftCardModal,
  TransferGiftCardModal,
} from 'components/payments/integrations/firstdata/components/gift-card-modal';

export const FirstDataAddGiftCard = (): JSX.Element => {
  return <AddGiftCardModal />;
};

export const FirstDataTransferGiftCard = (): JSX.Element => {
  return <TransferGiftCardModal />;
};

export const FirstDataSuccessTransferGiftCard = (): JSX.Element => {
  return <SuccessTransferGiftCardModal />;
};
