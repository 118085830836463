import React, { PropsWithChildren } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { KeyboardAvoidingView } from '@fhs-legacy/universal-components';

interface KeyboardAwareViewProps {
  /**
   * Enables scroll behaviour while the fields are focused.
   * It is recomended to set `withScroll` to `true` if the view being wrapped
   * exceeds the screen vertical height
   *
   *
   * @type {boolean}
   * @memberof KeyboardAwareViewProps
   */
  withScroll?: boolean;
}

export const KeyboardAwareView: React.FC<KeyboardAwareViewProps & PropsWithChildren> = ({
  children,
  withScroll = false,
}) => {
  const insets = useSafeAreaInsets();
  const headerHeight = 56 + insets.top;

  if (withScroll) {
    return (
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        extraScrollHeight={headerHeight}
      >
        {children}
      </KeyboardAwareScrollView>
    );
  }

  return (
    <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={0}>
      {children}
    </KeyboardAvoidingView>
  );
};
