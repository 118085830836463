import { useCallback, useMemo } from 'react';

import { Toast } from '@fhs-legacy/native-base';

import { Alert } from '../alert';
import { HStack } from '../h-stack';
import { Pressable } from '../pressable';
import { Text } from '../text';

import type { ToastProps, _ToastHook, _ToastTemplateProps, _ToastVariantMapType } from './types';

const TOAST_DISPLAY_TIME = 5000;

const ToastVariantMap: _ToastVariantMapType = {
  informative: 'info',
  positive: 'success',
  negative: 'error',
  neutral: undefined,
};

const ToastTemplate: React.VFC<_ToastTemplateProps> = ({
  text,
  render,
  testID,
  variant,
  style,
  onClose,
}) => (
  <Pressable
    flexDirection="row"
    flexBasis="100%"
    onPress={onClose}
    accessibilityRole="button"
    accessibilityHint="Close alert"
    paddingX={3}
    testID={testID}
    style={style}
  >
    <Alert
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={ToastVariantMap[variant]}
      variant="black"
      accessibilityLiveRegion="polite"
      accessibilityLabel={text}
      accessibilityHint=""
      marginX={0}
      marginBottom={0}
    >
      <HStack space={2} flexShrink={1} flexGrow={1} alignItems="center">
        {variant !== 'neutral' && <Alert.Icon />}
        {render?.() || (
          <Text fontSize="md" fontWeight="medium" color="white" flexShrink={1}>
            {text}
          </Text>
        )}
      </HStack>
    </Alert>
  </Pressable>
);

export const useToast = (
  defaultToastArgs: Partial<Pick<ToastProps, 'duration' | 'placement'>> = {}
): _ToastHook => {
  const show = useCallback(
    (args: ToastProps) => {
      if (Toast.isActive(args.id)) {
        return;
      }

      const toastId = Toast.show({
        id: args.id,
        duration: args.duration ?? defaultToastArgs.duration ?? TOAST_DISPLAY_TIME,
        isClosable: true,
        placement: args.placement ?? defaultToastArgs.placement ?? 'top',
        render: () => (
          <ToastTemplate
            text={args.text}
            variant={args.variant || 'neutral'}
            onClose={() => Toast.close(toastId)}
            testID={args.testID}
            style={args.style}
            render={args.render}
          />
        ),
      });
    },
    [defaultToastArgs.duration, defaultToastArgs.placement]
  );
  return useMemo(
    () => ({
      show,
    }),
    [show]
  );
};
