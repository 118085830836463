import {
  AddCreditCardFormAddress,
  IAddCreditCardFormValues,
  IDeliveryAddress,
} from 'components/payments/components/add-credit-card-form-inputs/types';
import { ISOs } from 'utils/form/constants';

import { DEFAULT_BILLING_COUNTRY } from './default-billing-country';

export const mapDeliveryAddressToCreditCardForm = (
  deliveryAddress: Partial<IDeliveryAddress>
): AddCreditCardFormAddress => {
  const {
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zip = '',
  } = deliveryAddress || {};
  return {
    billingStreetAddress: addressLine1,
    billingApt: addressLine2,
    billingCity: city,
    billingState: state,
    billingZip: zip,
  };
};

export const initialCreditCardFormValues = ({
  isDelivery = false,
  deliveryAddress = {},
  billingCountry = DEFAULT_BILLING_COUNTRY,
  userDetailsName = '',
}: {
  isDelivery?: boolean;
  billingCountry?: ISOs;
  deliveryAddress?: Partial<IDeliveryAddress>;
  userDetailsName?: string;
} = {}): IAddCreditCardFormValues => ({
  nameOnCard: userDetailsName ?? '',
  cardNumber: '',
  isCardNumberValid: false,
  isExpiryValid: false,
  cardBrand: '',
  cvv: '',
  expiry: '',
  ...mapDeliveryAddressToCreditCardForm(isDelivery ? deliveryAddress : {}),
  billingAddressSameAsDelivery: isDelivery,
  saveCard: true,
  billingCountry,
});
