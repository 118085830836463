import { useLocalSearchParams } from 'expo-router';
import React, { useCallback, useEffect } from 'react';

import { useDisclose } from '@fhs-legacy/universal-components';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';

import { LoyaltyPromoCodeCTA } from './loyalty-promo-code-cta';
import { LoyaltyPromoCodeDrawer } from './loyalty-promo-code-drawer';
import { IPromoCodeProps } from './types';

export const LoyaltyPromoCode = ({ onPromoCodeRedeemSuccess }: IPromoCodeProps) => {
  const params = useLocalSearchParams<{ promoCode?: string }>();
  const { isOpen, onOpen, onClose } = useDisclose(!!params.promoCode);
  const { logRBIEvent } = useCRMEventsContext();
  const onDismiss = useCallback(() => {
    onClose();
  }, [onClose]);

  const onOpenHandler = () => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.PROMO_CODE_TILE,
        text: 'Add Promo Code',
      },
    });
    onOpen();
  };

  useEffect(() => {
    if (!isOpen && params.promoCode) {
      // This delay is necessary in order to allow the component to be ready to show the Actionsheet.
      setTimeout(onOpen, 500);
    }
  }, [isOpen, onOpen, params.promoCode]);

  return (
    <>
      <LoyaltyPromoCodeCTA onPress={onOpenHandler} />
      {/* Hack to be able to use React Navigation navigate method from the Actionsheet component */}
      {/* Navigate won't trigger unless Actionsheet is unmounted */}
      {isOpen ? (
        <LoyaltyPromoCodeDrawer
          isOpen={isOpen}
          onDismiss={onDismiss}
          promoCode={params.promoCode?.toString()}
          onPromoCodeRedeemSuccess={onPromoCodeRedeemSuccess}
          onNavigate={onClose}
        />
      ) : null}
    </>
  );
};
