import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  addPaymentAuthorizationClosedListener,
  addPaymentAuthorizationListener,
  canMakePayments,
  isSupported,
  makePaymentRequest,
} from '@rbilabs/expo-applepay';
import {
  IRequestApplePayPayment,
  IUseApplePay,
} from 'components/payments/integrations/hooks/types';
import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { CartPaymentCardType, CountryCode, IApplePay, IOrder } from 'generated/graphql-gateway';
import useAuth from 'hooks/auth/use-auth';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { IOrderInformation } from 'pages/cart/payments/types';
import { useCartContext } from 'state/cart';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { brand, env, fullBrandName } from 'utils/environment';
import { sanitizeAlphanumeric } from 'utils/form';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import { buildFormattedBillingAddress } from 'utils/native-actions';
import noop from 'utils/noop';
import { parseStringifiedJSON } from 'utils/parse-string';

interface IEProtectPayPageResponse {
  bin: string;
  firstSix: string;
  lastFour: string;
  paypageRegistrationId: string;
  type: string;
  id: string;
  vantivTxnId: string;
  message: string;
  orderId: string;
  reportGroup: string;
  response: string;
  responseTime: string;
  expDate: string;
}

export default function useWorldpayApplePay({ prepaid = false }: IUseApplePay) {
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const { serverOrder } = useCartContext();
  const [canUseApplePay, setCanUseApplePay] = useState(false);
  const [requestingPayment, setRequestingPayment] = useState(false);
  const { user } = useAuth();

  const { handleCommitOrder } = useCommitOrder(serverOrder?.rbiOrderId ?? '');

  const applePayPluginAvailable = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY) && isSupported();
  const migrationEnabled = useFlag(LaunchDarklyFlag.ENABLE_DIGITAL_WALLET_MIGRATION);

  const appleConfig = useConfigValue({ key: 'apple', defaultValue: {}, isRegionalized: false });
  const paymentsNetworks = appleConfig.paymentsNetworks;
  const merchantId =
    migrationEnabled && appleConfig.migrationMerchantId
      ? appleConfig.migrationMerchantId
      : appleConfig.merchantId;
  const prepaidMerchantId =
    migrationEnabled && appleConfig.migrationPrepaidMerchantId
      ? appleConfig.migrationPrepaidMerchantId
      : appleConfig.prepaidMerchantId;

  const worldpayConfig = useConfigValue({ key: 'worldpay', defaultValue: {} });

  // Device canUseApplePay if apple pay available and least one card in wallet
  useEffect(() => {
    if (applePayPluginAvailable) {
      canMakePayments({
        networks: paymentsNetworks,
      })
        .then(({ available = false, hasCards = false }) => {
          const deviceCanMakePaymentsResult = available && hasCards;
          logger.info(`Device can${deviceCanMakePaymentsResult ? '' : 'not'} make payment`);
          setCanUseApplePay(deviceCanMakePaymentsResult);
        })
        .catch(() => {
          logger.info('Device can not make payment - api rejected');
          setCanUseApplePay(false);
        });
    }
  }, [applePayPluginAvailable, paymentsNetworks]);

  const onSuccessListener = useRef({ remove: noop });
  const onFinishListener = useRef({ remove: noop });

  const cleanupHandlers = useCallback(() => {
    setRequestingPayment(false);
    [onFinishListener, onSuccessListener].forEach(({ current }) => current && current.remove());
  }, [onFinishListener, onSuccessListener]);

  useEffect(() => {
    return cleanupHandlers;
  }, [cleanupHandlers]);

  const handleApplePayPaymentDetails = useCallback(
    async ({
      applePayDetails,
      orderInformation,
      lowValueToken,
    }: {
      applePayDetails: IApplePay;
      lowValueToken: string;
      orderInformation: IOrderInformation;
    }): Promise<IOrder | undefined> => {
      const worldPayPaymentInput = {
        applePayDetails,
        worldpayInput: {
          lowValueToken,
          storePaymentMethod: false,
        },
        fullName: '',
      };

      return handleCommitOrder({
        cardBrand: CartPaymentCardType.APPLE_PAY,
        orderInformation,
        payment: worldPayPaymentInput,
      });
    },
    [handleCommitOrder]
  );

  // Request Apple Pay Debundle - resolves with Apple Pay details
  const requestApplePay = useCallback(
    ({
      billingCountry,
      total,
    }: {
      billingCountry: ISOs;
      total: number;
    }): Promise<IApplePay | undefined> => {
      return new Promise((resolve, reject) => {
        if (requestingPayment) {
          return;
        }
        setRequestingPayment(true);
        onFinishListener.current = addPaymentAuthorizationClosedListener(() => {
          cleanupHandlers();
        });

        onSuccessListener.current = addPaymentAuthorizationListener(
          ({ data: authorizedPaymentData }) => {
            if (!authorizedPaymentData) {
              return;
            }

            const { paymentData, billingInformation, paymentType, displayName } =
              authorizedPaymentData;
            cleanupHandlers();
            if (!paymentData) {
              reject('Did not receive apple pay data');
              return;
            }

            if (!billingInformation) {
              reject('Did not receive Apple Pay billing information');
              return;
            }

            const [parsedPaymentData, parsedBillingInformation] = [
              parseStringifiedJSON({
                value: paymentData,
                onError: e => reject(`Invalid information received from Apple Pay: ${e}`),
              }),
              parseStringifiedJSON({
                value: billingInformation,
                onError: e => reject(`Invalid information received from Apple Pay: ${e}`),
              }),
            ];

            if (!parsedPaymentData || !parsedBillingInformation) {
              // reject would have already triggered in the parse function so no need to call it again here
              return;
            }

            const {
              signature,
              version,
              data,
              header: { ephemeralPublicKey, publicKeyHash, transactionId },
            } = parsedPaymentData;
            const applicationData = '';
            const { city, street, country, postalCode, state } = parsedBillingInformation;

            const formattedBillingAddress = buildFormattedBillingAddress({
              street: String(street),
              city: String(city),
              state: String(state),
              postalCode: String(postalCode),
              country: String(country),
            });

            const billingAddress = {
              locality: city,
              postalCode: sanitizeAlphanumeric(postalCode),
              region: state,
              streetAddress: street,
            };

            const decoratedPaymentData: IApplePay = {
              signature,
              applicationData,
              country: CountryCode[region],
              data,
              decryptAlias: prepaid ? prepaidMerchantId : merchantId,
              ephemeralPublicKey,
              formatted: formattedBillingAddress,
              paymentData,
              primary: true,
              publicKeyHash,
              transactionId,
              type: 'home',
              version,
              billingAddress,
              PaymentMethodData: {
                paymentType,
                displayName,
              },
            };

            resolve(decoratedPaymentData);
          }
        );

        const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
        const payeeName = fullBrandName();
        const itemLabel = formatMessage({ id: 'applePay' });

        makePaymentRequest({
          countryCode,
          currencyCode,
          total: total / 100,
          payeeName,
          merchantId: prepaid ? prepaidMerchantId : merchantId,
          itemLabel,
        });
      });
    },
    [requestingPayment, formatMessage, prepaid, prepaidMerchantId, merchantId, cleanupHandlers]
  );

  const requestLowValueToken = useCallback(
    async ({
      applePayDetails,
      orderInformation,
    }: {
      applePayDetails: IApplePay;
      orderInformation: IOrderInformation;
    }): Promise<string> => {
      try {
        const { eProtectUrl, paypageId } = worldpayConfig;

        if (!eProtectUrl) {
          throw new Error('Worldpay eProtectUrl not available');
        }

        if (!paypageId) {
          throw new Error('Worldpay paypageId not available');
        }

        const merchantOrderId = orderInformation.rbiOrderId.slice(0, 20);
        const merchantReportGroup = `${env()}-${brand()}-${region}`.toUpperCase();

        const data = {
          paypageId,
          reportGroup: merchantReportGroup,
          orderId: merchantOrderId,
          id: merchantOrderId,
          accountNumber: user?.cognitoId || '',
          'applepay.data': applePayDetails.data,
          'applepay.signature': applePayDetails.signature,
          'applepay.version': applePayDetails.version,
          'applepay.header.applicationData': '',
          'applepay.header.ephemeralPublicKey': applePayDetails.ephemeralPublicKey,
          'applepay.header.publicKeyHash': applePayDetails.publicKeyHash,
          'applepay.header.transactionId': applePayDetails.transactionId,
        };

        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        };

        const body = new URLSearchParams(data);
        const paypageUrl = `${eProtectUrl}/eProtect/paypage`;

        const resData = await fetch(paypageUrl, { method: 'POST', body, headers }).then(res =>
          res.json()
        );

        if (!resData) {
          throw new Error('Something went wrong trying to get payment token');
        }

        const eProtectResponse = resData as IEProtectPayPageResponse;

        if (!eProtectResponse.paypageRegistrationId) {
          throw new Error('eProtect request failed');
        }

        // Token
        return eProtectResponse.paypageRegistrationId;
      } catch (error) {
        throw new Error('Error requesting low value token');
      }
    },
    [worldpayConfig, region, user?.cognitoId]
  );

  const requestApplePayPayment = useCallback(
    async ({
      billingCountry,
      orderInformation,
    }: IRequestApplePayPayment): Promise<IOrder | undefined> => {
      const applePayDetails = await requestApplePay({
        billingCountry,
        total: orderInformation.total,
      });

      if (!applePayDetails) {
        logger.warn('No apple pay debundle returned. Are you on simulator?');
        return undefined;
      }

      const lowValueToken = await requestLowValueToken({
        applePayDetails,
        orderInformation,
      });

      return handleApplePayPaymentDetails({
        applePayDetails,
        orderInformation,
        lowValueToken,
      });
    },
    [requestApplePay, requestLowValueToken, handleApplePayPaymentDetails]
  );

  return {
    canUseApplePay,
    requestingPayment,
    requestApplePayPayment,
  };
}
