import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { splitExpiry } from 'components/payments/components/add-credit-card-form-inputs/utils';
import { IAddPaymentMethodPayload } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/types';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { sanitizeAlphanumeric, sanitizeNumber } from 'utils/form';

export const transformPaymentMethodValues = ({
  paymentMethodValues,
  paymentFieldVariations = defaultPaymentFieldVariation,
}: {
  paymentMethodValues: IAddCreditCardFormValues;
  paymentFieldVariations?: PaymentFieldVariations;
}): IAddPaymentMethodPayload => {
  const cardNumber = sanitizeNumber(paymentMethodValues.cardNumber);
  const { expiryMonth, expiryYear } = splitExpiry(paymentMethodValues.expiry ?? '');
  const expiryDate = paymentMethodValues.expiry
    ? {
        month: expiryMonth,
        year: expiryYear,
      }
    : null;

  const postalCode = sanitizeAlphanumeric(paymentMethodValues.billingZip);

  return {
    billingAddress: {
      country: null,
      postalCode: paymentFieldVariations.zip ? postalCode : '',
      unitNumber: paymentFieldVariations.addressLine2 ? paymentMethodValues.billingApt : null,
      locality: paymentFieldVariations.city ? paymentMethodValues.billingCity : null,
      region: paymentFieldVariations.state ? paymentMethodValues.billingState : null,
      streetAddress: paymentFieldVariations.addressLine1
        ? paymentMethodValues.billingStreetAddress
        : null,
    },
    cardNumber,
    cardType: paymentMethodValues.cardBrand,
    expiryDate,
    nameOnCard: paymentMethodValues.nameOnCard,
    securityCode: paymentMethodValues.cvv,
  };
};
