import { router } from 'expo-router';
import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { OrderStatus } from 'state/order';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { routes } from 'utils/routing';

import { APPROX_MINUTES_AWAY_TRANSLATION_ID } from './constants';
import { ButtonContainer, ButtonSubText, ButtonText } from './styled';
import { ICardButton } from './types';

export const PickupCardButton: React.FC<React.PropsWithChildren<ICardButton>> = ({
  onCommit,
  isLoading,
  restaurant,
  orderStatus,
  shouldShowImCloseText,
}) => {
  const { formatMessage } = useIntl();
  const hasCheckoutError = useRef(false);
  const onConfirmAlert = useCallback(() => {
    if (hasCheckoutError.current) {
      router.navigate(routes.cart);

      return;
    }
  }, []);

  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: onConfirmAlert,
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });

  useEffectOnUpdates(() => {
    if (orderStatus === OrderStatus.INSERT_ERROR) {
      // @todo - we need to evaluate the best path
      // when a user payment succeeds but insert fails
      openAlert({
        body: formatMessage({ id: 'couldntProcessPayment' }),
        buttonLabel: formatMessage({ id: 'retry' }),
        heading: formatMessage({ id: 'paymentFailed' }),
      });
    }
  }, [orderStatus]);

  const imClose = {
    enabled: shouldShowImCloseText,
    text: formatMessage({ id: 'imClose' }),
    approxMinutesAway: formatMessage({ id: APPROX_MINUTES_AWAY_TRANSLATION_ID }),
  };

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
    storeId: restaurant.number,
  });

  let buttonText = formatMessage({ id: isRestaurantPosOnline ? 'storeUnavailable' : 'imHere' });

  if (imClose.enabled) {
    buttonText = imClose.text;
  }

  return (
    <ButtonContainer>
      <ActionButton
        fullWidth
        accessibilityLabel={buttonText}
        testID="im-here-button"
        isLoading={isLoading}
        onPress={onCommit}
        disabled={!isRestaurantPosOnline}
      >
        <ButtonText>{buttonText}</ButtonText>
        {imClose.enabled && (
          <ButtonSubText testID="im-close-button">{imClose.approxMinutesAway}</ButtonSubText>
        )}
      </ActionButton>
      <AlertDialog {...(alertMessage || {})} />
    </ButtonContainer>
  );
};
