import React from 'react';

import { Box, Text, Header as UCLHeader, addWithConfig } from '@fhs-legacy/universal-components';

import theme from './theme';
import { IAccountLayout } from './types';

const Container = Box.withConfig<IAccountLayout>(p => ({
  flex: 1,
  paddingTop: {
    base: '20px',
    desktop: '75px',
  },
  paddingBottom: '100px',
  paddingX: {
    base: '5%',
    desktop: '0',
  },
  maxWidth: {
    base: 'full',
    desktop: p.fullWidth ? 'full' : '485px',
  },
  width: 'full',
  marginX: 'auto',
}));

const HeadingContainer = Box.withConfig({
  marginTop: '10',
  marginBottom: '5',
});

const Header = UCLHeader.withConfig({
  textAlign: 'center',
  textTransform: 'none',
  margin: 0,
  lineHeight: theme.headerLineHeight,
});

const Subheader = Text.withConfig({});

const LayoutRoot: React.FC<React.PropsWithChildren<IAccountLayout>> = ({
  children,
  header,
  subHeader = '',
  fullWidth = false,
  ...rest
}) => (
  <Container fullWidth={fullWidth} header={header} {...rest}>
    {header && (
      <HeadingContainer>
        <Header>{header}</Header>
        {subHeader && <Subheader>{subHeader}</Subheader>}
      </HeadingContainer>
    )}
    {children}
  </Container>
);

type Layout = typeof LayoutRoot & { Header: typeof Header };
const Layout = addWithConfig(LayoutRoot as Layout);
Layout.Header = Header;

export default Layout;
