import { router } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { routes } from 'utils/routing';

import { ADD_NEW_GIFT_CARD_TRANSLATION_ID } from './constants';

export const AddGiftCardButton = () => {
  const { formatMessage } = useIntl();

  const handleOnPress = () => {
    router.navigate(routes.addGiftCard);
  };

  return (
    <ActionButton
      fullWidth
      variant={ActionButtonVariants.OUTLINE}
      onPress={handleOnPress}
      testID="add-new-gift-card"
    >
      {formatMessage({
        id: ADD_NEW_GIFT_CARD_TRANSLATION_ID,
      })}
    </ActionButton>
  );
};
