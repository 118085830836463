import { FlatList, StyleSheet, View } from 'react-native';

import { Button, IconChevronRight, ScrollView, Text, tokens } from '@fhs/ui';

import { OfferListSkeletonTile } from '../components/offer-list-skeleton-tile';
import { OfferListTile } from '../components/offer-list-tile';

import { GetOffers, useQuery } from './client';

export function OffersAndRewardsPage() {
  const { data, fetching } = useQuery(GetOffers);

  return (
    <ScrollView style={styles.pageContainer}>
      <View style={styles.header}>
        <Text.Heading type="three">Offers</Text.Heading>
        <Button type="ghost" size="sm">
          <Button.Text>See All</Button.Text>
          <Button.Icon>
            <IconChevronRight />
          </Button.Icon>
        </Button>
      </View>

      <FlatList
        horizontal
        data={data}
        contentContainerStyle={styles.flatList}
        style={{ flexGrow: 0, paddingBottom: 32 }}
        keyExtractor={d => d.id}
        ListEmptyComponent={() => {
          if (fetching) {
            return (
              <View style={styles.skeletonListContainer}>
                <OfferListSkeletonTile />
                <OfferListSkeletonTile />
                <OfferListSkeletonTile />
                <OfferListSkeletonTile />
              </View>
            );
          }
          return (
            <View style={{ flex: 1, padding: 16, alignItems: 'center' }}>
              <Text.Heading type="one">No Offers</Text.Heading>
            </View>
          );
        }}
        renderItem={({ item }) => <OfferListTile {...item} />}
      />

      <View style={styles.header}>
        <Text.Heading type="three">My Rewards</Text.Heading>
        <Button type="ghost" size="sm">
          <Button.Text>See All</Button.Text>
          <Button.Icon>
            <IconChevronRight />
          </Button.Icon>
        </Button>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  flatList: { gap: 12 },
  skeletonListContainer: { gap: 12, flexDirection: 'row' },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 12,
  },
  pageContainer: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
    padding: 16,
  },
});
