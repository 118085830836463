import { MenuSection, MenuSectionHeaders } from '../../types';

import { getMenuItemData } from './menu-item-data';

export const menuSectionData: Array<MenuSection> = [
  // Sections
  {
    id: 'hot-subs',
    slug: 'hot-subs',
    displayName: 'Hot Subs',
    items: [
      // Items
    ],
  },
  {
    id: 'cold-subs',
    slug: 'cold-subs',
    displayName: 'Cold Subs',
    items: [getMenuItemData('hook-and-ladder'), getMenuItemData('firehouse-hero')],
  },
  {
    id: 'build-your-own',
    slug: 'build-your-own',
    displayName: 'Build Your Own',
    items: [getMenuItemData('hook-and-ladder'), getMenuItemData('firehouse-hero')],
  },
];

export const getMenuListData = () => {
  // TODO this should be ordered
  const menuSectionsHeader: MenuSectionHeaders[] = menuSectionData.map(section => ({
    id: section.id,
    displayName: section.displayName,
    slug: section.slug,
    to: `/v2/menu/?section=${section.slug}`,
  }));

  menuSectionData[0].items = [
    getMenuItemData('pepperoni-pizza-meatball-sub'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
  ];
  menuSectionData[1].items = [
    getMenuItemData('pepperoni-pizza-meatball-sub'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
  ];
  menuSectionData[2].items = [
    getMenuItemData('pepperoni-pizza-meatball-sub'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
    getMenuItemData('hook-and-ladder'),
    getMenuItemData('firehouse-hero'),
  ];

  return {
    menuSectionsHeader,
    menuSectionData,
  };
};
