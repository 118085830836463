import {
  applePayCardDetails,
  cashDetails,
  googlePayCardDetails,
} from 'components/payments/components/payment-method-option/constants';
import {
  usePaymentPreferencesQuery,
  useStoredPaymentMethodsQuery,
} from 'generated/graphql-gateway';
import useCanUseApplePay from 'state/apple-pay/hooks/use-can-use-apple-pay';
import useGooglePay from 'state/google-pay/hooks/use-google-pay';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { PaymentMethod, PrepaidPaymentMethod, SchemePaymentMethod } from './types';
import { addMethodWhen, isCreditCardMethod, isGiftCardMethod } from './utils';

interface IUsePaymentMethodsAdapter {
  availablePaymentMethods: PaymentMethod[];
  defaultPaymentMethod: PaymentMethod | undefined;
  loading: boolean;
}

export const usePaymentMethodsAdapter = (): IUsePaymentMethodsAdapter => {
  // Find which payment methods are enabled (this excludes a user's stored credit cards)
  const canUseApplePay = useCanUseApplePay();
  const { canUseGooglePay } = useGooglePay();
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const enableCashPayment = useFlag(LaunchDarklyFlag.ENABLE_CASH_PAYMENT);

  // Get the user's stored payment methods and preferences
  // TODO: Payments Refactor - Remove these queries in favor of useUserPaymentInformationQuery
  const { loading: paymentMethodsLoading, data: paymentMethodsData } = useStoredPaymentMethodsQuery(
    { fetchPolicy: 'cache-and-network' }
  );
  const { loading: preferencesLoading, data: preferencesData } = usePaymentPreferencesQuery();
  const storedPaymentMethods = paymentMethodsData?.storedPaymentMethods || [];

  // Get the user's stored credit and gift cards (excludes apple/google pay).
  // Users can only have one stored gift card at this time and it must be listed before credit cards.
  const sortedStoredPaymentMethods = storedPaymentMethods.reduce<
    Array<SchemePaymentMethod | PrepaidPaymentMethod>
  >((acc, method) => {
    if (isCreditCardMethod(method)) {
      acc.push(method);
    } else if (enableGiftCard && isGiftCardMethod(method)) {
      acc.unshift(method);
    }
    return acc;
  }, []);

  /**
   * Sort the available payment methods in the following order:
   * 1. Native credit methods - Apple and Google Pay
   * 2. Gift Cards
   * 3. Credit Cards
   * 4. Cash
   */
  const availablePaymentMethods = [
    ...addMethodWhen(canUseApplePay, applePayCardDetails),
    ...addMethodWhen(canUseGooglePay, googlePayCardDetails),
    ...sortedStoredPaymentMethods,
    ...addMethodWhen(enableCashPayment, cashDetails),
  ];

  const defaultPaymentMethod = availablePaymentMethods.find(
    method => method.id === preferencesData?.paymentPreferences?.storedPaymentMethodId
  );

  return {
    availablePaymentMethods,
    defaultPaymentMethod,
    loading: paymentMethodsLoading || preferencesLoading,
  };
};
