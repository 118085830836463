import {
  AllowedEvent as AllowedEventMParticle,
  EventTypes,
  IEventBase,
  Restaurant,
} from '@rbilabs/mparticle-client';
import { REGIONS } from 'state/intl/types';

import { CustomEventNames } from './constants';

interface IUpdateUserAttributes extends IEventBase {
  name: CustomEventNames.UPDATE_USER_ATTRIBUTES;
  type: EventTypes.UserContent;
}

interface ISaveNewPaymentMethod extends IEventBase {
  name: CustomEventNames.SAVE_NEW_PAYMENT_METHOD;
  type: EventTypes.UserContent;
}

interface ISupportFormSubmitted extends IEventBase {
  name: CustomEventNames.SUPPORT_FORM_SUBMITTED;
  type: EventTypes.UserContent;
  attributes: {
    caseId: string;
    userEmail: string;
    orderDateTime?: string | undefined;
    userAccountRegion?: string;
    userRegion: REGIONS;
  };
}

interface IPypestreamSupportOpen extends IEventBase {
  name: CustomEventNames.PYPESTREAM_SUPPORT_OPEN;
  type: EventTypes.UserContent;
}

interface IStoreLocatorTabView extends IEventBase {
  name: CustomEventNames.STORE_LOCATOR_TAB_VIEW;
  type: EventTypes.Other;
}

export interface IStoreSelectedEventWithCache extends IEventBase {
  name: 'Store Selected';
  type: EventTypes.Other;
  attributes: Restaurant & { isCacheEnabled: boolean };
}

export interface IOffersListed extends IEventBase {
  name: CustomEventNames.OFFERS_LISTED;
  type: EventTypes.UserContent;
  attributes: {
    offers: { id: string; name: string }[];
  };
}

export interface IHomepageReorder extends IEventBase {
  name: CustomEventNames.HOMEPAGE_REORDER;
  type: EventTypes.Other;
}

export interface IDeliveryFeesSectionDisplayed extends IEventBase {
  name: CustomEventNames.DELIVERY_FEES_SECTION_DISPLAYED;
  type: EventTypes.Other;
}

export interface IDiscountNotApplied extends IEventBase {
  name: CustomEventNames.DISCOUNT_NOT_APPLIED;
  type: EventTypes.Other;
  attributes: {
    offer: { id: string; loyaltyEngineId: string; name: string };
  };
}

export type AllowedEvent =
  | AllowedEventMParticle
  | IUpdateUserAttributes
  | ISaveNewPaymentMethod
  | ISupportFormSubmitted
  | IPypestreamSupportOpen
  | IStoreSelectedEventWithCache
  | IStoreLocatorTabView
  | IOffersListed
  | IHomepageReorder
  | IDeliveryFeesSectionDisplayed
  | IDiscountNotApplied;

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

/**
 * Used for attribution tracking
 */
export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
}

export interface ICRMEventsUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  isSmallScreen: boolean;
  currentBuild: string;
}

export interface ICRMEventsAttributes extends IUtmParamsAttributes {
  brand?: string;
  region?: string;
  env?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  ccToken?: string;
  favoriteStores?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'RBI Cognito ID'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}
