import {
  IAddPaymentMethod,
  IApplePayPaymentMethod,
  ICashPaymentMethod,
  IGooglePayPaymentMethod,
  PaymentMethodOptionTypes,
} from 'components/payments/integrations/hooks/types';
import { CartPaymentCardType, PaymentMethodType } from 'generated/graphql-gateway';

import {
  AddCreditCardOption,
  AddGiftCardOption,
  AddPaymentMethodOption,
  CashOption,
  CreditOption,
  GiftCardOption,
} from './payment-method-option-types';

export const ARIA_LABELED_BY_DEFAULT =
  'payment-method-loads-with payment-method-label payment-method-card payment-method-alias payment-method-expirationDate';

export const addCreditCardDetails: IAddPaymentMethod = {
  id: PaymentMethodOptionTypes.ADD_CREDIT_CARD,
  type: PaymentMethodOptionTypes.ADD_CREDIT_CARD,
};

export const addGiftCardDetails: IAddPaymentMethod = {
  id: PaymentMethodOptionTypes.ADD_GIFT_CARD,
  type: PaymentMethodOptionTypes.ADD_GIFT_CARD,
};

// This generic option is only used when a user has no stored payment methods for a default option to be selected.
// They will see a generic option to add a payment method and will then be able to add a credit card or gift card (if enabled).
export const addPaymentMethodDetails: IAddPaymentMethod = {
  id: PaymentMethodOptionTypes.ADD_PAYMENT_METHOD,
  type: PaymentMethodOptionTypes.ADD_PAYMENT_METHOD,
};

export const applePayCardDetails: IApplePayPaymentMethod = {
  id: PaymentMethodType.APPLE_PAY,
  type: PaymentMethodType.APPLE_PAY,
};

export const googlePayCardDetails: IGooglePayPaymentMethod = {
  id: PaymentMethodType.GOOGLE_PAY,
  type: PaymentMethodType.GOOGLE_PAY,
};

export const cashDetails: ICashPaymentMethod = {
  id: CartPaymentCardType.CASH,
  type: CartPaymentCardType.CASH,
};

export const paymentMethodOptionMap = {
  [PaymentMethodOptionTypes.ADD_CREDIT_CARD]: AddCreditCardOption,
  [PaymentMethodOptionTypes.ADD_GIFT_CARD]: AddGiftCardOption,
  [PaymentMethodOptionTypes.ADD_PAYMENT_METHOD]: AddPaymentMethodOption,
  [PaymentMethodType.APPLE_PAY]: CreditOption,
  [CartPaymentCardType.CASH]: CashOption,
  [PaymentMethodType.GOOGLE_PAY]: CreditOption,
  [PaymentMethodType.PREPAID]: GiftCardOption,
  [PaymentMethodType.SCHEME]: CreditOption,
};
