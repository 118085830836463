import { PaymentIntegrationType } from 'components/payments/entry-points/types';
import { FirstDataAddGiftCard } from 'components/payments/integrations/firstdata/firstdata-gift-card';

type AddGiftCardIntegrationMapping = Partial<
  Record<(typeof PaymentIntegrationType)[keyof typeof PaymentIntegrationType], () => JSX.Element>
>;

export const addGiftCardIntegrationMapping: AddGiftCardIntegrationMapping = {
  [PaymentIntegrationType.FIRSTDATA]: FirstDataAddGiftCard,
};
