import { decode, encode } from 'base-64';

interface ITryParseStringOptions<V, D extends V> {
  value: string;
  defaultValue?: D;
  onError?(error: Error): void;
}

const tryParsing =
  <V>(parseFn: (value: string) => V) =>
  <D extends V>(options: ITryParseStringOptions<V, D>) => {
    const { value, defaultValue = null, onError } = options;

    try {
      return parseFn(value);
    } catch (err) {
      // catch function is merely used for outputting a custom message
      if (onError) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        onError(err);
      }

      return defaultValue;
    }
  };

export const decodeBase64String = tryParsing(decode);
export const encodeBase64String = tryParsing(encode);
export const parseStringifiedJSON = tryParsing(JSON.parse);
