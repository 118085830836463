import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { Button, Text, tokens } from '@fhs/ui';

import { Offer } from '../../types';

export function OfferListTile(item: Offer) {
  return (
    <View style={styles.offerItemContainer}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: item.imageUrl }} contentFit="cover" style={styles.image} />
      </View>
      <View style={styles.textContainer}>
        <Text.Ui size="md" weight="bold">
          {item.name}
        </Text.Ui>
        <Text.Ui size="sm" style={styles.expiration}>
          Expires {item.expiration}
        </Text.Ui>
      </View>
      <View style={styles.buttonContainer}>
        <Button type="outline" size="xs" style={styles.button}>
          <Button.Text>Apply Offer</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  offerItemContainer: {
    width: 165,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    borderRadius: 8,
    height: 231,
    alignItems: 'center',
  },
  expiration: {
    marginTop: 8,
  },
  textContainer: {
    flex: 1,
    padding: 12,
    width: '100%',
  },
  button: {
    padding: 12,
  },
  image: {
    flex: 1,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  imageContainer: {
    height: 96,
    borderRadius: 0,
    backgroundColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$black10,
    width: '100%',
  },
  buttonContainer: {
    padding: 12,
    width: '100%',
  },
});
