import React from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@fhs-legacy/universal-components';
import { IActionButtonProps } from 'components/action-button';
import { useUIContext } from 'state/ui';
import { CART_PAGE_CONTINUE_BUTTON, CART_PLACE_ORDER_BUTTON } from 'utils/test-ids';

import { StyledButton } from './styled';
import { getPlaceOrderButtonText } from './utils';

interface IPlaceOrderButtonProps extends IActionButtonProps {
  isLoading?: boolean;
  confirmation: boolean;
  reloadAmount?: number;
  placeOrderAriaLabel?: string;
}

export const PlaceOrderButton: React.FC<IPlaceOrderButtonProps> = ({
  placeOrderAriaLabel,
  reloadAmount,
  disabled,
  onPress,
  isLoading,
  confirmation,
}) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();

  const showIcon = !reloadAmount && confirmation;
  const text = formatMessage(
    {
      id: getPlaceOrderButtonText({
        reloadAndPlaceOrder: reloadAmount,
        confirmation,
        secureOrderText: true,
      }),
    },
    {
      amount: formatCurrencyForLocale(reloadAmount || 0),
      secureOrderIcon: '',
    }
  );

  return (
    <StyledButton
      fullWidth
      onPress={onPress}
      accessibilityLabel={confirmation ? placeOrderAriaLabel : undefined}
      accessibilityRole="button"
      testID={confirmation ? CART_PLACE_ORDER_BUTTON : CART_PAGE_CONTINUE_BUTTON}
      disabled={disabled}
      isLoading={isLoading}
      padding="$3"
      startIcon={showIcon ? <Icon variant="locked" /> : undefined}
    >
      {text}
    </StyledButton>
  );
};
