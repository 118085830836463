import { ComponentType } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconCatering, IconDelivery, IconLocationFill, IconProps, IconStore } from '../icon';
import { Pressable, PressableProps } from '../pressable';
import { XStack, YStack } from '../stack';
import { Text } from '../text/text';

import { HeaderText } from './header-text';

type IconType = 'catering' | 'delivery' | 'pickup' | 'location';
const IconByType: Record<IconType, ComponentType<IconProps>> = {
  catering: IconCatering,
  delivery: IconDelivery,
  pickup: IconStore,
  location: IconLocationFill,
};

export type HeaderServiceModeLocationProps = Omit<PressableProps, 'children'> & {
  storeText?: string;
  iconType?: IconType;
  serviceModeSubtitle?: React.ReactElement;
};

export function HeaderServiceModeLocation({
  storeText = 'Choose location',
  iconType = 'location',
  serviceModeSubtitle,
  ...pressableProps
}: HeaderServiceModeLocationProps) {
  const Icon = IconByType[iconType];
  return (
    <Pressable {...pressableProps} style={[styles.pressable, pressableProps.style]}>
      <View style={styles.iconView}>
        <Icon size={20} />
      </View>
      <YStack>
        {storeText ? (
          <XStack>
            <HeaderText numberOfLines={1} ellipsizeMode="tail" style={styles.headerText}>
              {storeText}
            </HeaderText>
          </XStack>
        ) : (
          <HeaderText>Locations</HeaderText>
        )}
        {serviceModeSubtitle}
      </YStack>
      <View style={styles.changeTextView}>
        <Text style={styles.changeText}>(change)</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    gap: 8,
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
  },
  iconView: {
    flexShrink: 0,
  },
  changeTextView: {
    marginLeft: 4,
    flexShrink: 0,
  },
  headerText: {
    maxWidth: 150,
  },
  changeText: {
    textDecorationLine: 'underline',
    fontSize: 12,
    lineHeight: 20,
  },
});
