import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { AddCreditCardFormInputs, PaymentTotals } from 'components/payments/components';
import { useAddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/hooks/use-add-credit-card-form-inputs';
import { PaymentMethodDropdown } from 'components/payments/components/payment-method-dropdown/payment-method-dropdown';
import { addPaymentMethodDetails } from 'components/payments/components/payment-method-option/constants';
import {
  PaymentMethod,
  PaymentMethodOptionTypes,
} from 'components/payments/integrations/hooks/types';
import { usePaymentMethodsAdapter } from 'components/payments/integrations/hooks/use-payment-methods-adapter';
import { isNewCreditCard } from 'components/payments/integrations/hooks/utils';
import useWorldpayApplePay from 'components/payments/integrations/worldpay/hooks/use-worldpay-apple-pay';
import { PaymentProcessor } from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import {
  IOrderInformation,
  IPaymentCollectorProps,
  IPaymentCollectorRef,
  IPaymentMethodDetails,
  IPlaceOrderWithNewCreditCard,
  IPlaceOrderWithVaultedCard,
  PlaceOrderResult,
} from 'pages/cart/payments/types';
import { useCartContext } from 'state/cart';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import logger from 'utils/logger';
import { isDelivery } from 'utils/service-mode';

import EProtectEncryption from './components/encryption/eprotect-encryption';
import { IEncryptionError, IEncryptionResult } from './components/encryption/types';
import { useAddPaymentMethod } from './components/worldpay-add-credit-card-modal/hooks/use-add-payment-method/use-worldpay-add-payment-method';
import { buildMutationInput } from './components/worldpay-add-credit-card-modal/utils/build-mutation-input';
import useWorldpayGooglePay from './hooks/use-worldpay-google-pay';
import { useWorldpayOneTimePayment } from './hooks/use-worldpay-one-time-payment';
import { useWorldpayVaultedPayment } from './hooks/use-worldpay-vaulted-payment';

export const WorldpayPaymentCollector = forwardRef<IPaymentCollectorRef>(
  ({ hidePaymentMethod, isProcessingPlaceOrder = false }: IPaymentCollectorProps, ref) => {
    const paymentFieldVariations =
      useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
      defaultPaymentFieldVariation;
    const migrationEnabled = useFlag(LaunchDarklyFlag.ENABLE_DIGITAL_WALLET_MIGRATION);
    const oneTimePaymentFlowEnabled = useFlag(
      LaunchDarklyFlag.ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT
    );

    const { serverOrder, serviceMode } = useCartContext();
    const { feCountryCode: billingCountry } = useLocale();

    const { requestGooglePayPayment } = useWorldpayGooglePay(serverOrder?.rbiOrderId || '');

    const { requestVaultedPayment } = useWorldpayVaultedPayment(serverOrder?.rbiOrderId || '');

    const [encryptionResult, setEncryptionResult] = useState<IEncryptionResult | undefined>(
      undefined
    );
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
      useState<PaymentMethod>(addPaymentMethodDetails);

    const { addPaymentMethod } = useAddPaymentMethod();
    const { requestOneTimePayment } = useWorldpayOneTimePayment(serverOrder?.rbiOrderId || '');

    // Get the user's available payment methods
    const { availablePaymentMethods, defaultPaymentMethod, loading } = usePaymentMethodsAdapter();
    // Set the user's default payment method as the selected option.
    // If not, set the next available payment method option.
    // If not, set the generic option so a user can add a payment method.
    useEffectOnce(() => {
      let paymentMethod: PaymentMethod = addPaymentMethodDetails;
      if (defaultPaymentMethod) {
        paymentMethod = defaultPaymentMethod;
      } else if (availablePaymentMethods.length) {
        paymentMethod = availablePaymentMethods[0];
      }
      setSelectedPaymentMethod(paymentMethod);
    });

    const { requestApplePayPayment } = useWorldpayApplePay({
      prepaid: false,
    });

    const handlePaymentMethodSelection = (method: PaymentMethod) => {
      setSelectedPaymentMethod(method);
    };

    const isDeliveryOrder = isDelivery(serviceMode);
    const isUsingNewCreditCard = isNewCreditCard(selectedPaymentMethod);

    const showAddCreditCardForm =
      selectedPaymentMethod.id === PaymentMethodOptionTypes.ADD_CREDIT_CARD;

    const {
      creditCardFormErrors,
      creditCardFormValues,
      handleCreditCardFormChanges,
      validateCreditCardFormOnSubmit,
    } = useAddCreditCardFormInputs({
      billingCountry,
      paymentFieldVariations,
      paymentProcessor: PaymentProcessor.WORLDPAY,
    });

    // Handle encryption result and fire off add payment method request
    const onEncryptionResult = useCallback((encryptionData: IEncryptionResult) => {
      setEncryptionResult(encryptionData);
    }, []);

    // Handle encryption error
    const onEncryptionError = useCallback((_error: IEncryptionError) => {
      setEncryptionResult(undefined);
    }, []);

    /**
     * This is used with paymentCollectorRef in the checkout page (payment.tsx). It is a unique case in which we need to be able
     * to call a method within a child to send the current payment details for a user when, and only when, it is needed.
     */
    useImperativeHandle(
      ref,
      () => {
        return {
          // TODO: Payments Refactor - include further logic to pass payment details to parent when requested.
          getPaymentMethodDetails: (): IPaymentMethodDetails => ({
            paymentMethod: selectedPaymentMethod,
            ...(isUsingNewCreditCard && {
              creditCardFormValues,
              encryptionResult,
            }),
          }),
          placeOrderWithGooglePay: async (
            orderInformation: IOrderInformation
          ): PlaceOrderResult => {
            const { total, storeEmail, rbiOrderId } = orderInformation;
            return await requestGooglePayPayment({
              total,
              billingCountry,
              migrationEnabled,
              storeEmail,
              rbiOrderId,
              orderInformation,
            });
          },
          placeOrderWithApplePay: async (orderInformation: IOrderInformation) => {
            return requestApplePayPayment({
              billingCountry,
              orderInformation,
            });
          },
          placeOrderWithVaultedCard: async ({
            orderInformation,
            paymentMethod,
          }: IPlaceOrderWithVaultedCard): PlaceOrderResult => {
            return requestVaultedPayment({
              accountIdentifier: paymentMethod.id,
              cardBrand: paymentMethod.brand,
              orderInformation,
            });
          },
          placeOrderWithNewCreditCard: async ({
            creditCardFormValues,
            encryptionResult,
            orderInformation,
          }: IPlaceOrderWithNewCreditCard) => {
            const isFormValid = validateCreditCardFormOnSubmit();
            if (!isFormValid) {
              return undefined;
            }

            if (!encryptionResult || !('lowValueToken' in encryptionResult)) {
              logger.error({
                message: 'Worldpay card encryption failed',
              });
              return undefined;
            }

            if (creditCardFormValues.saveCard && !oneTimePaymentFlowEnabled) {
              const input = buildMutationInput(
                creditCardFormValues,
                encryptionResult,
                paymentFieldVariations,
                billingCountry
              );
              const addPaymentMethodResponse = await addPaymentMethod({
                input,
                options: {
                  skipErrorDialogOnError: true,
                },
              });
              return requestVaultedPayment({
                accountIdentifier: addPaymentMethodResponse.id,
                cardBrand: addPaymentMethodResponse.brand,
                orderInformation,
              });
            }
            return requestOneTimePayment({
              creditCardFormValues,
              encryptionData: encryptionResult as IEncryptionResult,
              orderInformation,
            });
          },
          placeOrderWithGiftCard: async () => {
            return undefined;
          },
        };
      },
      [
        selectedPaymentMethod,
        isUsingNewCreditCard,
        creditCardFormValues,
        encryptionResult,
        requestGooglePayPayment,
        billingCountry,
        migrationEnabled,
        requestApplePayPayment,
        requestVaultedPayment,
        validateCreditCardFormOnSubmit,
        oneTimePaymentFlowEnabled,
        requestOneTimePayment,
        addPaymentMethod,
        paymentFieldVariations,
      ]
    );

    /**
     * If you need to only access the payment operations you will be able
     * to hide the payment methods selector.
     */
    if (hidePaymentMethod) {
      return null;
    }

    return (
      <>
        <PaymentMethodDropdown
          aria-expanded="true"
          handleSelection={handlePaymentMethodSelection}
          loading={loading}
          paymentMethods={availablePaymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        {showAddCreditCardForm && (
          <>
            <AddCreditCardFormInputs
              errors={creditCardFormErrors}
              values={creditCardFormValues}
              isDelivery={isDeliveryOrder}
              onChange={handleCreditCardFormChanges}
              showSaveCard
            />
            <EProtectEncryption
              cardNumber={creditCardFormValues.cardNumber}
              cvv={creditCardFormValues.cvv || ''}
              onResult={onEncryptionResult}
              onError={onEncryptionError}
            />
          </>
        )}
        <PaymentTotals tipSelectorDisabled={isProcessingPlaceOrder} />
      </>
    );
  }
);
