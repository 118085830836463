import { Box, Header, Text } from '@fhs-legacy/universal-components';

import theme from './theme';

// ConfirmPickup
export const StoreCardContainer = Box.withConfig({
  justifyContent: 'center',
  flex: 1,
  marginTop: { base: '0', desktop: '$4' },
  marginX: { desktop: 'auto' },
  maxWidth: { desktop: Styles.layout.smallerSectionMaxWidth },
});

export const StoreCardContainerInner = Box.withConfig({
  flex: 1,
  justifyContent: 'center',
  marginBottom: { base: '$24', desktop: '$4' },
  paddingTop: '$10',
  paddingBottom: '$2',
});

export const verticalCenteringStyles = {
  justifyContent: 'space-around',

  marginTop: {
    base: 0,
  },

  marginBottom: {
    base: 0,
  },
};

export const MapContainer = Box.withConfig({
  flexGrow: 2,
  flexShrink: 1,
  flexBasis: 0,
  width: '100%',
  ...verticalCenteringStyles,
});

// PickupCard

export const Pickup = Text.withConfig({
  color: Styles.color.accent,
});

export const PickupInstructions = Header.withConfig({
  textAlign: 'center',
  color: theme.pickupInstructionsColor,
  fontSize: 'md',
});

// Other

export const LogoContainer = Box.withConfig({
  alignSelf: 'center',
  width: '$32',
  marginTop: {
    base: '$4',
  },
});

export const Heading = Header.withConfig({
  variant: 'headerFour',
  color: Styles.color.black,
  textAlign: 'center',
});

// Card button

export const ButtonContainer = Box.withConfig({
  justifyContent: 'space-between',
  margin: '$4',
});

export const ButtonText = Text.withConfig({
  color: Styles.color.white,
  textAlign: 'center',
  fontFamily: 'heading',
});

export const ButtonSubText = Text.withConfig({
  fontSize: 'xs',
  color: Styles.color.white,
  textAlign: 'center',
  fontFamily: 'heading',
});
