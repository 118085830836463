import { Image, ImageBackground } from 'expo-image';
import { StyleSheet } from 'react-native';

import { ScrollView, Text, YStack, tokens } from '@fhs/ui';

import type { MenuItem } from '../types';

type MenuSectionProps = {
  item: MenuItem;
};

export function MenuItemDetails(props: MenuSectionProps) {
  const { item } = props;
  return (
    <ScrollView style={styles.container}>
      <YStack style={styles.containerPadding}>
        <YStack style={styles.containerTitle}>
          <Text.Heading type="one" style={styles.textTitle}>
            {item.displayName}
          </Text.Heading>
          <Text.Heading type="four" style={styles.textSubTitle}>
            Medium Size • 720 Cal
          </Text.Heading>
        </YStack>
        <ImageBackground
          contentFit="contain"
          source={require('../assets/img/menu_item_detail_bg_2477_1058.webp')}
          style={styles.containerImageBackground}
        >
          <Image source={{ uri: item.imageUrl }} contentFit="contain" style={styles.image} />
        </ImageBackground>
        <Text.Paragraph size="md" style={styles.textDescription}>
          {item.description}
        </Text.Paragraph>
      </YStack>
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Size</Text.Heading>
      </YStack>
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Choices</Text.Heading>
      </YStack>
      <YStack style={[styles.containerSection, styles.containerPadding]}>
        <Text.Heading type="four">Make it a Combo</Text.Heading>
        <Text.Paragraph size="sm">Add any size drink + chips or cookie</Text.Paragraph>
      </YStack>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // TOOD(simplified-menu): Use media query
    backgroundColor: tokens.colors.$white,
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens, or add Media Query
    maxWidth: 620,
  },
  containerPadding: {
    paddingHorizontal: 16,
  },
  containerTitle: {
    marginVertical: 8,
  },
  textTitle: {
    textAlign: 'center',
  },
  textSubTitle: {
    textAlign: 'center',
  },
  textSectionTitle: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 20,
    paddingVertical: 8,
  },
  containerImageBackground: {
    flex: 1,
    minHeight: 200,
    paddingVertical: 18,
  },
  image: {
    height: '100%',
    paddingVertical: 18,
  },
  textDescription: {
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  containerSection: {
    marginVertical: 16,
  },
});
