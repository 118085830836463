import { router } from 'expo-router';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { useFirstDataGiftCardNumber } from 'components/payments/integrations/firstdata/components/gift-card-modal/hooks/use-first-data-gift-card-number';
import { StoredPaymentMethodsDocument, useMergePrepaidMutation } from 'generated/graphql-gateway';
import useErrorModal from 'hooks/use-error-modal';
import { usePathname } from 'hooks/use-pathname';
import { useLocale } from 'state/intl';
import { useUIContext } from 'state/ui';
import logger from 'utils/logger';
import { relativeRoutes } from 'utils/routing';

export const useFirstDataAddGiftCard = () => {
  const { feCountryCode } = useLocale();
  const { formatMessage } = useIntl();
  const {
    giftCardNumber,
    errors: giftCardNumberErrors,
    isValidGiftCardNumber,
    handleCardNumberChange,
  } = useFirstDataGiftCardNumber();
  const pathname = usePathname();
  const { formatCurrencyForUser } = useUIContext();

  const [mergePrepaidMutation, { loading: mergePrepaidLoading }] = useMergePrepaidMutation({
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  const handleCloseModal = () => {
    router.back();
  };

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
    onCancel: handleCloseModal,
  });

  const addGiftCard = useCallback(
    async (event: GestureResponderEvent) => {
      event.preventDefault();
      event.stopPropagation();

      try {
        const { data: mergePrepaidData, errors: mergePrepaidErrors } = await mergePrepaidMutation({
          variables: { input: { sourceCardNumber: giftCardNumber } },
        });

        if (!mergePrepaidErrors?.length) {
          const currentBalance = mergePrepaidData?.mergePrepaid.prepaidDetails.balance;

          const successRoute = pathname.replace(
            relativeRoutes.addGiftCard,
            relativeRoutes.successGiftCard
          );
          const formatCurrency = formatCurrencyForUser(currentBalance);

          // Remove the source screen from stack and then navigate to success modal
          router.back();
          router.navigate({ pathname: successRoute, params: { currentBalance: formatCurrency } });
        }

        if (mergePrepaidErrors?.length) {
          logger.error({ errors: mergePrepaidErrors, message: 'Error adding gift card' });

          mergePrepaidErrors.forEach(error => {
            openErrorDialog({
              message: formatMessage({ id: 'giftCardAddingError' }),
              error,
            });
          });
        }
      } catch (error) {
        openErrorDialog({
          message: formatMessage({ id: 'giftCardAddingError' }),
          error: error as Error,
        });
        logger.error({ error, message: 'Error adding gift card' });
      }
    },
    [
      // @ts-ignore
      openErrorDialog,
      formatMessage,
      giftCardNumber,
      pathname,
      formatCurrencyForUser,

      mergePrepaidMutation,
    ]
  );

  return {
    addGiftCard,
    ErrorDialog,
    handleCloseModal,
    mergePrepaidLoading,
    isValidGiftCardNumber,
    handleCardNumberChange,
    giftCardNumberErrors,
  };
};
