import { type StyleProp, StyleSheet, Text, type TextStyle } from 'react-native';

import { tokens } from '../../tokens';
import { usePressableState } from '../pressable/pressable';

import { useButtonContext } from './button';

export type ButtonTextProps = {
  children: string;
  style?: StyleProp<TextStyle>;
};
export function ButtonText(props: ButtonTextProps) {
  const buttonContext = useButtonContext();
  const pressableState = usePressableState();

  return (
    <Text
      style={[
        buttonTextStyles.baseline,
        buttonTextStyles[buttonContext.size],
        buttonTextStyles[buttonContext.type],
        buttonContext.type === 'ghost' && pressableState.hovered && buttonTextStyles.ghostHovered,
        buttonContext.disabled && buttonTextStyles.disabled,
        props.style,
      ]}
    >
      {props.children}
    </Text>
  );
}

const buttonTextStyles = StyleSheet.create({
  baseline: {
    fontFamily: 'MontserratSemiBold',
    paddingHorizontal: 8,
    userSelect: 'none',
  },
  xs: {
    fontSize: 12,
  },
  sm: {
    fontSize: 12,
  },
  md: {
    fontSize: 14,
  },
  lg: {
    fontSize: 16,
  },
  xl: {
    fontSize: 18,
  },
  solid: {
    color: tokens.colors.$white,
  },
  outline: {
    color: tokens.colors.$houseRedDarken,
  },
  ghost: {
    color: tokens.colors.$houseDark,
  },
  ghostHovered: {
    color: tokens.colors.$houseRedDarken,
  },
  disabled: {
    color: tokens.colors.$disabledText,
  },
});
