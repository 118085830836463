import React from 'react';

import { BlankScreen } from 'components/blank-screen';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useUserPaymentInformationQuery } from 'generated/graphql-gateway';

import { paymentMethodIntegrationMapping } from './types';

export const PaymentMethodEntryPoint: React.FC = () => {
  const { data, loading } = useUserPaymentInformationQuery();

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  const paymentIntegration = data?.paymentConfig.frontendIntegration;
  if (!paymentIntegration) {
    return <BlankScreen context={{ reason: 'paymentIntegration is null' }} />;
  }

  // Determine which payment integration to display for handling a user's payment methods.
  const PaymentMethodCollectorIntegration = paymentMethodIntegrationMapping[paymentIntegration];

  return <PaymentMethodCollectorIntegration />;
};
