import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function MenuStackLayout() {
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ cannotGoBackHref: '/v2/menu' }),
        title: 'Offers & Rewards',
      }}
    />
  );
}

// Disabled because this is causing module import errors on production build
// Presumably because it duplicate `fresh/routing/src/routes/menu/_layout.tsx`
// export const unstable_settings = {
// };
