import { IAddCreditCardFormErrors } from 'components/payments/components/add-credit-card-form-inputs/types';
import { CardBrand } from 'generated/graphql-gateway';

export type CardType = CardBrand | string;

export const initialCreditCardFormErrors = (): IAddCreditCardFormErrors => ({
  nameOnCard: '',
  cardNumber: '',
  cardBrand: '',
  cvv: '',
  expiry: '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: '',
  billingApt: '',
});
