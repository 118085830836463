import { createContext, forwardRef, useContext, useMemo, useState } from 'react';
import { Platform, Pressable as RnPressable, StyleSheet, View } from 'react-native';
import type { PressableProps as RnPressableProps, StyleProp, ViewStyle } from 'react-native';

import { useMergedEventHandlers } from '@fhs/utils';

import { tokens } from '../../tokens';

import { DEFAULT_PRESSABLE_BORDER_RADIUS, PRESSABLE_BORDER_WIDTH } from './constants';
import { PressableOutline } from './pressable-outline';

export type PressableState = {
  pressed: boolean;
  hovered: boolean;
  focused: boolean;
  disabled: boolean;
};

const PressableContext = createContext<PressableState>({
  pressed: false,
  hovered: false,
  focused: false,
  disabled: false,
});

export function usePressableState() {
  return useContext(PressableContext);
}

export type PressableProps = Omit<RnPressableProps, 'style'> & {
  style?: StyleProp<ViewStyle>;
  pressedStyle?: StyleProp<ViewStyle>;
  hoveredStyle?: StyleProp<ViewStyle>;
  focusedStyle?: StyleProp<ViewStyle>;
  disabledStyle?: StyleProp<ViewStyle>;

  outlineStyle?: StyleProp<ViewStyle>;
  outlinePressedStyle?: StyleProp<ViewStyle>;
  outlineHoveredStyle?: StyleProp<ViewStyle>;
  outlineFocusedStyle?: StyleProp<ViewStyle>;
  outlineDisabledStyle?: StyleProp<ViewStyle>;

  borderRadius?: number;
};

export const Pressable = forwardRef<View, PressableProps>(function Pressable(
  { children, borderRadius = DEFAULT_PRESSABLE_BORDER_RADIUS, ...props }: PressableProps,
  forwardedRef
) {
  const [pressed, setPressed] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const disabled = props.disabled ?? false;

  const pressableState: PressableState = useMemo(
    () => ({ pressed, hovered, focused, disabled }),
    [pressed, hovered, focused, disabled]
  );

  const handleOnPressIn = useMergedEventHandlers(props.onPressIn, () => setPressed(true));
  const handleOnPressOut = useMergedEventHandlers(props.onPressOut, () => setPressed(false));

  const handleOnHoverIn = useMergedEventHandlers(props.onHoverIn, () => setHovered(true));
  const handleOnHoverOut = useMergedEventHandlers(props.onHoverOut, () => setHovered(false));

  const handleOnFocus = useMergedEventHandlers(props.onFocus, () => setFocused(true));
  const handleOnBlur = useMergedEventHandlers(props.onBlur, () => setFocused(false));

  return (
    <PressableContext.Provider value={pressableState}>
      <RnPressable
        ref={forwardedRef}
        {...props}
        disabled={disabled}
        onPressIn={handleOnPressIn}
        onPressOut={handleOnPressOut}
        onHoverIn={handleOnHoverIn}
        onHoverOut={handleOnHoverOut}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        style={[
          styles.baseline,
          { borderRadius },
          props.style,
          pressed && props.pressedStyle,
          focused && props.focusedStyle,
          hovered && props.hoveredStyle,
          disabled && [styles.disabled, props.disabledStyle],
        ]}
      >
        <>
          {children}
          <PressableOutline
            borderRadius={borderRadius}
            style={[
              props.outlineStyle,
              pressed && [outlineStyles.pressed, props.outlinePressedStyle],
              focused && [outlineStyles.focused, props.outlineFocusedStyle],
              hovered && [outlineStyles.hovered, props.outlineHoveredStyle],
              disabled && [outlineStyles.disabled, props.outlineDisabledStyle],
            ]}
          />
        </>
      </RnPressable>
    </PressableContext.Provider>
  );
});

const styles = StyleSheet.create({
  baseline: {
    ...Platform.select({ web: { cursor: 'pointer' } }),
    borderWidth: PRESSABLE_BORDER_WIDTH,
    borderColor: 'transparent',
    // relative position required for Outline
    position: 'relative',
    ...Platform.select({
      // remove outline on web
      web: { outlineWidth: 0 },
    }),
  },

  disabled: {
    ...Platform.select({ web: { cursor: 'not-allowed' } }),
    pointerEvents: 'none',
  },
});

const outlineStyles = StyleSheet.create({
  pressed: {
    borderColor: tokens.colors.$blackOpacity10,
  },
  hovered: {},
  focused: {
    borderColor: tokens.colors.$blackOpacity10,
  },
  disabled: {
    borderColor: tokens.colors.$transparent,
  },
});
