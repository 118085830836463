import { router } from 'expo-router';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ModalContent, ModalHeading } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import { AddCreditCardFormInputs } from 'components/payments/components';
import { useAddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/hooks/use-add-credit-card-form-inputs';
import {
  getCreditCardErrorMessage,
  getCreditCardFormErrors,
  initialCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/utils';
import { PaymentError } from 'components/payments/integrations/hooks/types';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useErrorModal from 'hooks/use-error-modal';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import logger from 'utils/logger';

import { useAddPaymentMethod } from './hooks/use-add-payment-method/use-add-payment-method';

export const FirstDataAddCreditCardModal: React.FC<React.PropsWithChildren> = () => {
  const { formatMessage } = useIntl();
  const { feCountryCode: billingCountry } = useLocale();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const {
    creditCardFormValues,
    creditCardFormErrors,
    handleCreditCardFormChanges,
    setCreditCardFormErrors,
    setCreditCardFormValues,
  } = useAddCreditCardFormInputs({ paymentFieldVariations, billingCountry });

  const { addPaymentMethod } = useAddPaymentMethod();

  // @ts-ignore - Union type error
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Credit Card details',
  });

  const [isLoading, setIsLoading] = useState(false);

  const addCreditCard = useCallback(
    async (event: any) => {
      if (event) {
        event.preventDefault();
      }
      const { hasErrors, formErrors } = getCreditCardFormErrors(
        creditCardFormValues,
        creditCardFormErrors,
        formatMessage,
        paymentFieldVariations,
        billingCountry
      );

      if (hasErrors) {
        const newErrorMessage = getCreditCardErrorMessage({
          errors: creditCardFormErrors,
          formatMessage,
        });
        setErrorMessage(newErrorMessage);

        return setCreditCardFormErrors(prevState => ({ ...prevState, ...formErrors }));
      }

      setIsLoading(true);

      try {
        await addPaymentMethod({
          paymentMethodValues: creditCardFormValues,
          addPaymentMethodOptions: {
            skipErrorDialogOnError: true,
          },
        });

        // Handle addPaymentMethod success
        setCreditCardFormValues(initialCreditCardFormValues());
        setIsLoading(false);
        router.back();
      } catch (error) {
        setIsLoading(false);
        logger.error({ error, message: 'Error adding payment method' });

        if (error instanceof PaymentError) {
          // @ts-ignore - Union type error
          return openErrorDialog({
            message: error.message,
            modalAppearanceEventMessage: formatMessage({ id: error.failureMessageKey }),
            error,
          });
        }

        openErrorDialog({
          message: formatMessage({ id: 'paymentAddingError' }),
          // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown'
          error,
        });

        setIsLoading(false);
      }
    },
    [
      addPaymentMethod,
      billingCountry,
      creditCardFormValues,
      creditCardFormErrors,
      formatMessage,
      openErrorDialog,
      paymentFieldVariations,
      setCreditCardFormErrors,
      setCreditCardFormValues,
    ]
  );

  const modalHeading = formatMessage({ id: 'addPaymentMethod' });
  const modalButtonText = `${formatMessage({ id: 'save' })} & ${formatMessage({ id: 'continue' })}`;

  return (
    <Modal
      onDismiss={router.back}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Add payment method',
      }}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onDismiss: () => void; ... Remove this comment to see the full error message
      allowModalResizeWithKeyboard
      header={
        <Box marginTop="$1">
          <ModalHeading marginBottom="$0" testID="add-payment-modal-heading">
            <Header marginBottom="$0" marginX="auto" variant="headerOne">
              {modalHeading}
            </Header>
          </ModalHeading>
        </Box>
      }
    >
      <KeyboardAwareView withScroll>
        <ModalContent backgroundColor={Styles.color.background} py="$0">
          <Box>
            {!!errorMessage && (
              <VisuallyHidden role="alert" accessibilityLabel="">
                <Text>{errorMessage}</Text>
              </VisuallyHidden>
            )}
            <AddCreditCardFormInputs
              onChange={handleCreditCardFormChanges}
              values={creditCardFormValues}
              errors={creditCardFormErrors}
              $withPadding
            />
            <Box marginY="$4" marginX="$0">
              <ActionButton
                fullWidth
                testID="save-and-continue"
                isLoading={isLoading}
                disabled={!creditCardFormValues.saveCard}
                onPress={addCreditCard}
              >
                {modalButtonText}
              </ActionButton>
            </Box>
          </Box>
        </ModalContent>
      </KeyboardAwareView>
      <ErrorDialog />
    </Modal>
  );
};
