import { ApolloError, isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';

import {
  PaymentError,
  isAddPaymentMethodSuccess,
  isSchemeStoredPaymentMethodResponse,
} from 'components/payments/integrations/hooks/types';
import {
  AddPaymentMethodMutationResult,
  IAddPaymentMethod,
  IUseAddPaymentMethod,
} from 'components/payments/integrations/orbital/components/orbital-add-credit-card-modal/types';
import {
  StoredPaymentMethodsDocument,
  useAddPaymentMethodMutation,
} from 'generated/graphql-gateway';
import { PaymentProcessor } from 'generated/rbi-graphql';
import { HttpErrorCodes } from 'remote/constants';
import { useLocale } from 'state/intl';
import { parseGraphQLErrorCodes } from 'utils/errors';
import logger from 'utils/logger';

export const useOrbitalAddPaymentMethod = (): IUseAddPaymentMethod => {
  const { feCountryCode } = useLocale();

  const [addPaymentMethodMutation, { loading }] = useAddPaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  const logNewPaymentMethod = (success: boolean, context: Record<string, any> = {}) => {
    if (success) {
      logger.info({
        message: 'Payment method added',
        context,
      });
    } else {
      logger.error({
        message: 'Error adding payment method',
        context,
      });
    }
  };

  const addPaymentMethod = useCallback(
    async ({ input, options }: IAddPaymentMethod) => {
      try {
        const { data } = await addPaymentMethodMutation({
          variables: {
            input,
          },
        });

        if (!data) {
          throw new Error('Mutation response error');
        }

        const addPaymentMethodResult: AddPaymentMethodMutationResult = data?.addPaymentMethod;

        if (isAddPaymentMethodSuccess(addPaymentMethodResult)) {
          const storedPaymentMethod = addPaymentMethodResult.storedPaymentMethod;
          if (!isSchemeStoredPaymentMethodResponse(storedPaymentMethod)) {
            throw new Error('Unexpected card type');
          }

          logNewPaymentMethod(true, {
            accountIdentifider: storedPaymentMethod.id,
            paymentProcessor: PaymentProcessor.ORBITAL,
          });

          return storedPaymentMethod;
        }

        throw new Error('Unexpected mutation response');
      } catch (error) {
        const defaultErrorMessage = 'Error: Adding Payment Method Failure';
        const defaultPaymentAddingError = 'paymentAddingError';
        if (isApolloError(error as Error)) {
          logger.error({
            error,
            message: 'Error adding orbital payment method',
          });

          const errors = parseGraphQLErrorCodes(error as ApolloError);
          const message = errors[0]?.message || defaultErrorMessage;

          logNewPaymentMethod(false, {
            message,
            accountIdentifier: undefined,
          });

          if (options.skipErrorDialogOnError) {
            throw new Error('Add Account Failure');
          }

          const statusCode = (error as ApolloError).graphQLErrors?.find(
            (graphQLError: GraphQLError) => Number.isInteger(graphQLError?.extensions?.statusCode)
          )?.extensions?.statusCode as number | undefined;

          if (!statusCode) {
            throw new PaymentError(message, defaultPaymentAddingError);
          }

          const messageKey =
            statusCode === HttpErrorCodes.TooManyRequests
              ? 'tooManyAddAccountAttempts'
              : defaultPaymentAddingError;

          throw new PaymentError(message, messageKey);
        }

        throw new PaymentError(defaultErrorMessage, defaultPaymentAddingError);
      }
    },
    [addPaymentMethodMutation]
  );

  return {
    addPaymentMethod,
    loading,
  };
};
