import { Link, usePathname } from 'expo-router';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import {
  Button,
  Dropdown,
  Favicon,
  HeaderDivider,
  HeaderServiceModeLocation,
  HeaderServiceModeLocationProps,
  HeaderText,
  IconCartFill,
  IconCatering,
  IconChevronDown,
  IconDelivery,
  MqSwitch,
  RapidRescue,
  Text,
  Wordmark,
  XStack,
  YStack,
  tokens,
} from '@fhs/ui';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { ServiceMode, useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';
import { isExternalLink } from '@fhs-legacy/frontend/src/utils/is-external-link';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useHrefs } from '../../../use-hrefs';

function getIconTypeFromServiceMode(
  serviceMode?: ServiceMode
): HeaderServiceModeLocationProps['iconType'] {
  if (!serviceMode) {
    return 'location';
  }

  if (
    serviceMode === ServiceMode.CATERING_DELIVERY ||
    serviceMode === ServiceMode.CATERING_PICKUP
  ) {
    return 'catering';
  }

  if (serviceMode === ServiceMode.DELIVERY) {
    return 'delivery';
  }

  return 'pickup';
}

export function HeaderLeftDesktop() {
  const currentPathname = usePathname();
  const { store } = useStoreContext();
  const { deliveryAddress } = useOrderContext();
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const { isAuthenticated } = useAuthContext();
  const { pickUpHref, deliveryHref, cateringHref, foundationHref } = useHrefs();
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const isCateringExternal = isExternalLink(cateringHref);
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const locationText = isDelivery ? deliveryAddress?.addressLine1 : store.name;

  return (
    <>
      <XStack style={styles.wordmarkContainer}>
        <Link href="/">
          <MqSwitch
            $ltDesktopLg={() => <Favicon size={44} />}
            $gteDesktopLg={() => <Wordmark width={142} />}
          />
        </Link>
      </XStack>

      <XStack style={styles.container}>
        <XStack style={styles.links}>
          <Dropdown>
            <Dropdown.Trigger>
              {({ isExpanded }) => (
                <XStack style={styles.textWithCaret}>
                  <HeaderText isHighlighted={isExpanded}>Order</HeaderText>
                  <IconChevronDown
                    color={isExpanded ? tokens.colors.$houseRedDarken : undefined}
                    size={20}
                  />
                </XStack>
              )}
            </Dropdown.Trigger>
            <Dropdown.Pane style={styles.orderDropdownPane}>
              <YStack style={styles.orderDropdownGraphicAndButtonsStack}>
                <Dropdown.ClosePaneOnChange value={currentPathname} />
                <RapidRescue />
                <YStack style={styles.orderDropdownButtonStack}>
                  <Link href={pickUpHref} asChild>
                    <Button type="solid" size="lg">
                      <Button.Icon>
                        <IconCartFill />
                      </Button.Icon>
                      <Button.Text>Pick Up Order</Button.Text>
                    </Button>
                  </Link>

                  {Boolean(deliveryHref) && (
                    <Link href={deliveryHref} asChild>
                      <Button type="outline" size="lg">
                        <Button.Icon>
                          <IconDelivery />
                        </Button.Icon>
                        <Button.Text>Delivery Order</Button.Text>
                      </Button>
                    </Link>
                  )}

                  {Boolean(cateringHref) && (
                    <Link
                      href={cateringHref}
                      asChild
                      {...(isCateringExternal ? { target: '_blank' } : {})}
                    >
                      <Button type="outline" size="lg">
                        <Button.Icon>
                          <IconCatering />
                        </Button.Icon>
                        <Button.Text>Catering Order</Button.Text>
                      </Button>
                    </Link>
                  )}
                </YStack>
              </YStack>
            </Dropdown.Pane>
          </Dropdown>

          {isAuthenticated && (
            <Link href="/rewards/details">
              <HeaderText isHighlighted={currentPathname === '/rewards/details'}>
                Rewards
              </HeaderText>
            </Link>
          )}

          {Boolean(cateringHref) && (
            <Link href={cateringHref} {...(isCateringExternal ? { target: '_blank' } : {})}>
              <HeaderText>Catering</HeaderText>
            </Link>
          )}

          <Link href={foundationHref} target="_blank">
            <HeaderText>Foundation</HeaderText>
          </Link>
        </XStack>

        <HeaderDivider />

        <YStack>
          <Link href={isDelivery ? routes.address : routes.storeLocator} asChild>
            <HeaderServiceModeLocation
              storeText={locationText}
              iconType={getIconTypeFromServiceMode(serviceMode)}
              serviceModeSubtitle={
                enableDeliveryFeeOnServiceModeBar && isDelivery ? (
                  <Text style={styles.deliveryFeeText}>
                    {formatMessage(
                      { id: 'deliveryFeeWithFee' },
                      { deliveryFee: currencyFormatter(deliveryFees.deliveryTotalFee) }
                    )}
                  </Text>
                ) : null
              }
            />
          </Link>
        </YStack>
      </XStack>
    </>
  );
}

const styles = StyleSheet.create({
  wordmarkContainer: {
    alignItems: 'center',
  },
  container: {
    flexShrink: 1,
    gap: 24,
    alignItems: 'center',
  },
  links: {
    gap: 24,
    alignItems: 'center',
  },
  textWithCaret: {
    height: '100%',
    gap: 4,
    alignItems: 'center',
  },
  orderDropdownPane: {
    minWidth: 276,
    paddingTop: 32,
    paddingHorizontal: 16,
    paddingBottom: 16,
    alignItems: 'center',
  },
  orderDropdownGraphicAndButtonsStack: {
    gap: 24,
    width: '100%',
    alignItems: 'center',
  },
  orderDropdownButtonStack: {
    width: '100%',
    gap: 8,
  },
  deliveryFeeText: {
    fontSize: 12,
    lineHeight: 16,
    color: tokens.colors.$blackOpacity75,
  },
});
