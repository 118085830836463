import { useEffect, useMemo, useState } from 'react';
import { FlatList, SafeAreaView, SectionList, StyleSheet, View } from 'react-native';

import { getMenuItemData } from '@fhs/menu/src/data/__mocks__/menu-item-data';
import { Button, IconChevronDown, Text, tokens } from '@fhs/ui';

import { CartItem } from '../components/cart-item';
import { DonationSwitch } from '../components/donation-switcher';
import { UpsellItem } from '../components/upsell-item';
import { Debugger } from '../debugger';

import { GetCart, PriceOrder, useMutation, useQuery } from './client';

type Data =
  | {
      title: 'Donation';
      data: { key: string }[];
    }
  | { title: 'Upsells'; data: any[] }
  | { title: 'CartEntries'; data: any[] };

export function CartPage() {
  const { data: cart } = useQuery(GetCart);
  const [{ fetching: pricingOrder, error }, priceOrder] = useMutation(PriceOrder);
  const [showDebugger, setShowDebugger] = useState(false);
  const subtotal = cart?.entries.reduce((p, { price }) => p + price, 0);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  const data = useMemo<Data[]>(() => {
    return [
      { title: 'Donation', data: [{ key: 'donation' }] },
      {
        title: 'Upsells',
        // need something in here so it renders the entry
        data: [void 0],
      },
      {
        title: 'CartEntries',
        data: cart.entries,
      },
    ];
  }, [cart.entries]);

  return showDebugger ? (
    <Debugger onClose={() => setShowDebugger(false)} />
  ) : (
    <>
      <Button
        onPress={() => setShowDebugger(true)}
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}
      >
        <Button.Text>Debugger</Button.Text>
      </Button>
      <View style={styles.page}>
        {pricingOrder && <View style={styles.uiblock} />}
        <View style={styles.container}>
          <SectionList
            sections={data}
            contentContainerStyle={styles.list}
            ListEmptyComponent={() => {
              return (
                <View style={{ flex: 1, padding: 16, alignItems: 'center' }}>
                  <Text.Heading type="one">No Cart Entries</Text.Heading>
                </View>
              );
            }}
            renderSectionHeader={({ section }) => {
              switch (section.title) {
                case 'Upsells':
                  return (
                    <Text.Ui size="md" weight="bold">
                      Add To Order
                    </Text.Ui>
                  );
                case 'CartEntries':
                  return (
                    <Text.Ui size="md" weight="bold">
                      Cart Items
                    </Text.Ui>
                  );
                default:
                  return null;
              }
            }}
            renderItem={({ item, section }) => {
              switch (section.title) {
                case 'Donation':
                  return <DonationSwitch donationAmount={0} />;
                case 'Upsells':
                  return <UpsellFlatList />;
                case 'CartEntries':
                  return <CartItem {...item} />;
              }
            }}
          />
        </View>
        <SafeAreaView>
          <View style={styles.subtotalContainer}>
            <View style={[styles.container, styles.subtotalRow]}>
              <Text.Ui size="md" weight="semibold">
                Subtotal:
              </Text.Ui>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text.Ui size="md" weight="semibold">
                  ${subtotal / 100}
                </Text.Ui>
                <IconChevronDown color={tokens.colors.$houseRedDarken} size={24} />
              </View>
            </View>
          </View>
          <View style={styles.container}>
            <Button onPress={priceOrder} loading={pricingOrder}>
              <Button.Text>Continue</Button.Text>
            </Button>
          </View>
        </SafeAreaView>
      </View>
    </>
  );
}

function UpsellFlatList() {
  /* TODO: SimplyBetter - how do we want to choose what items to add to order */
  const upsells = [getMenuItemData('firehouse-hero'), getMenuItemData('hook-and-ladder')];

  return (
    <FlatList
      data={upsells}
      contentContainerStyle={styles.upsellList}
      keyExtractor={item => item.id}
      horizontal
      renderItem={({ item }) => {
        return <UpsellItem {...item} />;
      }}
    />
  );
}

const styles = StyleSheet.create({
  upsellList: {
    gap: 12,
  },
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  container: {
    marginHorizontal: 'auto',
    padding: 16,
    width: 375,
    flex: 1,
  },
  list: {
    gap: 16,
  },
  subtotalContainer: {
    backgroundColor: tokens.colors.$houseLight,
    width: '100%',
    height: 48,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uiblock: {
    backgroundColor: tokens.colors.$black10,
    zIndex: 1000,
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
