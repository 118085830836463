import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  IAddCreditCardFormErrors,
  IAddCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/types';
import {
  getCreditCardFormErrors,
  initialCreditCardFormErrors,
  initialCreditCardFormValues,
  onCreditCardFormChange,
  testCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/utils';
import { CardBrand } from 'generated/graphql-gateway';
import { PaymentProcessor } from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  SupportedCardType,
  defaultSupportedCardTypes,
} from 'state/launchdarkly/variations';
import { ISOs } from 'utils/form/constants';

interface IUseCreditCardInputsProps {
  billingCountry: ISOs;
  paymentFieldVariations: PaymentFieldVariations;
  paymentProcessor?: PaymentProcessor;
}

interface IUseCreditCardInputs {
  creditCardFormValues: IAddCreditCardFormValues;
  creditCardFormErrors: IAddCreditCardFormErrors;
  handleCreditCardFormChanges: (name: string, value: string) => void;
  setCreditCardFormErrors: Dispatch<SetStateAction<IAddCreditCardFormErrors>>;
  setCreditCardFormValues: Dispatch<SetStateAction<IAddCreditCardFormValues>>;
  validateCreditCardFormOnSubmit: () => boolean;
}

export const useAddCreditCardFormInputs = ({
  billingCountry,
  paymentFieldVariations,
  paymentProcessor,
}: IUseCreditCardInputsProps): IUseCreditCardInputs => {
  const { formatMessage } = useIntl();
  const auth = useAuthContext();

  const [creditCardFormValues, setCreditCardFormValues] = useState<IAddCreditCardFormValues>(
    initialCreditCardFormValues({
      billingCountry,
      userDetailsName: auth.user?.details.name,
    })
  );
  const [creditCardFormErrors, setCreditCardFormErrors] = useState<IAddCreditCardFormErrors>(
    initialCreditCardFormErrors
  );

  // For lower env testing purposes
  const autoFill = useFlag(LaunchDarklyFlag.AUTO_FILL_TEST_CARD);
  useEffectOnce(() => {
    if (autoFill) {
      setCreditCardFormValues(
        testCreditCardFormValues({ isDelivery: false, paymentFieldVariations, paymentProcessor })
      );
    }
  });

  /**
   * Supported credit card brands
   * See confluence doc: https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3843227653/Card+Brands+Accepted+Per+Market
   */
  const rawSupportedCardBrands: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  const supportedCardTypes = rawSupportedCardBrands.reduce((acc, cardBrand) => {
    const supportedCardBrand = CardBrand[cardBrand.toUpperCase()]; // normalize card brand casing to correct potential user error in LD
    if (supportedCardBrand) {
      acc.push(supportedCardBrand);
    }
    return acc;
  }, [] as CardBrand[]);

  const handleCreditCardFormChanges = useCallback(
    (name: string, value: string) => {
      const { state, formErrors } = onCreditCardFormChange(
        name,
        value,
        creditCardFormValues,
        creditCardFormErrors,
        formatMessage,
        supportedCardTypes
      );
      setCreditCardFormErrors(prevState => ({ ...prevState, ...formErrors }));
      setCreditCardFormValues(prevState => ({ ...prevState, ...state }));
    },
    [creditCardFormValues, creditCardFormErrors, formatMessage, supportedCardTypes]
  );

  const validateCreditCardFormOnSubmit = useCallback(() => {
    const { formErrors, hasErrors } = getCreditCardFormErrors(
      creditCardFormValues,
      creditCardFormErrors,
      formatMessage,
      paymentFieldVariations,
      billingCountry
    );
    if (hasErrors) {
      // TODO: Payments Refactor - Handle focusing on first input with an error
      setCreditCardFormErrors(prevState => ({ ...prevState, ...formErrors }));
    }
    return !hasErrors;
  }, [
    billingCountry,
    creditCardFormErrors,
    creditCardFormValues,
    formatMessage,
    paymentFieldVariations,
  ]);

  return {
    creditCardFormValues,
    creditCardFormErrors,
    handleCreditCardFormChanges,
    setCreditCardFormErrors,
    setCreditCardFormValues,
    validateCreditCardFormOnSubmit,
  };
};
