import { useCallback, useState } from 'react';

import {
  IDeletePaymentMethodInput,
  StoredPaymentMethodsDocument,
  useDeletePaymentMethodMutation,
} from 'generated/graphql-gateway';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';

import { DeletePaymentMethodHandler, IDeletePaymentMethod } from './types';

interface IUseDeletePaymentMethod {
  deletePaymentMethod: DeletePaymentMethodHandler;
  isDeleting: boolean;
}

export const useDeletePaymentMethod = (): IUseDeletePaymentMethod => {
  const { feCountryCode } = useLocale();

  const [deletePaymentMethodMutation] = useDeletePaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: StoredPaymentMethodsDocument, variables: { feCountryCode } }],
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const logDeletePaymentMethod = useCallback(
    (success: boolean, context: Record<string, any> = {}) => {
      if (success) {
        logger.info({
          message: 'Payment method deleted',
          context,
        });
      } else {
        logger.error({
          message: 'Error deleting payment method',
          context,
        });
      }
    },
    []
  );

  const deletePaymentMethod = useCallback(
    async ({ storedPaymentMethodId }: IDeletePaymentMethod) => {
      setIsDeleting(true);
      let isDeletePaymentMethodSuccess = false;
      try {
        const input: IDeletePaymentMethodInput = {
          storedPaymentMethodId,
        };
        const { data } = await deletePaymentMethodMutation({
          variables: {
            input,
          },
        });

        if (!data) {
          throw Error('Error deleting a payment method');
        }

        isDeletePaymentMethodSuccess = data.deletePaymentMethod.success;

        if (!isDeletePaymentMethodSuccess) {
          throw Error('Deleting a payment method failed');
        }

        logDeletePaymentMethod(isDeletePaymentMethodSuccess, undefined);
      } catch (error) {
        logger.error({
          error,
          message: 'Error deleting payment method',
        });
        logDeletePaymentMethod(isDeletePaymentMethodSuccess, undefined);
      } finally {
        setIsDeleting(false);
      }

      return isDeletePaymentMethodSuccess;
    },
    [deletePaymentMethodMutation, logDeletePaymentMethod]
  );

  return {
    deletePaymentMethod,
    isDeleting,
  };
};
