import { router, useLocalSearchParams } from 'expo-router';
import { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { TabbedHeader, tokens } from '@fhs/ui';

import { MenuOffersLink } from '../components/menu-offers-link';
import { MenuSection } from '../components/menu-section';
import { getMenuListData } from '../data/__mocks__/menu-list-data';
import type { ScreenMenuListProps } from '../types';

export function ScreenMenuList(props: ScreenMenuListProps) {
  // TODO(simplified-menu): Update the selectedIndex when a section is in view
  const [selectedSlug, setSelectedSlug] = useState(props.selectedSection);
  const activeIndex = props.headers.findIndex(item => item.slug === selectedSlug);
  const [coordinate, setCoordinates] = useState<{ [key: string]: number }>({});
  const scrollViewRef = useRef(null);

  useEffect(() => {
    // Scroll to the selected section
    const y = coordinate[selectedSlug];
    if (y !== undefined) {
      scrollViewRef.current?.scrollTo({
        y: y,
        animated: true,
      });
    }
  }, [selectedSlug, coordinate]);

  const onSelect = args => {
    setSelectedSlug(args.slug);
    // TODO(simplified-menu): Can we use # (hash) instead of ? (query string)
    router.setParams({ section: args.slug });
    return;
  };

  return (
    <>
      <View style={styles.containerTabbedHeader}>
        <TabbedHeader onSelect={onSelect} activeIndex={activeIndex} items={props.headers} />
      </View>
      <ScrollView ref={scrollViewRef}>
        <View style={styles.containerInner}>
          <View style={styles.offerLinkContainer}>
            <MenuOffersLink />
          </View>
          {props.sections.map(item => (
            <MenuSection
              key={item.slug}
              section={item}
              onLayout={event => {
                const layout = event.nativeEvent.layout;
                coordinate[item.slug] = layout.y;
                setCoordinates(coordinate);
              }}
              isSelected={item.slug === selectedSlug}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
}

export function ScreenMenuListWithMock() {
  const { menuSectionsHeader, menuSectionData } = getMenuListData();
  // TODO(simplified-menu): Can we use # (hash) instead of ? (query string)
  const { section } = useLocalSearchParams<{ section: string }>();

  // TODO(simplified-menu): remove hardcoded value
  const selectedSection = section || 'hot-subs';
  return (
    <ScreenMenuList
      headers={menuSectionsHeader}
      sections={menuSectionData}
      selectedSection={selectedSection}
    />
  );
}

const styles = StyleSheet.create({
  offerLinkContainer: {
    marginTop: 18,
    marginHorizontal: 16,
    marginBottom: 20,
  },
  containerTabbedHeader: {
    backgroundColor: tokens.colors.$white,
    alignItems: 'center',
  },
  containerInner: {
    backgroundColor: tokens.colors.$white,
    alignSelf: 'center',
    // TODO(simplified-menu): Update to use tokens
    maxWidth: 620,
  },
});
