import { Dispatch, SetStateAction, useCallback } from 'react';
import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import { DEFAULT_TIP_AMOUNT } from 'components/payments/components/payment-totals/tip-selector/constants';
import theme from 'pages/cart/your-cart/theme';
import { TipAmounts, useOrderContext } from 'state/order';
import { ITipSelection } from 'state/order/types';

import { StyledPressableTip, StyledTipOptions, StyledTipText } from './styled';

interface ITipOptionsProps {
  isDisabled: boolean;
  onTipAmountChange: (newTipAmount: string) => void;
  otherDiscountAmount: number;
  setErrorString: Dispatch<SetStateAction<string>>;
  setCustomTipString: Dispatch<SetStateAction<string>>;
  subTotalCents: number;
}

export const TipOptions: React.FC<React.PropsWithChildren<ITipOptionsProps>> = ({
  isDisabled,
  onTipAmountChange,
  otherDiscountAmount,
  setErrorString,
  setCustomTipString,
  subTotalCents,
}) => {
  const { formatMessage } = useIntl();
  const { setTipSelection, tipSelection, shouldShowTipPercentage, updateTipAmount } =
    useOrderContext();

  const showTipPercentage = shouldShowTipPercentage(subTotalCents + otherDiscountAmount);
  const predefinedTipAmounts = shouldShowTipPercentage
    ? TipAmounts.PERCENT_AMOUNTS
    : TipAmounts.DOLLAR_AMOUNTS;
  const tip = shouldShowTipPercentage ? tipSelection.percentAmount : tipSelection.dollarAmount;

  const buildRadioProps = (tipAmount: string | number) => {
    const numAmount = tipAmount === 'other' ? 0 : tipAmount;
    return {
      $isSelected: tipSelection.isOtherSelected ? tipAmount === 'other' : tip === tipAmount,
      onPress: () => {
        if (isDisabled) {
          return;
        }
        setTipSelection((selection: ITipSelection) => {
          if (tipAmount === 'other') {
            // Reset to default when changing to "Other"
            onTipAmountChange(DEFAULT_TIP_AMOUNT);
            return {
              ...selection,
              isOtherSelected: true,
              otherAmount: 0,
            };
          }
          if (showTipPercentage) {
            return {
              ...selection,
              isOtherSelected: false,
              percentAmount: tipAmount as number,
            };
          }
          return {
            ...selection,
            isOtherSelected: false,
            dollarAmount: tipAmount as number,
          };
        });
        setCustomTipString('');
        setErrorString('');
        if (tipAmount !== 'other') {
          onTipChange(numAmount.toString());
        }
      },
    };
  };

  const onTipPercentChange = useCallback(
    (tipPercent: string) => {
      updateTipAmount({
        subTotalCents,
        otherDiscountAmount,
        newTipOption: 'percent',
        newTipAmount: parseFloat(tipPercent),
      });
    },
    [otherDiscountAmount, subTotalCents, updateTipAmount]
  );

  const onTipChange = useCallback(
    (amount: string) => {
      if (showTipPercentage) {
        onTipPercentChange(amount);
      } else {
        onTipAmountChange(amount);
      }
    },
    [showTipPercentage, onTipAmountChange, onTipPercentChange]
  );

  return (
    <StyledTipOptions
      showsHorizontalScrollIndicator={false}
      horizontal
      accessibilityLabel="Select Tip"
      contentContainerStyle={{ flexGrow: 1 }}
    >
      {predefinedTipAmounts.map((amount, index) => {
        return (
          <StyledPressableTip
            key={index}
            flex={1}
            accessibilityLabel={`${amount} tip`}
            {...buildRadioProps(amount)}
            $isFirst={index === 0}
          >
            {showTipPercentage ? (
              <StyledTipText
                $isSelected={buildRadioProps(amount).$isSelected}
              >{`${amount}%`}</StyledTipText>
            ) : (
              <Currency
                amount={amount * 100}
                textProps={{
                  fontWeight: 'normal',
                  margin: 0,
                  textAlign: 'center',
                  color: buildRadioProps(amount).$isSelected
                    ? Styles.color.white
                    : theme.tipSelectorLabelColor,
                }}
              />
            )}
          </StyledPressableTip>
        );
      })}
      {/* Option for a user to set a custom amount */}
      <StyledPressableTip
        flex={1}
        accessibilityLabel="Other tip"
        {...buildRadioProps('other')}
        $isLast
      >
        <StyledTipText $isSelected={tipSelection.isOtherSelected}>
          {formatMessage({ id: 'other' })}
        </StyledTipText>
      </StyledPressableTip>
    </StyledTipOptions>
  );
};
