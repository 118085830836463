import { FC } from 'react';

import { ConfirmPickup } from 'components/payments/components/confirm-pickup/confirm-pickup';
import { IConfirmPickup } from 'components/payments/entry-points/confirm-pickup/types';

const OrbitalConfirmPickup: FC<IConfirmPickup> = ({ serverOrder }) => {
  return <ConfirmPickup serverOrder={serverOrder} />;
};

export default OrbitalConfirmPickup;
