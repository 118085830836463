import { router } from 'expo-router';
import { useCallback, useEffect, useState } from 'react';

import { IBackendCartEntries, IHistoricalOrder, ServiceMode } from '@rbi-ctg/menu';
import useFoodware from 'hooks/use-foodware';
import { usePathname } from 'hooks/use-pathname';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { isOfferType } from '../utils';

export interface IFavoriteOrder {
  cart: {
    cartEntries: IBackendCartEntries[];
    /* reorderCartEntries are needed to handleReorder */
    reorderCartEntries: IBackendCartEntries[];
    serviceMode: ServiceMode | null;
    cartVersion: number;
  };
  status?: OrderStatus;
}

export const useReorder = (order?: IHistoricalOrder | IFavoriteOrder | null) => {
  const [hasOffer, setHasOffer] = useState(false);
  const {
    reorder: { handleReorder, setPendingReorder, reordering, setShouldHandleReorder },
  } = useOrderContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const { serviceMode: selectedServiceMode, setServiceMode } = useServiceModeContext();

  const pathname = usePathname();

  const enableReorder = useFlag(LaunchDarklyFlag.ENABLE_REORDER);
  const { isFoodware } = useFoodware();

  /**
   * TODO: Add support for offers with reorder.
   * - right now just disabling reorder if there's an offer.
   */
  useEffect(() => {
    const hasOfferEntry =
      order && order.cart
        ? (order.cart.cartEntries || []).some((entry: IBackendCartEntries) =>
            isOfferType(entry.type)
          )
        : false;

    setHasOffer(hasOfferEntry);
  }, [order]);

  const handleReorderClick = useCallback(
    async ({ skipStoreLocatorRedirect = false, switchingStore = false } = {}) => {
      if (!order) {
        return;
      }

      const filteredReorderCartEntries = order.cart.reorderCartEntries?.filter(
        item => !(item.isDonation || isFoodware(item))
      );

      const filteredOrder = {
        ...order,
        cart: {
          ...order.cart,
          reorderCartEntries: filteredReorderCartEntries,
        },
      };

      if (isStoreOpenAndAvailable && !switchingStore) {
        handleReorder(filteredOrder);
      } else {
        setPendingReorder(filteredOrder);
        setShouldHandleReorder(true);

        if (skipStoreLocatorRedirect) {
          const orderServiceMode = order?.cart.serviceMode;

          // We need to have a service mode selected before trying to reorder an order
          if (orderServiceMode && !selectedServiceMode) {
            setServiceMode(orderServiceMode);
          }

          return;
        }

        const route = isCatering(filteredOrder.cart.serviceMode)
          ? routes.serviceModeCatering
          : routes.storeLocator;

        router.navigate({ pathname: route, params: { back: pathname } });
      }
    },
    [
      setServiceMode,
      selectedServiceMode,
      order,
      isStoreOpenAndAvailable,
      isFoodware,
      handleReorder,
      setPendingReorder,
      pathname,
      setShouldHandleReorder,
    ]
  );

  const serviceMode = order?.cart.serviceMode ?? null;
  const isCateringWithWrongStatus =
    isCatering(serviceMode) && order?.status !== OrderStatus.CATERING_SUCCESSFUL;

  const disabled =
    !enableReorder ||
    hasOffer ||
    isCateringWithWrongStatus ||
    isCatering(serviceMode) ||
    (!isStoreOpenAndAvailable && !noStoreSelected) ||
    reordering;

  return { handleReorderClick, disabled, reordering };
};
