import { Box, Divider, Text } from '@fhs-legacy/universal-components';

export const CardBalanceText = Text.withConfig({
  textAlign: 'center',
});

export const CurrentBalanceText = Text.withConfig({
  textAlign: 'center',
  fontSize: 'lg',
  fontWeight: 'bold',
});

export const ModalHr = Divider.withConfig({
  width: '100%',
  thickness: '1',
  marginX: 0,
  marginY: '$8',
});

export const CurrentBalanceDescription = Box.withConfig({
  _text: {
    fontSize: 'sm',
    fontWeight: 'bold',
  },
});
