import { usePathname } from 'expo-router';
import { useEffect, useRef } from 'react';

import { useCRMEventsContext } from 'state/crm-events';
import { useStoreContext } from 'state/store';

export default function useLogPageView() {
  const pathname = usePathname();
  const lastLoggedPathname = useRef('');
  const { logPageView } = useCRMEventsContext();
  const { store } = useStoreContext();

  useEffect(() => {
    if (lastLoggedPathname.current !== pathname) {
      logPageView(pathname, store);
      lastLoggedPathname.current = pathname;
    }
  }, [logPageView, pathname, store]);
}
