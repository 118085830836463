import { PaymentIntegrationType } from 'components/payments/entry-points/types';
import { FirstDataSuccessTransferGiftCard } from 'components/payments/integrations/firstdata/firstdata-gift-card';

type SuccessTransferGiftCardIntegrationMapping = Partial<
  Record<(typeof PaymentIntegrationType)[keyof typeof PaymentIntegrationType], () => JSX.Element>
>;

export const successTransferGiftCardIntegrationMapping: SuccessTransferGiftCardIntegrationMapping =
  {
    [PaymentIntegrationType.FIRSTDATA]: FirstDataSuccessTransferGiftCard,
  };
