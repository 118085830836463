import React from 'react';
import { useIntl } from 'react-intl';

import { IServerOrder } from '@rbi-ctg/menu';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { fullBrandName } from 'utils/environment';
import { getOrderNumber } from 'utils/order';

import PickupCard from './pickup-card';
import { MapContainer, StoreCardContainer, StoreCardContainerInner } from './styled';

export const ConfirmPickup = ({ serverOrder }: { serverOrder: IServerOrder }) => {
  const { formatMessage } = useIntl();

  const enableOrderNumberGeneration = useFlag(LaunchDarklyFlag.ENABLE_ORDER_NUMBER_GENERATION);
  const orderNumber = getOrderNumber(serverOrder, enableOrderNumberGeneration);

  return (
    <MapContainer>
      <StoreCardContainer>
        <StoreCardContainerInner>
          <PickupCard serverOrder={serverOrder} />
        </StoreCardContainerInner>
      </StoreCardContainer>
      <VisuallyHidden
        role="alert"
        accessibilityLabel={formatMessage(
          { id: 'pickUpOrderSuccessfulAlert' },
          {
            orderNumber,
            brand: fullBrandName(),
            address: `${serverOrder.cart.storeAddress.addressLine1} ${serverOrder.cart.storeAddress.addressLine2}`,
          }
        )}
      />
    </MapContainer>
  );
};
