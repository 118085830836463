// This file mocks the idea of a dynamo stream lambda
// that is called when the cart db changes for a record
import { http as loyaltyHttp } from '@fhs/loyalty';

import { createLogger } from '../debugger';
import { CartModel } from '../types';

import { updateCart } from './update-cart';

// The dynamo stream for the cart is effectively a state machine
// that will operate as a state machine
export async function dynamoStream(cart: CartModel) {
  const logger = createLogger('dynamostream');
  switch (cart.state) {
    case 'NEEDS_LOYALTY_VALIDATION':
      logger.log('Handling NEEDS_LOYALTY_VALIDATION cart state');
      const discounts = await loyaltyHttp.receiveDiscounts(cart);
      cart.discounts = discounts;
      const updates = await loyaltyHttp.validateCart(cart);
      cart.state = updates.state;
      await updateCart(cart, logger);
      break;
    default:
      logger.log(`No handler for ${cart.state}`);
  }
}
