import { StyleSheet, View } from 'react-native';

import { Skeleton, SkeletonTextBlock, tokens } from '@fhs/ui';

export function OfferListSkeletonTile() {
  return (
    <View style={styles.offerItemContainer}>
      <Skeleton style={styles.imageContainer} />
      <SkeletonTextBlock lines={3} style={styles.textContainer} />
      <View style={styles.buttonContainer}>
        <Skeleton style={styles.skeletonButton} />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  offerItemContainer: {
    width: 165,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    borderRadius: 8,
    height: 231,
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    padding: 12,
    width: '100%',
  },
  imageContainer: {
    height: 96,
    borderRadius: 0,
    backgroundColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$black10,
    width: '100%',
  },
  buttonContainer: {
    padding: 12,
    width: '100%',
  },
  skeletonButton: {
    height: 31,
  },
});
