import { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Modal, StyleSheet } from 'react-native';

import { IconClose, Pressable, Text, XStack, YStack } from '@fhs/ui';
import { useBoxRef } from '@fhs/utils';

import { tokens } from '../../tokens';

import { ActionSheetProps } from './types';

export const BottomDrawer = ({ isVisible, onClose, headerTitle, children }: ActionSheetProps) => {
  const [drawerHeight, setDrawerHeight] = useState(0);

  const animation = useRef(new Animated.Value(0)).current;

  const handleRequestCloseRef = useBoxRef(() => {
    onClose();
  });

  const openDrawer = () => {
    Animated.timing(animation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  const closeDrawer = () => {
    Animated.timing(animation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => onClose?.());
  };

  const onLayout = useCallback(event => {
    const { height } = event.nativeEvent.layout;
    setDrawerHeight(height);
  }, []);

  useEffect(() => {
    if (isVisible) {
      openDrawer();
      return;
    }

    closeDrawer();
  }, [isVisible]);

  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [drawerHeight, 0],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0.75],
  });

  return (
    <Modal
      visible={isVisible}
      transparent
      style={styles.overlay}
      onRequestClose={handleRequestCloseRef.current}
    >
      <Animated.View style={[styles.fullScreen, { opacity }]}>
        <Pressable
          aria-label="Close dropdown"
          accessible={false}
          focusable={false}
          style={styles.fullScreen}
          onPress={handleRequestCloseRef.current}
        />
      </Animated.View>
      <Animated.View style={[styles.drawerContainer, { transform: [{ translateY }] }]}>
        {!!headerTitle && (
          <XStack style={styles.header}>
            <Text style={styles.headerTitle} weight="semibold">
              {headerTitle}
            </Text>
            <Pressable style={styles.closeIcon} onPress={onClose}>
              <IconClose size={24} />
            </Pressable>
          </XStack>
        )}
        <YStack style={styles.drawer} onLayout={onLayout}>
          {children}
        </YStack>
      </Animated.View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  fullScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$black,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    minHeight: 56,
  },
  headerTitle: {
    fontSize: 16,
  },
  drawerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colors.$white,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingVertical: 24,
  },
  drawer: {
    marginTop: 16,
    flex: 1,
  },
  closeIcon: {
    position: 'absolute',
    right: 26,
  },
});
