import { CartModel, Discount } from '@fhs/cart';

export async function receiveDiscounts(_cart: CartModel): Promise<Discount[]> {
  return [];
}

export async function validateCart(
  _cart: CartModel
): Promise<{ state: 'VALID' | 'LOYALTY_INVALID' }> {
  return {
    state: 'VALID',
  };
}
