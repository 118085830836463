import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useOrderNumber from 'hooks/use-order-number';
import { fullBrandName } from 'utils/environment';

import { MapContainer } from './common';
import PickupCard from './pickup-card';

const StoreCardContainer = Box.withConfig({
  justifyContent: 'center',
  flex: 1,
  marginTop: { base: '0', desktop: '$4' },
  marginX: { desktop: 'auto' },
  maxWidth: { desktop: Styles.layout.smallerSectionMaxWidth },
});

const StoreCardContainerInner = Box.withConfig({
  flex: 1,
  justifyContent: 'center',
  marginBottom: { base: '$24', desktop: '$4' },
  paddingTop: '$10',
  paddingBottom: '$2',
});

function ConfirmPickup({ serverOrder }: { serverOrder: IServerOrder }) {
  const { formatMessage } = useIntl();
  const orderNumber = useOrderNumber(serverOrder);
  return (
    <MapContainer>
      <StoreCardContainer>
        <StoreCardContainerInner>
          <PickupCard serverOrder={serverOrder} />
        </StoreCardContainerInner>
      </StoreCardContainer>
      <VisuallyHidden
        role="alert"
        accessibilityLabel={formatMessage(
          { id: 'pickUpOrderSuccessfulAlert' },
          {
            orderNumber,
            brand: fullBrandName(),
            address: `${serverOrder.cart.storeAddress.addressLine1} ${serverOrder.cart.storeAddress.addressLine2}`,
          }
        )}
      />
    </MapContainer>
  );
}

export default React.memo(ConfirmPickup);
