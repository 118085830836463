import cloneDeep from 'lodash/cloneDeep';

import { create } from '@fhs/zustand';
import LocalStorage from '@fhs-legacy/frontend/src/utils/local-storage';

import { CartModel } from '../types';

import { dynamoStream } from './dynamo-stream';

const NEW_CART: CartModel = {
  donationAmount: 0,
  userId: 'foobar',
  entries: [],
  discounts: [],
  store: null,
  state: 'VALID',
  version: 0,
  serviceMode: 'pickup',
  metadata: {},
};

export const __resetCartState = () => {
  cart.setState(NEW_CART);
};

export const cart = create<CartModel>(() => {
  try {
    // using raw local storage - this will break native
    const d = JSON.parse(localStorage.getItem('RBI:V2:CART'));
    if (d === null) {
      throw new Error();
    }
    return {
      ...NEW_CART,
      ...d,
    };
  } catch {
    return NEW_CART;
  }
});

cart.subscribe(state => {
  LocalStorage.setItem('RBI:V2:CART', state);
  dynamoStream(cloneDeep(state));
});
