import { FC } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import OrbitalConfirmPickup from 'components/payments/integrations/orbital/orbital-confirm-pickup';
import WorldPayConfirmPickup from 'components/payments/integrations/worldpay/worldpay-confirm-pickup';

import { PaymentIntegrationType } from '../types';

export interface IConfirmPickup {
  serverOrder: IServerOrder;
}

type ConfirmPickupIntegrationMapping = Record<string, FC<IConfirmPickup>>;

export const confirmPickupIntegrationMapping: ConfirmPickupIntegrationMapping = {
  [PaymentIntegrationType.ORBITAL]: OrbitalConfirmPickup,
  [PaymentIntegrationType.WORLDPAY]: WorldPayConfirmPickup,
};
