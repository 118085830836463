import React from 'react';
import { useIntl } from 'react-intl';

import { HStack, Pressable, Text } from '@fhs-legacy/universal-components';
import { ADD_NEW_CARD_BUTTON } from 'utils/test-ids';

import {
  StyledAddCreditCardIcon,
  StyledGenericPaymentMethodIcon,
} from '../icons/card-icon/card-icon';
import { PrepaidIcon } from '../icons/prepaid-icon/prepaid-icon';

export interface IAddPaymentMethodOptionProps {
  Icon?: React.ReactNode;
  messageId?: string;
  onPress: () => void;
  selected?: boolean;
  testID?: string;
}

export const AddPaymentMethodOption: React.FC<IAddPaymentMethodOptionProps> = ({
  Icon,
  messageId,
  onPress,
  testID,
}) => {
  const { formatMessage } = useIntl();

  const text = formatMessage({ id: messageId ?? 'addNewPaymentMethod' });
  const handleOnPress = () => {
    if (!onPress) {
      return;
    }
    onPress();
  };

  return (
    <Pressable onPress={handleOnPress} testID={testID ?? 'add-new-payment-method'}>
      <HStack alignItems="center" space={2}>
        {Icon || <StyledGenericPaymentMethodIcon />}
        <Text>{text}</Text>
      </HStack>
    </Pressable>
  );
};

export const AddCreditCardOption = ({ onPress, selected }: IAddPaymentMethodOptionProps) => (
  <AddPaymentMethodOption
    Icon={<StyledAddCreditCardIcon />}
    messageId="addNewCard"
    onPress={onPress}
    selected={selected}
    testID={ADD_NEW_CARD_BUTTON}
  />
);

export const AddGiftCardOption = ({ onPress, selected }: IAddPaymentMethodOptionProps) => (
  <AddPaymentMethodOption
    Icon={<PrepaidIcon />}
    messageId="addPrepaidCard"
    onPress={onPress}
    selected={selected}
    testID="add-gift-card"
  />
);
