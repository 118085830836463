import { Pressable, ScrollView, Text } from '@fhs-legacy/universal-components';
import theme from 'pages/cart/your-cart/theme';
import { theme as configureTheme } from 'styles/configure-theme';

export const StyledPressableTip = Pressable.withConfig<{
  $isSelected: boolean;
  $isFirst?: boolean;
  $isLast?: boolean;
}>(p => ({
  borderRightWidth: p.$isLast ? 0 : 1,
  borderRightColor: p.$isLast ? 'transparent' : Styles.color.grey.three,
  backgroundColor: p.$isSelected
    ? theme.tipSelectionBackground
    : configureTheme.token('background-default'),
  padding: '$2.5',
  borderLeftRadius: p.$isFirst ? 8 : 0,
  borderRightRadius: p.$isLast ? 8 : 0,
}));

export const StyledTipOptions = ScrollView.withConfig({
  keyboardShouldPersistTaps: 'always',
  width: 'full',
  overflow: 'hidden',
  backgroundColor: configureTheme.token('background-default'),
  borderColor: Styles.color.grey.three,
  borderWidth: 2,
  borderLeftRadius: Styles.borderRadius,
  borderRightRadius: Styles.borderRadius,
  padding: 0,
  marginY: '$8',
});

export const StyledTipText = Text.withConfig<{ $isSelected: boolean }>(p => ({
  fontWeight: 'bold',
  textAlign: 'center',
  color: p.$isSelected ? Styles.color.white : theme.tipSelectorLabelColor,
}));
