import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Currency from 'components/currency';
import LoadingAnimation from 'components/loading-animation';
import GrandTotal from 'pages/cart/your-cart/totals/grand-total';
import { useTotalTipCalculations } from 'pages/cart/your-cart/totals/use-total-tip-calculations';
import { useCartContext } from 'state/cart';
import { useOrderContext } from 'state/order';
import { EventName, emitEvent } from 'utils/event-hub';

import { StyledTipAmount, StyledTotalsContainer } from './styled';
import { TipSelector } from './tip-selector';

interface IPaymentTotalsProps {
  tipSelectorDisabled: boolean;
}

export const PaymentTotals: FC<React.PropsWithChildren<IPaymentTotalsProps>> = ({
  tipSelectorDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { tipAmount } = useOrderContext();
  const { serverOrder, serviceMode } = useCartContext();
  const {
    hideTipAmount,
    subTotalCents = 0,
    serverOrderTip,
    additionalDiscountAmount,
    cart,
    loading,
    isDeliveryOrder,
  } = useTotalTipCalculations({ serverOrder, serviceMode });

  useEffect(() => {
    if (!loading) {
      emitEvent(EventName.CART_STEP_2_PRICES_LOADING_END);
    }
  }, [loading]);

  if (loading || (isDeliveryOrder && !serverOrder?.delivery)) {
    // if loading is true there may have been a pricing error so
    // we should not attempt to render values that depend on the cart
    return <LoadingAnimation />;
  }

  return (
    <StyledTotalsContainer accessible>
      <GrandTotal
        cart={cart}
        serverOrderTip={serverOrderTip}
        tipAmount={isDeliveryOrder && !hideTipAmount ? tipAmount : 0}
      />
      {isDeliveryOrder && !hideTipAmount && (
        <>
          <StyledTipAmount testID="tip-amount">
            {formatMessage({ id: 'tip' })}
            <Currency amount={tipAmount} />
          </StyledTipAmount>
          <TipSelector
            isDisabled={tipSelectorDisabled}
            otherDiscountAmount={additionalDiscountAmount}
            subTotalCents={subTotalCents}
          />
        </>
      )}
    </StyledTotalsContainer>
  );
};
