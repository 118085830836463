import React from 'react';

import { Icon } from '@fhs-legacy/universal-components';

import { cardBrandToIcon, nativeTypeToIcon } from './constants';

// This icon is used for stored credit cards/native payment methods (i.e. Visa, Amex, ApplePay, GooglePay, etc.)
export const CreditBrandIcon = ({ brand, type }: { brand?: string; type?: string }) => {
  if (brand) {
    return cardBrandToIcon[brand] || <StyledAddCreditCardIcon />;
  }

  if (type) {
    return nativeTypeToIcon[type] || <StyledAddCreditCardIcon />;
  }

  return <StyledAddCreditCardIcon />;
};

// This icon is used for the add credit card option in the payment method dropdown list
export const StyledAddCreditCardIcon = Icon.withConfig({
  variant: 'defaultCard',
});

// This icon is used when a user has no payment methods and is prompted to add one
export const StyledGenericPaymentMethodIcon = Icon.withConfig({ variant: 'addCard' });
