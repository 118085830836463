import { useCallback } from 'react';

import { PaymentMethod } from 'components/payments/integrations/hooks/types';
import { isCreditCardMethod, isGiftCardMethod } from 'components/payments/integrations/hooks/utils';
import {
  IMutationUpdateDefaultPaymentMethodArgs,
  UserPaymentInformationDocument,
  useUpdateDefaultPaymentMethodMutation,
} from 'generated/graphql-gateway';
import { useAmplitudeContext } from 'state/amplitude';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { setUserAttributes } from 'utils/braze';
import logger from 'utils/logger';

export type updateDefaultPaymentMethodHandler = (
  storedPaymentMethod: PaymentMethod
) => Promise<void>;

interface IUseUpdateDefaultPaymentMethod {
  isUpdating: boolean;
  updateDefaultPaymentMethod: updateDefaultPaymentMethodHandler;
}

export const useUpdateDefaultPaymentMethod = (): IUseUpdateDefaultPaymentMethod => {
  const { feCountryCode } = useLocale();
  const { user } = useAuthContext();
  const { updateAmplitudeUserAttributes } = useAmplitudeContext();

  const [updateDefaultPaymentMethodMutation, { loading }] = useUpdateDefaultPaymentMethodMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserPaymentInformationDocument, variables: { feCountryCode } }],
  });

  const logUserPaymentIdentity = useCallback(
    (selectedMethod: PaymentMethod) => {
      if (!user || !selectedMethod) {
        return;
      }
      let ccToken: string | undefined;
      if (isCreditCardMethod(selectedMethod)) {
        ccToken = selectedMethod.panToken ?? undefined;
      } else if (isGiftCardMethod(selectedMethod)) {
        ccToken = selectedMethod.shuffledCardNumber;
      }

      if (ccToken) {
        updateAmplitudeUserAttributes({
          ccToken,
        });
        setUserAttributes({ ccToken });
      }
    },
    [updateAmplitudeUserAttributes, user]
  );

  const updateDefaultPaymentMethod = useCallback(
    async (storedPaymentMethod: PaymentMethod): Promise<void> => {
      const { id } = storedPaymentMethod;
      try {
        const input: IMutationUpdateDefaultPaymentMethodArgs = {
          input: {
            storedPaymentMethodId: id,
          },
        };

        const { data } = await updateDefaultPaymentMethodMutation({ variables: input });

        if (!data || data?.updateDefaultPaymentMethod?.defaultPaymentMethodId !== id) {
          throw Error('Updaing default payment method failed');
        }

        logUserPaymentIdentity(storedPaymentMethod);
      } catch (error) {
        logger.error({
          error,
          message: 'Error updating default payment method',
        });
      }
    },
    [logUserPaymentIdentity, updateDefaultPaymentMethodMutation]
  );

  return {
    isUpdating: loading,
    updateDefaultPaymentMethod,
  };
};
