import { Link, Redirect, Stack } from 'expo-router';
import Head from 'expo-router/head';
import { View } from 'react-native';

import { GetCart, useQuery } from '@fhs/cart';
import { ScreenMenuListWithMock } from '@fhs/menu/src/screens/screen-menu-list';
import { Button } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isNative } from '@fhs-legacy/frontend/src/utils/environment';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuIndexPage() {
  const { data: cart } = useQuery(GetCart);
  // TODO(simplified-menu): Remove when simplified menu is out of beta
  if (!useFlag(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA)) {
    return <Redirect href="/menu" />;
  }
  return (
    <>
      <Stack.Screen options={{ headerShown: isNative }} />
      <Head>
        <title>Menu - Firehouse Subs</title>
      </Head>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <ScreenMenuListWithMock />
        </View>
        <View style={{ padding: 16, backgroundColor: '#fff' }}>
          <Link href="/v2/cart" asChild>
            <Button>
              <Button.Text>Checkout {`(${cart?.entries.length.toString()})`}</Button.Text>
            </Button>
          </Link>
        </View>
      </View>
    </>
  );
}
