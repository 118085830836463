import { Image, StyleSheet, View } from 'react-native';

import { MenuItem } from '@fhs/menu/src/types';
import { Button, IconAdd, Text, tokens } from '@fhs/ui';

import { AddItemToCart, useMutation } from '../../screens/client';

export function UpsellItem(props: MenuItem) {
  const [{ fetching }, addItemToCart] = useMutation(AddItemToCart, { itemId: props.id });

  return (
    <View style={styles.container}>
      <Image source={{ uri: props.imageUrl }} style={styles.image} />
      <View style={styles.copyContainer}>
        <Text.Ui size="md" numberOfLines={2}>
          {props.displayName}
        </Text.Ui>
        <View style={styles.priceAndCalories}>
          <Text.Ui size="sm" weight="bold">
            ${props.basePrice / 100}
          </Text.Ui>
          {/* TODO: SimplyBetter - bring in calories properly */}
          <Text.Ui size="sm">420 Cal</Text.Ui>
        </View>
      </View>

      <Button
        type="outline"
        style={styles.button}
        onPress={() => addItemToCart()}
        loading={fetching}
      >
        <Button.Icon>
          <IconAdd size={16} />
        </Button.Icon>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    minHeight: 'auto', // resets a base value, needed to get a true circle
    height: 32,
    width: 32,
  },
  container: {
    borderRadius: 8,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 12,
    padding: 12,
    height: 84,
    width: 227,
  },
  image: {
    height: 56,
    width: 56,
  },
  copyContainer: {
    flex: 1,
    gap: 4,
  },
  priceAndCalories: {
    flexDirection: 'row',
    gap: 8,
  },
});
