import { Link } from 'expo-router';
import { StyleSheet, View } from 'react-native';

import { Button, IconAdd, IconRemove, Text, tokens } from '@fhs/ui';

import { RemoveItemFromCart, useMutation } from '../../screens/client';
import { ItemEntry } from '../../types';

export function CartItem(props: ItemEntry) {
  const [{ fetching: removeItemFromCartFetching }, removeItemFromCart] =
    useMutation(RemoveItemFromCart);

  // all buttons should be disabled when any of the mutations are fetching
  const areButtonsDisabled = removeItemFromCartFetching;

  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.productInfoContainer}>
          <Text.Ui size="md" weight="bold">
            {props.displayName}
          </Text.Ui>
          {/* TODO: List modifiers */}
        </View>
        <View style={styles.totalsContainer}>
          <Text.Ui size="md">Item Total</Text.Ui>
          <View style={styles.prices}>
            <Text.Ui size="md" weight="bold">
              ${props.price / 100}
            </Text.Ui>
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        <View style={styles.editRemoveContainer}>
          <Link
            href={`/v2/menu/pepperoni-pizza-meatball-sub?lineId=${props.lineId}`}
            asChild
            disabled={areButtonsDisabled}
          >
            <Button type="ghost" disabled={areButtonsDisabled} style={styles.editremoveButton}>
              <Button.Text style={styles.underlineButtons}>Edit</Button.Text>
            </Button>
          </Link>
          <Button
            type="ghost"
            onPress={async () => {
              await removeItemFromCart({ lineId: props.lineId });
            }}
            disabled={areButtonsDisabled}
            loading={removeItemFromCartFetching}
            style={styles.editremoveButton}
          >
            <Button.Text style={styles.underlineButtons}>Remove</Button.Text>
          </Button>
        </View>
        <View style={styles.quantityContainer}>
          <Button
            style={styles.quantityButton}
            type="outline"
            size="xs"
            disabled={areButtonsDisabled}
          >
            <Button.Icon>
              <IconRemove />
            </Button.Icon>
          </Button>
          <Text style={styles.quantityInput}>1</Text>
          <Button
            disabled={areButtonsDisabled}
            type="outline"
            size="xs"
            style={styles.quantityButton}
          >
            <Button.Icon>
              <IconAdd />
            </Button.Icon>
          </Button>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  productInfoContainer: { minHeight: 31 },
  totalsContainer: {
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  underlineButtons: {
    textDecorationLine: 'underline',
    paddingLeft: 0,
    paddingRight: 0,
  },
  quantityInput: {
    fontFamily: 'Montserrat',
    alignContent: 'center',
    textAlign: 'center',
    height: 28,
    width: 28,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 100,
    gap: 8,
  },
  top: {
    padding: 16,
  },
  bottom: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    height: 56,
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
  },
  quantityButton: {
    // reset minHeight from the base component
    minHeight: 'auto',
    width: 28,
    height: 28,
    padding: 0,
  },
  container: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$black10,
  },
  editRemoveContainer: {
    flexDirection: 'row',
    gap: 16,
  },
  prices: {
    justifyContent: 'flex-end',
  },
  editremoveButton: {
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
});
