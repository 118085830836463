import { useGlobalSearchParams } from 'expo-router';
import { useCallback } from 'react';

import {
  IProductWizardFlow,
  ProductWizardParams,
  ProductWizardStep,
} from 'state/product-wizard/types';

import { useProductWizardContext } from '..';

const stringToBooleanParamMap = {
  true: true,
  false: false,
};

export const useProductWizardFlow = (): IProductWizardFlow => {
  const { _wizardFlowStep, _setWizardFlowStep, _slotKeyToCustomize, _setSlotKeyToCustomize } =
    useProductWizardContext();
  const routerParams = useGlobalSearchParams<ProductWizardParams>();
  const {
    id = '',
    rewardBenefitId,
    rewardId,
    isEdit = 'false',
    isFavorite = 'false',
    isFromCart = 'false',
    isFromSurprise = 'false',
  } = routerParams;

  const navigateToStep = useCallback(
    (step: ProductWizardStep, slotKey?: string) => {
      _setWizardFlowStep(step);
      _setSlotKeyToCustomize(slotKey ?? '');
    },
    [_setSlotKeyToCustomize, _setWizardFlowStep]
  );

  const goToHome = useCallback(() => {
    navigateToStep(ProductWizardStep.ProductHome);
  }, [navigateToStep]);

  const goToCustomizeProduct = useCallback(
    (slotKey: string) => {
      navigateToStep(ProductWizardStep.ProductCustomization, slotKey);
    },
    [navigateToStep]
  );

  return {
    id,
    isEdit: stringToBooleanParamMap[isEdit],
    isFavorite: stringToBooleanParamMap[isFavorite],
    isFromCart: stringToBooleanParamMap[isFromCart],
    isFromSurprise: stringToBooleanParamMap[isFromSurprise],
    rewardBenefitId,
    rewardId,
    currentStep: Number(_wizardFlowStep),
    slotKeyToCustomize: _slotKeyToCustomize,
    goToHome,
    goToCustomizeProduct,
  };
};

export default useProductWizardFlow;
