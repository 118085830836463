import React from 'react';
import { useIntl } from 'react-intl';

import { Box, HStack, Icon, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

interface ICashOptionProps {
  isDisabled: boolean;
}

export const CashOption = ({ isDisabled }: ICashOptionProps) => {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: 'payWithCash' });

  return (
    <HStack alignItems="center" space={2}>
      <Box justifyContent="center">
        <Icon variant="cash" />
      </Box>
      <Text color={theme.token(isDisabled ? 'text-disabled' : 'text-default')}>{message}</Text>
    </HStack>
  );
};
