import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../components/get-screen-title-header';

export default function V2StackLayout() {
  return (
    <>
      <Stack screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="menu"
          options={{
            header: getScreenTitleHeader({
              backStyle: 'back',
            }),
          }}
        />
        <Stack.Screen
          name="/cart"
          options={{
            title: 'Cart',
            header: getScreenTitleHeader({
              backStyle: 'back',
            }),
          }}
        />
        <Stack.Screen
          name="index"
          options={{
            title: 'Simply Better',
            header: getScreenTitleHeader({ backStyle: 'back' }),
          }}
        />
        <Stack.Screen
          name="offers"
          options={{
            title: 'Simply Better',
            header: getScreenTitleHeader({ backStyle: 'back' }),
          }}
        />
      </Stack>
    </>
  );
}
