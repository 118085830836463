import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  IExpoGooglePayResult,
  canMakePayments,
  isSupported,
  makeWorldPayPaymentRequest,
} from '@rbilabs/expo-googlepay';
import { IRequestGooglePayPayment } from 'components/payments/integrations/hooks/types';
import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { CartPaymentCardType, IOrder } from 'generated/graphql-gateway';
import { useConfigValue } from 'hooks/configs/use-config-value';
import {
  TransactionType,
  getGooglePaymentsConfigs,
} from 'state/google-pay/get-google-payments-configs';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { brand, env, fullBrandName } from 'utils/environment';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import { isCatering } from 'utils/service-mode';

export default function useWorldpayGooglePay(rbiOrderId: string) {
  const { formatMessage } = useIntl();
  const locale = useLocale();
  const enableGooglePay = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_PAY);
  const { serviceMode } = useServiceModeContext();
  const isCateringOrder = isCatering(serviceMode);
  const googlePayPluginAvailable = isSupported();
  const googleConfig = useConfigValue({ key: 'google', defaultValue: {} });

  const { handleCommitOrder } = useCommitOrder(rbiOrderId);

  /**
   * Get an eProtect low value token for payment
   */
  const requestGooglePay = useCallback(
    async ({
      total,
      billingCountry,
      migrationEnabled,
    }: {
      total: number;
      billingCountry: ISOs;
      migrationEnabled: boolean;
    }): Promise<{ lowValueToken: string; fullName: string } | undefined> => {
      const googleConfigNetworks = googleConfig.paymentsNetworks;
      const networks = googleConfigNetworks.filter((i: unknown) => !!i);
      const environment = googleConfig.paymentsEnvironment;

      if (!googlePayPluginAvailable) {
        logger.info('Google Pay Plugin is not available');
        return;
      }
      if (!enableGooglePay) {
        logger.info('Google Pay is disabled via LD');
        return;
      }

      if (isCateringOrder) {
        logger.info('Google Pay not available on catering orders');
        return;
      }

      try {
        const { result, error } = await canMakePayments({
          networks,
          environment,
        });

        if (error) {
          throw error;
        }

        if (!result || !(result.available && result.hasCards)) {
          logger.error({
            message: 'Google Pay CanMakePayments Failed',
            error,
          });
          return;
        }
      } catch (error) {
        logger.error({
          message: 'Google Pay CanMakePayments Failed',
          error,
        });
        return;
      }

      const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
      const { gateway, gatewayMerchantId } = getGooglePaymentsConfigs({
        transactionType: TransactionType.PAYMENT,
        countryCode,
        googleConfig,
        migrationEnabled,
      });
      const payeeName = fullBrandName();
      const itemLabel = formatMessage({ id: 'reloadCard' });

      try {
        const merchantOrderId = rbiOrderId.slice(0, 20);
        const merchantTransactionId = merchantOrderId;
        const merchantReportGroup = `${env()}-${brand()}-${locale.region}`.toUpperCase();

        const paymentRequestResp: IExpoGooglePayResult = await makeWorldPayPaymentRequest({
          gateway,
          gatewayMerchantId,
          countryCode,
          currencyCode,
          total: (total / 100).toString(),
          itemLabel,
          payeeName,
          merchantOrderId,
          merchantTransactionId,
          merchantReportGroup,
        });

        if (paymentRequestResp?.error) {
          throw paymentRequestResp?.error;
        }

        if (!paymentRequestResp.result || !paymentRequestResp.result.paymentMethodData) {
          return;
        }

        const { paymentMethodData } = paymentRequestResp.result;

        const {
          tokenizationData: { token: lowValueToken },
          info: { billingAddress: billingInformation },
        } = paymentMethodData;

        return {
          lowValueToken,
          fullName: billingInformation.name,
        };
      } catch (error) {
        logger.error({
          message: 'Device Google Payment Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [
      enableGooglePay,
      formatMessage,
      googleConfig,
      googlePayPluginAvailable,
      isCateringOrder,
      locale.region,
      rbiOrderId,
    ]
  );

  const requestGooglePayPayment = useCallback(
    async ({
      total,
      billingCountry,
      migrationEnabled,
      orderInformation,
    }: IRequestGooglePayPayment): Promise<IOrder | undefined> => {
      try {
        const googlePayDetails = await requestGooglePay({
          total,
          billingCountry,
          migrationEnabled,
        });

        if (!googlePayDetails) {
          throw new Error('Google Pay details not available');
        }

        const { lowValueToken, fullName } = googlePayDetails;

        // Use lowValueToken to commit order as one-time payment
        return handleCommitOrder({
          cardBrand: CartPaymentCardType.GOOGLE_PAY,
          orderInformation,
          payment: {
            worldpayInput: {
              lowValueToken,
              storePaymentMethod: false,
            },
            fullName,
          },
        });
      } catch (error) {
        logger.error({
          message: 'Device Google Payment Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [handleCommitOrder, requestGooglePay]
  );

  return { requestGooglePayPayment };
}
