/*
 *
 * We broke combo pricing due to launching fountain drinks and a PLU misconfiguration.
 * However we did not have any alerting to catch these types of issues.

 * This component implements a price mismatch log that logs an error when the cart total
 * is different from the subTotal returned from the price response in the final checkout flow of the app.
 *
 * @see https://rbictg.atlassian.net/browse/FUC-155
 */
import { MutableRefObject, memo, useRef } from 'react';
import { Platform } from 'react-native';

import { IServerOrder } from '@rbi-ctg/menu';
import { IStore } from '@rbi-ctg/store';
import { useOrderContext } from 'state/order';
import { env } from 'utils/environment';
import WebLocalStorage, { StorageKeys } from 'utils/local-storage';

const ddlog = Platform.select({
  native: () => require('expo-datadog').DdLogs,
  web: () => require('@datadog/browser-logs').datadogLogs.logger,
})!;

const getStoreIdFromStorage = () => {
  const state = WebLocalStorage.getItem(StorageKeys.STORE) as { store: IStore } | null;
  return state?.store.storeId;
};

const TempCartPriceMismatchLoggerComponent = memo(function TempCartPriceMismatchLoggerComponent({
  serverOrderTotal,
  serverOrderId,
  calculatedCartTotals,
  cartEntriesRef,
}: {
  serverOrderTotal: number | null;
  serverOrderId: string | null;
  calculatedCartTotals: number | null;
  cartEntriesRef: MutableRefObject<unknown>;
}) {
  if (!serverOrderId) {
    return null;
  }

  if (serverOrderTotal !== calculatedCartTotals) {
    ddlog().error('FrontendCartPriceMismatchError', {
      userId: WebLocalStorage.getItem(StorageKeys.USER_AUTH_TOKEN),
      orderId: serverOrderId,
      storeId: getStoreIdFromStorage(),
      pricedSubtotal: serverOrderTotal,
      cartSubtotal: calculatedCartTotals,
      cartEntries: cartEntriesRef.current,
      stage: env(),
    });
  }

  return null;
});

export function TempCartPriceMismatchLogger(props: { serverOrder: IServerOrder | null }) {
  const calculatedCartTotals = useOrderContext().calculateCartTotal();

  // Wrapping a cart entry in a ref to avoid re-rendering this component when the cart changes.
  const cartEntries = useOrderContext().cartEntries;
  const cartEntriesRef = useRef(cartEntries);
  cartEntriesRef.current = cartEntries;

  return (
    <TempCartPriceMismatchLoggerComponent
      calculatedCartTotals={calculatedCartTotals}
      serverOrderTotal={props.serverOrder?.cart.totalCents ?? null}
      serverOrderId={props.serverOrder?.rbiOrderId ?? null}
      cartEntriesRef={cartEntriesRef}
    />
  );
}
