import { Box, Divider } from '@fhs-legacy/universal-components';

export const StyledPaymentMethodDropdown = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderWidth: 1,
  borderColor: Styles.color.grey.four,
  borderRadius: Styles.borderRadius,
});

export const StyledPaymentMethodOptionDivider = Divider.withConfig({
  thickness: '1',
  margin: 0,
});

export const StyledPaymentMethodOptionWrapper = Box.withConfig({
  backgroundColor: 'transparent',
});

export const StyledToggleWrapper = Box.withConfig({
  position: 'absolute',
  top: '$4',
  right: '$2',
});
