import { router } from 'expo-router';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ModalContent, ModalHeading } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import { AddCreditCardFormInputs } from 'components/payments/components';
import { useAddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/hooks/use-add-credit-card-form-inputs';
import {
  getCreditCardErrorMessage,
  getCreditCardFormErrors,
  initialCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/utils';
import { PaymentError } from 'components/payments/integrations/hooks/types';
import { SafetechEncryption } from 'components/payments/integrations/orbital/components/encryption';
import {
  IEncryptionError,
  IEncryptionResult,
} from 'components/payments/integrations/orbital/components/encryption/types';
import { VisuallyHidden } from 'components/ucl';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import useErrorModal from 'hooks/use-error-modal';
import { useToast } from 'hooks/use-toast';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import logger from 'utils/logger';

import { useOrbitalAddPaymentMethod } from './hooks/use-orbital-add-payment-method';
import { buildMutationInput } from './utils';

export const OrbitalAddCreditCardModal: React.FC<React.PropsWithChildren> = () => {
  const { formatMessage } = useIntl();
  const { feCountryCode: billingCountry } = useLocale();
  const toast = useToast();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isEncryptionActive, setIsEncryptionActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const {
    creditCardFormValues,
    creditCardFormErrors,
    handleCreditCardFormChanges,
    setCreditCardFormErrors,
    setCreditCardFormValues,
  } = useAddCreditCardFormInputs({ paymentFieldVariations, billingCountry });

  const { addPaymentMethod } = useOrbitalAddPaymentMethod();

  // @ts-ignore - Union type error
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Credit Card details',
  });

  // Handle add credit card form submission
  const encryptCard = useCallback(
    async (event: any) => {
      if (event) {
        event.preventDefault();
      }
      const { hasErrors, formErrors } = getCreditCardFormErrors(
        creditCardFormValues,
        creditCardFormErrors,
        formatMessage,
        paymentFieldVariations,
        billingCountry
      );

      if (hasErrors) {
        const newErrorMessage = getCreditCardErrorMessage({
          errors: creditCardFormErrors,
          formatMessage,
        });
        setErrorMessage(newErrorMessage);

        return setCreditCardFormErrors(prevState => ({ ...prevState, ...formErrors }));
      }

      setIsLoading(true);

      // When encryption completed, will execute onEncryptionResult
      setIsEncryptionActive(true);
    },
    [
      billingCountry,
      creditCardFormErrors,
      creditCardFormValues,
      formatMessage,
      paymentFieldVariations,
      setCreditCardFormErrors,
    ]
  );

  // Handle mutation success case
  // Called after card is successfully vaulted
  const onAddCardSuccess = () => {
    setCreditCardFormValues(initialCreditCardFormValues({ isDelivery: false }));
    router.back();
    setIsLoading(false);
    toast.show({
      text: formatMessage({ id: 'success' }),
      variant: 'positive',
    });
  };

  // Handle mutation failure case
  const onAddCardFailure = (paymentMethodError: Error, _message: string) => {
    // @ts-ignore - Union type error
    openErrorDialog({
      message: formatMessage({ id: 'paymentAddingError' }),
      error: paymentMethodError,
    });
    setIsLoading(false);
  };

  // Handle encryption result and fire off add payment method request
  const onEncryptionResult = async (encryptionData: IEncryptionResult) => {
    setIsEncryptionActive(false);
    try {
      const input = buildMutationInput(
        creditCardFormValues,
        encryptionData,
        paymentFieldVariations,
        billingCountry
      );

      await addPaymentMethod({
        input,
        options: {
          skipErrorDialogOnError: false,
        },
      });

      onAddCardSuccess();
    } catch (error) {
      setIsLoading(false);

      logger.error({ error, message: 'Error adding payment method' });

      if (error instanceof PaymentError) {
        // @ts-ignore - Union type error
        return openErrorDialog({
          message: error.message,
          modalAppearanceEventMessage: formatMessage({ id: error.failureMessageKey }),
          error,
        });
      }

      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      onAddCardFailure(error, 'Error adding payment method');
    }
  };

  // Handle encryption error
  const onEncryptionError = (_error: IEncryptionError) => {
    onAddCardFailure(new Error('Encryption failed'), 'Error adding payment method');
    setIsEncryptionActive(false);
  };

  const modalHeading = formatMessage({ id: 'addPaymentMethod' });
  const modalButtonText = `${formatMessage({ id: 'save' })} & ${formatMessage({ id: 'continue' })}`;

  return (
    <Modal
      onDismiss={router.back}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Add payment method',
      }}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; onDismiss: () => void; ... Remove this comment to see the full error message
      allowModalResizeWithKeyboard
      header={
        <Box marginTop="$1">
          <ModalHeading marginBottom="$0" testID="add-payment-modal-heading">
            <Header marginBottom="$0" marginX="auto" variant="headerOne">
              {modalHeading}
            </Header>
          </ModalHeading>
        </Box>
      }
    >
      <KeyboardAwareView withScroll>
        <ModalContent backgroundColor={Styles.color.background} py="$0">
          <Box>
            {!!errorMessage && (
              <VisuallyHidden role="alert" accessibilityLabel="">
                <Text>{errorMessage}</Text>
              </VisuallyHidden>
            )}
            <AddCreditCardFormInputs
              onChange={handleCreditCardFormChanges}
              errors={creditCardFormErrors}
              values={creditCardFormValues}
              isDelivery={false}
            />
            <Box marginY="$4" marginX="$0">
              <ActionButton
                fullWidth
                testID="save-and-continue"
                isLoading={isLoading}
                disabled={!creditCardFormValues.saveCard}
                onPress={encryptCard}
              >
                {modalButtonText}
              </ActionButton>
            </Box>
            {isEncryptionActive && (
              <SafetechEncryption
                cardNumber={creditCardFormValues.cardNumber}
                cvv={creditCardFormValues.cvv || ''}
                onResult={onEncryptionResult}
                onError={onEncryptionError}
              />
            )}
            <ErrorDialog />
          </Box>
        </ModalContent>
      </KeyboardAwareView>
    </Modal>
  );
};

export default OrbitalAddCreditCardModal;
