import { useEffect, useState } from 'react';

import {
  _addItemToCart,
  _priceAndCommit,
  _removeItemFromCart,
  _setDonationAmount,
} from '../backend/graphql';
import { cart } from '../backend/tmp-cart';
import { CartModel } from '../types';

export const GetCart = `query {
  cart {
    entries
  }
}`;

/*
 * represents a graphql query:
 *
 * query GetCart() {
 *   cart: Cart
 * }
 */
export function useQuery(_query: typeof GetCart) {
  const [state, setState] = useState({
    fetching: true,
    error: null,
    data: cart.getState(),
  });

  useEffect(() => {
    cart.subscribe(state => setState({ fetching: false, error: null, data: state }));
  }, []);

  return state;
}

export const AddItemToCart = `mutation AddItemToCart($itemId: ID!) {
  addItemToCart(itemId: $itemId) {
  }
}`;

export const RemoveItemFromCart = `mutation RemoveItemFromCart($lineId: ID!) {
  removeItemFromCart(lineId: $lineId) {
  }
}`;

export const SetDonationAmount = `mutation SetDonationAmount($donationAmount: Int!) {
  setDonationAmount(lineId: $lineId) {
  }
}`;

export const PriceOrder = `mutation PriceOrder() {
  priceOrder() {
    cart
  }
}`;

type State<T> = { data: null | T; fetching: boolean; error: null | Error };
export function useMutation<T extends CartModel, V extends Record<string, any>>(
  mutation:
    | typeof AddItemToCart
    | typeof RemoveItemFromCart
    | typeof SetDonationAmount
    | typeof PriceOrder,
  variables?: V
): [State<T>, (vars?: V) => Promise<T>] {
  const [state, setState] = useState<State<T>>({
    data: null,
    fetching: false,
    error: null,
  });

  return [
    state,
    async (vars?: Record<string, any>) => {
      let data;
      switch (mutation) {
        case AddItemToCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _addItemToCart(vars?.itemId ?? variables?.itemId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            setState({ fetching: false, error, data: null });
          }
          return data;

        case RemoveItemFromCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _removeItemFromCart(vars?.lineId ?? variables?.lineId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            setState({ fetching: false, error, data: null });
          }
          return data;

        case SetDonationAmount:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _setDonationAmount(vars?.donationAmount ?? variables?.donationAmount);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            setState({ fetching: false, error, data: null });
          }
          return data;

        case PriceOrder:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _priceAndCommit();
            setState({ fetching: false, error: null, data });
          } catch (error) {
            setState({ fetching: false, error, data: null });
          }
          return data;
      }
    },
  ];
}
