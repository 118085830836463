import { useCallback } from 'react';

import { useCommitOrder } from 'components/payments/integrations/hooks/use-commit-order';
import { CardBrand, IOrder } from 'generated/graphql-gateway';
import { IOrderInformation } from 'pages/cart/payments/types';
import { useAuthContext } from 'state/auth';

interface IRequestFirstDataVaultedPayment {
  accountIdentifier: string;
  cardBrand: CardBrand;
  orderInformation: IOrderInformation;
}

export const useFirstDataVaultedPayment = (rbiOrderId: string) => {
  const { user } = useAuthContext();
  const { handleCommitOrder } = useCommitOrder(rbiOrderId);

  const requestVaultedPayment = useCallback(
    async ({
      accountIdentifier,
      cardBrand,
      orderInformation,
    }: IRequestFirstDataVaultedPayment): Promise<IOrder | undefined> => {
      // Build the payment input for a vaulted payment method
      const firstDataPaymentInput = {
        firstDataInput: {
          accountIdentifier,
        },
        fullName: user?.details.name ?? '',
      };

      // Handle committing the order
      return handleCommitOrder({
        cardBrand,
        orderInformation,
        payment: firstDataPaymentInput,
      });
    },
    [handleCommitOrder, user?.details.name]
  );

  return {
    requestVaultedPayment,
  };
};
