import { useCallback, useMemo } from 'react';

import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { transformPaymentMethodValues } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal/utils';
import { IReloadPrepaidInput, useReloadPrepaidMutation } from 'generated/graphql-gateway';
import { useGetEncryptionDetailsMutation } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { IAuthorizedOneTimePaymentMethod } from 'pages/cart/payments/types';
import { getNonce } from 'remote/api/first-data';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';

export const useFirstDataOneTimePrepaidReload = () => {
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);

  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const fdUrl = useMemo(() => urlsConfig.firstData, [urlsConfig.firstData]);

  const [encryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [reloadPrepaidMutation] = useReloadPrepaidMutation();

  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';

  const getAuthorizedOneTimePaymentMethod = useCallback(
    async (
      paymentMethodValues: IAddCreditCardFormValues
    ): Promise<IAuthorizedOneTimePaymentMethod> => {
      const formattedCreditCardFormValues = transformPaymentMethodValues({
        paymentMethodValues,
        paymentFieldVariations,
      });

      try {
        const encryptionDetailsResponse = await encryptionDetailsMutation();
        const encryptionDetails = encryptionDetailsResponse?.data?.encryptionDetails;

        if (!encryptionDetails) {
          throw new Error('Missing encryption details');
        }

        const { fdPublicKey, fdApiKey, fdAccessToken, fdCustomerId, algorithm } = encryptionDetails;

        const nonceResponse = await getNonce(
          formattedCreditCardFormValues,
          fdPublicKey,
          fdApiKey,
          fdAccessToken,
          fdCustomerId || null,
          fdUrl,
          onlySendPostalCode,
          algorithm || undefined
        );

        const { token } = await nonceResponse.json();
        return {
          accessToken: fdAccessToken,
          token: token.tokenId,
        };
      } catch (error) {
        // TODO: Payments Refactor - Properly handle error dialogues across operations to avoid redundant error handling
        // openErrorDialog({
        //   message: formatMessage({ id: 'paymentAddingError' }),
        //   modalAppearanceEventMessage: 'Error: Fetching Encryption Details Failure',
        //   // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        //   error,
        // });
        throw new Error('Missing encryption details');
      }
    },
    [fdUrl, encryptionDetailsMutation, onlySendPostalCode, paymentFieldVariations]
  );

  const buildReloadPrepaidInput = ({
    billingCountry,
    authorizationDetails,
    reloadInformation,
  }: {
    billingCountry: ISOs;
    authorizationDetails: IAuthorizedOneTimePaymentMethod;
    reloadInformation: {
      reloadAmount: number;
      destinationPaymentMethodId: string;
    };
  }): IReloadPrepaidInput => {
    const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
    const { reloadAmount, destinationPaymentMethodId } = reloadInformation;
    const { token, accessToken } = authorizationDetails;
    const input = {
      amount: reloadAmount,
      currency: currencyCode,
      destinationPaymentMethodId,
      billingAddress: {
        regionCode: ISOsToRegions[billingCountry],
      },
      paymentMethod: JSON.stringify({
        type: 'scheme',
        subtype: 'onetime',
        token,
        accessToken,
      }),
      riskData: JSON.stringify({
        cookie: forterToken,
        mobileId: forterDeviceId,
      }),
    };

    return input;
  };

  const requestOneTimePrepaidReload = async ({
    billingCountry,
    creditCardFormValues,
    reloadInformation,
  }: {
    billingCountry: ISOs;
    creditCardFormValues: IAddCreditCardFormValues;
    reloadInformation: {
      reloadAmount: number;
      destinationPaymentMethodId: string;
    };
  }) => {
    const authorizationDetails = await getAuthorizedOneTimePaymentMethod(creditCardFormValues);

    const input = buildReloadPrepaidInput({
      billingCountry,
      authorizationDetails,
      reloadInformation,
    });

    const reloadPrepaidResult = await reloadPrepaidMutation({ variables: { input } });
    return reloadPrepaidResult?.data || undefined;
  };

  return {
    requestOneTimePrepaidReload,
  };
};
