import { PaymentIntegrationType } from 'components/payments/entry-points/types';
import { FirstDataTransferGiftCard } from 'components/payments/integrations/firstdata/firstdata-gift-card';

type TransferGiftCardIntegrationMapping = Partial<
  Record<(typeof PaymentIntegrationType)[keyof typeof PaymentIntegrationType], () => JSX.Element>
>;

export const transferGiftCardIntegrationMapping: TransferGiftCardIntegrationMapping = {
  [PaymentIntegrationType.FIRSTDATA]: FirstDataTransferGiftCard,
};
