import { FC } from 'react';

import FirstDataPaymentMethods from 'components/payments/integrations/firstdata/firstdata-payment-methods';
import OrbitalPaymentMethods from 'components/payments/integrations/orbital/orbital-payment-methods';
import WorldpayPaymentMethods from 'components/payments/integrations/worldpay/worldpay-payment-methods';

import { PaymentIntegrationType } from '../types';

type PaymentMethodIntegrationMapping = Record<string, FC>;

export const paymentMethodIntegrationMapping: PaymentMethodIntegrationMapping = {
  [PaymentIntegrationType.FIRSTDATA]: FirstDataPaymentMethods,
  [PaymentIntegrationType.ORBITAL]: OrbitalPaymentMethods,
  [PaymentIntegrationType.WORLDPAY]: WorldpayPaymentMethods,
};
