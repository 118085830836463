import React, {
  FC,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  RefObject,
} from 'react';

import { BlankScreen } from 'components/blank-screen';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { PaymentIntegrationType } from 'components/payments/entry-points/types';
import { FirstDataPaymentCollector } from 'components/payments/integrations/firstdata/firstdata-payment-collector';
import { OrbitalPaymentCollector } from 'components/payments/integrations/orbital/orbital-payment-collector';
import { WorldpayPaymentCollector } from 'components/payments/integrations/worldpay/worldpay-payment-collector';
import { FrontendIntegration } from 'generated/graphql-gateway';
import { IPaymentCollectorProps, IPaymentCollectorRef } from 'pages/cart/payments/types';

type PaymentIntegrationMapping = Record<
  string,
  ForwardRefExoticComponent<
    PropsWithoutRef<IPaymentCollectorProps> & RefAttributes<IPaymentCollectorRef>
  >
>;
const paymentIntegrationMapping: PaymentIntegrationMapping = {
  [PaymentIntegrationType.FIRSTDATA]: FirstDataPaymentCollector,
  [PaymentIntegrationType.ORBITAL]: OrbitalPaymentCollector,
  [PaymentIntegrationType.WORLDPAY]: WorldpayPaymentCollector,
};

interface IPaymentEntryPointProps {
  hidePaymentMethod?: boolean;
  isProcessingPlaceOrder?: boolean;
  loading: boolean;
  paymentCollectorRef: RefObject<IPaymentCollectorRef>;
  paymentIntegration: FrontendIntegration | undefined;
}

export const PaymentEntryPoint: FC<IPaymentEntryPointProps> = ({
  hidePaymentMethod,
  isProcessingPlaceOrder = false,
  loading,
  paymentCollectorRef,
  paymentIntegration,
}) => {
  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  if (!paymentIntegration) {
    return <BlankScreen context={{ reason: 'paymentIntegration is not defined' }} />;
  }

  // Determine which payment integration to display for collecting a payment in checkout.
  const PaymentCollectorIntegration = paymentIntegrationMapping[paymentIntegration];

  return (
    <PaymentCollectorIntegration
      ref={paymentCollectorRef}
      hidePaymentMethod={hidePaymentMethod}
      isProcessingPlaceOrder={isProcessingPlaceOrder}
    />
  );
};
