interface ICalculateReloadAmount {
  totalCents: number;
  currentPrepaidBalance: number;
  defaultReloadAmt: number;
}

const ROUND_TO_NEAREST_CENTS = 500;

export const calculateReloadAmount = ({
  totalCents,
  currentPrepaidBalance,
  defaultReloadAmt,
}: ICalculateReloadAmount) => {
  let amountToReload = 0;

  if (totalCents > currentPrepaidBalance) {
    const minimumReloadAmount = totalCents - currentPrepaidBalance;

    if (!currentPrepaidBalance || minimumReloadAmount > defaultReloadAmt) {
      amountToReload =
        Math.ceil(minimumReloadAmount / ROUND_TO_NEAREST_CENTS) * ROUND_TO_NEAREST_CENTS;
    } else {
      amountToReload = defaultReloadAmt;
    }
  }
  return amountToReload;
};
