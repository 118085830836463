import { Spinner } from '@fhs-legacy/universal-components';
import { BlankScreen } from 'components/blank-screen';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useUserPaymentInformationQuery } from 'generated/graphql-gateway';

import { transferGiftCardIntegrationMapping } from './types';

export const TransferGiftCardEntryPoint = (): null | JSX.Element => {
  const { data, loading } = useUserPaymentInformationQuery();
  const paymentIntegration = data?.paymentConfig.frontendIntegration;

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  if (!paymentIntegration) {
    return <BlankScreen context={{ reason: 'paymentIntegration is null' }} />;
  }

  // Determine which payment integration to display for handling a gift card purchase.
  const TransferGiftCardEntryPointIntegration =
    transferGiftCardIntegrationMapping[paymentIntegration];

  return <TransferGiftCardEntryPointIntegration />;
};
