import { capitalize } from 'lodash';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { HStack, Header, VStack } from '@fhs-legacy/universal-components';
import { ICartEntry } from '@rbi-ctg/menu';
import Currency from 'components/currency';
import { usePrevious } from 'hooks/use-previous';
import { selectors, useAppSelector } from 'state/global-state';
import { IncentiveEligibleItem } from 'state/loyalty/hooks/types';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { theme } from 'styles/configure-theme';
import { getItemLoyaltyRewardDiscount, getItemOfferDiscount } from 'utils/cart/helper';
import { delayUnmountHOC } from 'utils/delay-unmount-hoc';
import priceForCartEntry from 'utils/menu/price-for-cart-entry';

import { IPrice } from '../types';

import { LoyaltyIcon } from './loyalty-icon';

const DelayedUnmountPrice = delayUnmountHOC(Currency);

const RewardsFreeText = Header.withConfig({
  variant: 'headerThree',
  color: theme.token('text-loyalty-highlight'),
  margin: 0,
  marginLeft: 2,
});

const PriceFree = () => {
  const { formatMessage } = useIntl();

  return (
    <HStack alignItems="center">
      <LoyaltyIcon />
      <RewardsFreeText>{capitalize(formatMessage({ id: 'free' }))}</RewardsFreeText>
    </HStack>
  );
};

const RewardDiscountPrice = ({
  amount,
  rewardApplied,
  item,
}: {
  amount: number;
  rewardApplied: boolean;
  item: ICartEntry;
}) => {
  const { formatMessage } = useIntl();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const discountPrice =
    amount - getItemLoyaltyRewardDiscount({ cartEntry: item, appliedLoyaltyRewards });
  const prevDiscountPrice = usePrevious(discountPrice);
  const discountDisplay = rewardApplied ? discountPrice : prevDiscountPrice;
  if (rewardApplied && discountPrice === 0) {
    return <PriceFree />;
  }

  const afterDiscountLabel = `${discountDisplay} ${formatMessage({ id: 'afterDiscount' })}`;

  return (
    <DelayedUnmountPrice
      delayTime={300}
      isVisible={rewardApplied}
      amount={discountDisplay}
      textProps={{
        accessibilityLabel: afterDiscountLabel,
      }}
    />
  );
};

const OfferDiscountPrice = ({
  amount,
  item,
  eligibleItem,
}: {
  amount: number;
  item: ICartEntry;
  eligibleItem: IncentiveEligibleItem;
}) => {
  const { formatMessage } = useIntl();
  const selectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);

  const getDiscountPrice = React.useCallback(() => {
    if (!selectedOffer) {
      return 0;
    }

    return (
      amount -
      getItemOfferDiscount({
        cartEntry: item,
        offer: selectedOffer,
        timesApplied: eligibleItem.timesApplied,
      })
    );
  }, [selectedOffer, item, amount, eligibleItem.timesApplied]);

  const discountPrice = useMemo(() => getDiscountPrice(), [getDiscountPrice]);
  const afterDiscountLabel = `${discountPrice} ${formatMessage({ id: 'afterDiscount' })}`;

  return (
    <Currency
      amount={discountPrice}
      textProps={{
        accessibilityLabel: afterDiscountLabel,
      }}
    />
  );
};

const Price: FC<React.PropsWithChildren<IPrice>> = ({ item, isOffer, rewardApplied }) => {
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const price = priceForCartEntry(item);
  const isRewardApplied = Boolean(rewardApplied && !isOffer);
  const offerEligibleItem = useAppSelector(state =>
    selectors.loyalty.selectOfferEligibleItem(state, item.cartId)
  );
  const isOfferEligibleItem = !!offerEligibleItem;
  const shouldStrikethroughPrice = isRewardApplied || isOfferEligibleItem;
  const priceLabel = `${price} ${
    shouldStrikethroughPrice && formatMessage({ id: 'beforeDiscount' })
  }`;

  return (
    <VStack alignItems="flex-end">
      <Currency
        amount={price}
        isBold
        textProps={{
          color: shouldStrikethroughPrice
            ? theme.token('text-disabled')
            : theme.token('text-default'),
          strikeThrough: shouldStrikethroughPrice,
          margin: 0,
          fontSize: 'lg',
          accessibilityLabel: priceLabel,
        }}
      />
      {loyaltyEnabled && (
        <RewardDiscountPrice amount={price} rewardApplied={isRewardApplied} item={item} />
      )}
      {isOfferEligibleItem && (
        <OfferDiscountPrice amount={price} item={item} eligibleItem={offerEligibleItem} />
      )}
    </VStack>
  );
};

export default Price;
