import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Box, HStack, Icon, IconButton, Text } from '@fhs-legacy/universal-components';
import { UnverifiedMethod } from 'components/payment-method-option/unverified-method';
import {
  DeletePaymentMethodHandler,
  PaymentMethod,
} from 'components/payments/integrations/hooks/types';
import { isCreditCardMethod } from 'components/payments/integrations/hooks/utils';
import RadioIndicator from 'components/radio-indicator';
import useDialogModal from 'hooks/use-dialog-modal';
import { theme } from 'styles/configure-theme';

import { ARIA_LABELED_BY_DEFAULT, paymentMethodOptionMap } from './constants';
import {
  StyledMethodContainer,
  StyledMethodTypeInnerWrapper,
  StyledMethodTypeWrapper,
} from './styled';

interface IPaymentMethodOptionProps {
  ariaLabelledby?: string;
  disableDelete?: boolean;
  disableHover?: boolean;
  disableMethod?: boolean;
  fullWidthDescription?: boolean;
  hideBorder?: boolean;
  isDefaultPaymentMethod?: boolean;
  paymentMethod: PaymentMethod;
  onPress?: (paymentMethod: PaymentMethod) => void;
  deletePaymentMethod?: DeletePaymentMethodHandler;
  readonly?: boolean;
  revaultRequired?: boolean;
  selected?: boolean;
  showDefault?: boolean;
}

export const PaymentMethodOption: React.FC<IPaymentMethodOptionProps> = ({
  ariaLabelledby = ARIA_LABELED_BY_DEFAULT,
  disableDelete = false,
  disableHover = false,
  disableMethod = false,
  fullWidthDescription = true,
  hideBorder = false,
  isDefaultPaymentMethod = false,
  paymentMethod,
  onPress,
  deletePaymentMethod,
  revaultRequired,
  selected,
  showDefault,
}) => {
  const { formatMessage } = useIntl();

  const handleOnPress = useCallback(() => {
    if (disableMethod || !onPress) {
      return;
    }
    onPress(paymentMethod);
  }, [disableMethod, onPress, paymentMethod]);

  const handleOnDelete = useCallback(async () => {
    if (disableDelete || !deletePaymentMethod) {
      return;
    }
    await deletePaymentMethod({ storedPaymentMethodId: paymentMethod.id });
  }, [disableDelete, deletePaymentMethod, paymentMethod]);

  const [DeletionConfirmationDialog, openDeletionConfirmationDialog] = useDialogModal({
    onConfirm: handleOnDelete,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Delete payment method',
  });

  const openPaymentDeletionConfirmation = useCallback(() => {
    return openDeletionConfirmationDialog();
  }, [openDeletionConfirmationDialog]);

  // If payment method is a credit card, get the last 4 digits of the card to display for reference
  let paymentMethodAlias = '';
  if (isCreditCardMethod(paymentMethod)) {
    paymentMethodAlias = paymentMethod.last4;
  }

  // Determine which payment method option type to display
  const PaymentMethodOptionType = paymentMethodOptionMap[paymentMethod.type];

  return (
    <>
      <StyledMethodContainer
        disableHover={disableHover}
        disableMethod={disableMethod}
        hideBorder={hideBorder}
        aria-labelledby={ariaLabelledby}
        testID={`payment-method-${paymentMethod.id}`}
      >
        <StyledMethodTypeWrapper
          data-private
          onPress={handleOnPress}
          disableMethod={disableMethod}
          testID={`method-type-wrapper-${paymentMethod.id}`}
        >
          <StyledMethodTypeInnerWrapper space={2} testID="payment-method-types">
            <PaymentMethodOptionType
              method={paymentMethod}
              disableMethod={disableMethod}
              fullWidthDescription={fullWidthDescription}
              onPress={handleOnPress}
            />
            {disableMethod && (
              <HStack alignItems="center">
                <Icon variant="error" size="$5" color={theme.token('text-error')} mr="$1" ml="$2" />
                <Text color={theme.token('text-error')} variant="copyTwo">
                  {formatMessage({ id: 'paymentMethodNotAvailable' })}
                </Text>
              </HStack>
            )}
          </StyledMethodTypeInnerWrapper>
          {/* TODO: Payments Refactor - handle revaulting payment method */}
          {revaultRequired && <UnverifiedMethod onPress={() => {}} />}
          {showDefault && (
            <RadioIndicator
              accessibilityLabel={paymentMethodAlias}
              isSelected={selected || isDefaultPaymentMethod}
              isDisabled={disableMethod}
            />
          )}
        </StyledMethodTypeWrapper>
        <Box width="$10" ml="$2">
          {!disableDelete && (
            <IconButton
              variant="ghost"
              testID="remove-card-button"
              accessibilityLabel={formatMessage({ id: 'removePaymentMethod' })}
              // TODO: Payments Refactor - handle deleting a payment method and add delete confirmation dialog in upcoming ticket
              onPress={openPaymentDeletionConfirmation}
              icon={<Icon variant="remove" size="$7" />}
              _pressed={{
                _icon: {
                  style: {
                    transform: [{ scale: 1.05 }],
                  },
                },
              }}
            />
          )}
        </Box>
      </StyledMethodContainer>
      <DeletionConfirmationDialog
        testID="delete-confirmation-dialog"
        heading={formatMessage({ id: 'deletePaymentMethod' })}
        body={formatMessage({ id: 'confirmDeletePaymentMethod' })}
        modalAppearanceEventMessage="Confirmation: Delete Payment Method"
      />
    </>
  );
};
