import { router } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ModalContent, ModalHeading } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import GiftCardImage from 'components/payments/components/gift-card-image/gift-card-image';
import { CARD_ALIAS_TRANSLATION_ID } from 'components/payments/integrations/hooks/constants';
import { useUIContext } from 'state/ui';

import { useFirstDataSuccessTransferGiftCard } from './hooks/use-first-data-success-tranfer-gift-card';
import { CardBalanceText, CurrentBalanceDescription, CurrentBalanceText, ModalHr } from './styled';

export const SuccessTransferGiftCardModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForUser } = useUIContext();

  const {
    transferCard,
    isLoading,
    ErrorDialog,
    handleDismiss,
    transferCardBalance,
    cardNumber,
    currentBalance,
  } = useFirstDataSuccessTransferGiftCard();

  const formattedCurrecyBalance = formatCurrencyForUser(currentBalance);
  const formattedTransferCardBalance = formatCurrencyForUser(transferCardBalance);
  const formatCardNumber = cardNumber?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ');

  const cardAlias = formatMessage({ id: CARD_ALIAS_TRANSLATION_ID });
  const isTransferCardBalanceValid = transferCardBalance && transferCardBalance > 0;
  const { actionDetails, actionHandler, actionText } = isTransferCardBalanceValid
    ? {
        actionDetails: formatMessage(
          { id: 'wouldYouLikeToAddToPrepaidCard' },
          { amount: formattedTransferCardBalance, cardAlias }
        ),
        actionText: formatMessage({ id: 'yesTransfer' }, { amount: formattedTransferCardBalance }),
        actionHandler: transferCard,
      }
    : {
        actionDetails: formatMessage({ id: 'wouldYouLikeToAddADifferentCard' }, { cardAlias }),
        actionText: formatMessage({ id: 'yesAddNewCard' }, { cardAlias }),
        actionHandler: () => {
          //router.navigate(-1) go back to transfer-gift-card route
          router.back();
        },
      };
  return (
    <Modal
      onDismiss={handleDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Success transfer gift card',
      }}
    >
      <ModalContent>
        <GiftCardImage />
        <ModalHeading testID="modal-heading">
          {formatMessage(
            { id: 'prepaidCardBalance' },
            { cardAlias: formatMessage({ id: CARD_ALIAS_TRANSLATION_ID }) }
          )}
        </ModalHeading>
        <CardBalanceText testID="card-balance">
          {formatMessage(
            {
              id: 'theCardBalanceIs',
            },
            {
              cardNumber: (
                <Text fontWeight="bold">
                  {formatCardNumber}
                  {'\n'}
                </Text>
              ),
            }
          )}
        </CardBalanceText>
        <CurrentBalanceText testID="transfer-balance">
          {formattedTransferCardBalance}
        </CurrentBalanceText>
        <ModalHr />
        <CurrentBalanceDescription>
          {formatMessage({ id: 'yourBalanceIs' }, { currentBalance: formattedCurrecyBalance })}
        </CurrentBalanceDescription>
        <CardBalanceText>{actionDetails}</CardBalanceText>
        <Box marginTop="$5" marginX="auto" marginBottom="$0" maxWidth="400px" width="100%">
          <ActionButton
            fullWidth
            testID="confirm-now"
            onPress={actionHandler}
            isLoading={isLoading}
          >
            {actionText}
          </ActionButton>
        </Box>
      </ModalContent>

      <ErrorDialog />
    </Modal>
  );
};

export default SuccessTransferGiftCardModal;
