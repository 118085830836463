import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { canMakePayments, isSupported, makePaymentRequest } from '@rbilabs/expo-googlepay';
import {
  CartPaymentCardType,
  IGooglePay,
  IOrder,
  IReloadPrepaidInput,
  IReloadPrepaidMutation,
  Platform,
  useReloadPrepaidMutation,
} from 'generated/graphql-gateway';
import { CountryCode } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import {
  TransactionType,
  getGooglePaymentsConfigs,
} from 'state/google-pay/get-google-payments-configs';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { fullBrandName } from 'utils/environment';
import { sanitizeAlphanumeric } from 'utils/form';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import { buildFormattedBillingAddress } from 'utils/native-actions';
import { parseStringifiedJSON } from 'utils/parse-string';
import { isCatering } from 'utils/service-mode';

import { IRequestGooglePayPayment, IRequestGooglePayReload } from './types';
import { useCommitOrder } from './use-commit-order';

export default function useGooglePay(rbiOrderId: string) {
  const { formatMessage } = useIntl();
  const enableGooglePay = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_PAY);
  const { serviceMode } = useServiceModeContext();
  const isCateringOrder = isCatering(serviceMode);
  const googlePayPluginAvailable = isSupported();
  const googleConfig = useConfigValue({ key: 'google', defaultValue: {} });

  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';

  const { handleCommitOrder } = useCommitOrder(rbiOrderId);

  const [reloadPrepaidMutation] = useReloadPrepaidMutation();

  const requestGooglePay = useCallback(
    async ({
      total,
      billingCountry,
      migrationEnabled,
    }: {
      total: number;
      billingCountry: ISOs;
      migrationEnabled: boolean;
    }): Promise<IGooglePay | undefined> => {
      const googleConfigNetworks = googleConfig.paymentsNetworks;
      const networks = googleConfigNetworks.filter((i: unknown) => !!i);
      const environment = googleConfig.paymentsEnvironment;

      if (!googlePayPluginAvailable) {
        logger.info('Google Pay Plugin is not available');
        return;
      }
      if (!enableGooglePay) {
        logger.info('Google Pay is disabled via LD');
        return;
      }

      if (isCateringOrder) {
        logger.info('Google Pay not available on catering orders');
        return;
      }

      try {
        const { result, error } = await canMakePayments({
          networks,
          environment,
        });

        if (error) {
          throw error;
        }

        if (!result) {
          logger.error({
            message: 'Google Pay CanMakePayments Failed',
            error,
          });
          return;
        }
      } catch (error) {
        logger.error({
          message: 'Google Pay CanMakePayments Failed',
          error,
        });
        return;
      }

      const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
      const { gateway, gatewayMerchantId } = getGooglePaymentsConfigs({
        transactionType: TransactionType.PAYMENT,
        countryCode,
        googleConfig,
        migrationEnabled,
      });
      const payeeName = fullBrandName();
      const itemLabel = formatMessage({ id: 'reloadCard' });

      try {
        const paymentRequestResp = await makePaymentRequest({
          gateway,
          gatewayMerchantId,
          countryCode,
          currencyCode,
          total: (total / 100).toString(),
          itemLabel,
          payeeName,
        });

        if (paymentRequestResp?.error) {
          throw paymentRequestResp?.error;
        }

        if (!paymentRequestResp.result || !paymentRequestResp.result.paymentMethodData) {
          return;
        }

        const {
          tokenizationData: { token },
          info: { billingAddress: billingInformation, cardNetwork: paymentType },
          description: displayName,
        } = paymentRequestResp.result.paymentMethodData;

        const parsedToken = parseStringifiedJSON({ value: token });

        const { signature, protocolVersion, signedMessage: data } = parsedToken;

        const {
          locality,
          postalCode,
          administrativeArea: region,
          address1: streetAddress,
          countryCode: country,
        } = billingInformation;

        const formattedBillingAddress = buildFormattedBillingAddress({
          street: String(streetAddress),
          city: String(locality),
          state: String(region),
          postalCode: String(postalCode),
          country: String(country),
        });
        const billingAddress = {
          locality,
          postalCode: sanitizeAlphanumeric(postalCode),
          region,
          streetAddress,
        };

        const googlePayDetails: IGooglePay = {
          signature,
          country: CountryCode[region],
          data,
          formatted: formattedBillingAddress,
          primary: true,
          type: 'work',
          version: protocolVersion,
          billingAddress,
          paymentData: token,
          PaymentMethodData: {
            paymentType,
            displayName,
          },
        };
        return googlePayDetails;
      } catch (error) {
        logger.error({
          message: 'Device Google Payment Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [enableGooglePay, formatMessage, googleConfig, googlePayPluginAvailable, isCateringOrder]
  );

  const requestGooglePayPayment = useCallback(
    async ({
      total,
      billingCountry,
      migrationEnabled,
      orderInformation,
    }: IRequestGooglePayPayment): Promise<IOrder | undefined> => {
      try {
        const googlePayDetails = await requestGooglePay({
          total,
          billingCountry,
          migrationEnabled,
        });

        return handleCommitOrder({
          cardBrand: CartPaymentCardType.GOOGLE_PAY,
          orderInformation,
          payment: {
            googlePayDetails,
            fullName: '',
          },
        });
      } catch (error) {
        logger.error({
          message: 'Device Google Payment Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [handleCommitOrder, requestGooglePay]
  );

  const requestGooglePayPrepaidReload = useCallback(
    async ({
      billingCountry,
      migrationEnabled,
      reloadInformation,
    }: IRequestGooglePayReload): Promise<IReloadPrepaidMutation | undefined> => {
      try {
        const { reloadAmount, destinationPaymentMethodId } = reloadInformation;
        const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);

        const googlePayDetails = await requestGooglePay({
          total: reloadAmount,
          billingCountry,
          migrationEnabled,
        });

        const input: IReloadPrepaidInput = {
          amount: reloadAmount,
          currency: currencyCode,
          destinationPaymentMethodId,
          billingAddress: {
            regionCode: ISOsToRegions[billingCountry],
            postalCode: googlePayDetails?.billingAddress?.postalCode,
            administrativeArea: googlePayDetails?.billingAddress?.country,
            streetNumber: googlePayDetails?.billingAddress?.streetAddress,
            locality: googlePayDetails?.billingAddress?.locality,
          },
          paymentMethod: JSON.stringify({
            type: 'googlepay',
            token: googlePayDetails?.paymentData,
          }),
          riskData: JSON.stringify({
            cookie: forterToken,
            mobileId: forterDeviceId,
          }),
          platform: Platform.APP,
        };

        const reloadPrepaidResult = await reloadPrepaidMutation({
          variables: {
            input,
          },
        });

        return reloadPrepaidResult.data || undefined;
      } catch (error) {
        logger.error({
          message: 'Device Google Pay Prepaid Reload Failed',
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: error.message,
        });
        return Promise.reject(error);
      }
    },
    [forterDeviceId, forterToken, reloadPrepaidMutation, requestGooglePay]
  );

  return { requestGooglePayPayment, requestGooglePayPrepaidReload };
}
