import { createLogger } from '../debugger';
import { CartModel, ID } from '../types';

import { fetchPriceOrderMutation } from './legacy/fetch-price-order-mutation';
import { transformCartToV1Cart } from './legacy/transform-cart-to-v1-cart';
import { getCart, updateCart } from './update-cart';

const userId = 'foobar';

export async function _priceAndCommit(): Promise<CartModel> {
  const logger = createLogger('priceAndCommit');

  const cart = await getCart(userId);
  const v1Cart = await transformCartToV1Cart('app', cart, logger);
  // TODO: Validate that `status` is a valid state in this machine. I think it will only be PRICE_REQUESTED
  const { rbiOrderId, status } = await fetchPriceOrderMutation(v1Cart, logger);

  // Update the cart with the new status and hold reference to the rbiOrderId metadata
  cart.state = status;
  cart.metadata.rbiOrderId = rbiOrderId;
  await updateCart(cart, logger);

  return await getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation AddItemToCart($id: ID!) {
 *   addItemToCart(id: $id): Cart
 * }
 */
export async function _addItemToCart(itemId: ID): Promise<CartModel> {
  const logger = createLogger('addItemToCart');
  function uuid(): ID {
    return [
      Math.random().toString(16).slice(7),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(7),
    ].join('-');
  }

  const cart = await getCart(userId);
  const lineId = uuid();
  const productData = await dynamo_getItemData(itemId);
  cart.entries.push({
    itemId,
    displayName: productData.displayName,
    // confirm with menu team the basePrice/variants approach
    price: productData.basePrice,
    lineId,
    type: 'item',
    modifiers: [],
  });
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  logger.log('Adding cart line', lineId, 'and settings state to', cart.state);
  await updateCart(cart, logger);
  return getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation RemoveItemFromCart($lineId: ID!) {
 *   removeItemFromCart(lineId: $id): Cart
 * }
 */
export async function _removeItemFromCart(lineId: ID): Promise<CartModel> {
  const logger = createLogger('removeItemFromCart');
  const cart = await getCart(userId);

  const index = cart.entries.findIndex(i => i.lineId === lineId);
  cart.entries.splice(index, 1);
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  logger.log('Removing cart item line', lineId, 'and settings state to', cart.state);
  await updateCart(cart, logger);

  return getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation RemoveItemFromCart($lineId: ID!) {
 *   removeItemFromCart(lineId: $id): Cart
 * }
 */
export async function _setDonationAmount(donationAmount: number): Promise<CartModel> {
  const logger = createLogger('setDonationAmount');
  const cart = await getCart(userId);

  cart.donationAmount = donationAmount;
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  await updateCart(cart, logger);

  return getCart(userId);
}

// PRETEND PRIVATE METHODS
//------------------------

// eslint-disable-next-line
import { menuItemData } from '@fhs/menu/src/data/__mocks__/menu-item-data';
async function dynamo_getItemData(slug: string) {
  return menuItemData[slug];
}
