import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { brand, env, getCurrentCommitHash, isLocalDev } from 'utils/environment';

const FALLBACK_SAMPLING_RATE = isLocalDev ? 100 : 10;

export const useDatadogRum = () => {
  const rumSampleRate = useFlag(LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE);
  const logSampleRate = useFlag(LaunchDarklyFlag.DATADOG_LOG_SAMPLE_RATE);
  const dataDogConfig = useConfigValue({ key: 'datadog', defaultValue: { rum: {}, logs: {} } });
  const awsConfig = useConfigValue({ key: 'aws', defaultValue: {}, isRegionalized: false });

  useEffectOnce(() => {
    const applicationId = dataDogConfig.rum.applicationId;
    const clientToken = dataDogConfig.rum.clientToken;
    const datacenter = dataDogConfig.rum.dataCenter || 'us';
    const currentEnv = env();

    // We do not log errors to datadog in local dev
    // If you need to test datadog integration, remove the `&& !isLocalDev`
    if (isLocalDev) {
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      datacenter,
      site: 'datadoghq.com',
      service: `${brand()}_web`,
      sessionSampleRate: rumSampleRate ?? FALLBACK_SAMPLING_RATE,
      env: currentEnv,
      version: getCurrentCommitHash(),
      traceSampleRate: rumSampleRate ?? FALLBACK_SAMPLING_RATE,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        { match: awsConfig.gqlApiUrl, propagatorTypes: ['datadog'] },
        { match: awsConfig.gqlGatewayApiUrl, propagatorTypes: ['datadog'] },
      ],
    });

    datadogRum.addRumGlobalContext('expo', {
      commit: getCurrentCommitHash(),
    });

    datadogLogs.init({
      clientToken: dataDogConfig.logs.clientToken,
      datacenter,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      env: currentEnv,
      service: `${brand()}_web`,
      sessionSampleRate: logSampleRate ?? FALLBACK_SAMPLING_RATE,
      version: getCurrentCommitHash(),
    });
  });
};
