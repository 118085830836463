import { Box } from '@fhs-legacy/universal-components';

export const StyledTotalsContainer = Box.withConfig({
  backgroundColor: Styles.color.grey.ten,
  // account for 1rem margin on totals row
  paddingTop: '$1.5',
  paddingBottom: '22px',
  paddingX: '18px',
  marginY: '$6',
  borderRadius: Styles.borderRadius,
});

export const StyledTipAmount = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const StyledWarningContainer = Box.withConfig({
  paddingBottom: '$3',
  paddingTop: 0,
  marginRight: '$3',
});
