import { useCallback } from 'react';

import {
  IReloadPrepaidInput,
  IReloadPrepaidMutation,
  Platform,
  useReloadPrepaidMutation,
} from 'generated/graphql-gateway';
import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';

import { IRequestVaultedPrepaidReload } from '../../hooks/types';

export const useFirstDataVaultedPrepaidReload = () => {
  const [reloadPrepaidMutation] = useReloadPrepaidMutation();

  const forterDeviceId = withForterHeaders?.deviceId ?? '';
  const forterToken = withForterHeaders?.ttiForterToken ?? '';

  const buildReloadPrepaidInput = useCallback(
    ({
      billingCountry,
      storedPaymentMethodId,
      reloadInformation,
    }: IRequestVaultedPrepaidReload): IReloadPrepaidInput => {
      const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
      const { destinationPaymentMethodId, reloadAmount } = reloadInformation;
      const input: IReloadPrepaidInput = {
        amount: reloadAmount,
        currency: currencyCode,
        destinationPaymentMethodId,
        billingAddress: {
          regionCode: ISOsToRegions[billingCountry],
        },
        paymentMethod: JSON.stringify({
          type: 'scheme',
          subtype: 'vaulted',
          storedPaymentMethodId,
        }),
        riskData: JSON.stringify({
          cookie: forterToken,
          mobileId: forterDeviceId,
        }),
        platform: Platform.APP,
      };

      return input;
    },
    [forterDeviceId, forterToken]
  );

  const requestVaultedPrepaidReload = useCallback(
    async ({
      billingCountry,
      storedPaymentMethodId,
      reloadInformation,
    }: IRequestVaultedPrepaidReload): Promise<IReloadPrepaidMutation | undefined> => {
      const input = buildReloadPrepaidInput({
        billingCountry,
        storedPaymentMethodId,
        reloadInformation,
      });

      const result = await reloadPrepaidMutation({
        variables: {
          input,
        },
      });
      return result.data || undefined;
    },
    [buildReloadPrepaidInput, reloadPrepaidMutation]
  );

  return {
    requestVaultedPrepaidReload,
  };
};
