import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Favicon } from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import { PaymentEntryPoint } from 'components/payments/entry-points';
import {
  PaymentMethodOptionTypes,
  SchemePaymentMethod,
} from 'components/payments/integrations/hooks/types';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { CartPaymentCardType, IOrder, PaymentMethodType } from 'generated/graphql-gateway';
import { useGetRestaurantsQuery } from 'generated/rbi-graphql';
import { IPaymentCollectorRef } from 'pages/cart/payments/types';
import { StoreCard } from 'pages/store-locator/new-ui/store-card';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payments';
import { ServiceMode } from 'state/service-mode';

import { TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID, TAP_IM_CLOSE_TRANSLATION_ID } from './constants';
import { PickupCardButton } from './pickup-card-button';
import { Heading, LogoContainer, PickupInstructions } from './styled';

const PickupCard = ({
  confirmed = false,
  serverOrder,
}: {
  confirmed?: boolean;
  serverOrder: IServerOrder;
}) => {
  const { emptyCart, onCommitSuccess } = useOrderContext();
  const {
    creditCardFormValues,
    encryptionResult,
    loading: paymentLoading,
    orderInformation,
    paymentIntegration,
    selectedPaymentMethod: paymentMethodDetails,
  } = usePaymentContext();

  /**
   * This orderStatus is taken from the poller inside useCommitOrder.
   * Reference: useOrderStatus
   */
  const orderStatus = serverOrder.status;

  // TODO: Could we take the serverOrder from the context and do not require a prop?
  const { serviceMode } = serverOrder.cart;

  const [isCommitingOrder, setIsCommitingOrder] = useState<boolean>(false);
  const [processingOrder, setProcessingOrder] = useState(false);

  /**
   * This is used with useImperativeHandler in the payment collector. It is a unique case in which we need to be able
   * to call a method within a child to send the current payment details for a user when, and only when, it is needed.
   */
  const paymentCollectorRef = useRef<IPaymentCollectorRef>(null);

  const { data: restaurantsData, loading: loadingRestaurant } = useGetRestaurantsQuery({
    variables: {
      input: {
        first: 1,
        coordinates: {
          userLat: serverOrder.cart.storeDetails.latitude,
          userLng: serverOrder.cart.storeDetails.longitude,
          searchRadius: 20,
        },
      },
    },
  });

  const restaurant = restaurantsData?.restaurants?.nodes[0];

  const handleCommitOrder = useCallback(async () => {
    if (!paymentMethodDetails || !orderInformation) {
      throw new Error('Missing payment information');
    }

    try {
      let placeOrderResult: IOrder | undefined;
      switch (paymentMethodDetails.paymentMethod.type) {
        case PaymentMethodOptionTypes.ADD_PAYMENT_METHOD: // this case appears when the user has zero available method
        case PaymentMethodOptionTypes.ADD_CREDIT_CARD: // this case appears when the user has at least one available payment method
          if (!creditCardFormValues) {
            throw new Error('Missing credit card information to make the payment');
          }

          placeOrderResult = await paymentCollectorRef?.current?.placeOrderWithNewCreditCard({
            creditCardFormValues,
            encryptionResult,
            orderInformation,
          });
          break;
        case PaymentMethodType.SCHEME:
          placeOrderResult = await paymentCollectorRef.current?.placeOrderWithVaultedCard({
            orderInformation,
            paymentMethod: paymentMethodDetails.paymentMethod as SchemePaymentMethod,
          });
          break;
        case PaymentMethodType.GOOGLE_PAY:
          placeOrderResult = await paymentCollectorRef?.current?.placeOrderWithGooglePay(
            orderInformation
          );
          break;
        case PaymentMethodType.APPLE_PAY:
          placeOrderResult = await paymentCollectorRef?.current?.placeOrderWithApplePay(
            orderInformation
          );
          break;
        // TODO: Payments Refactor Improvement - Add error handling if a payment type is not found
      }
      setProcessingOrder(false);
      return placeOrderResult;
    } catch (error) {
      setProcessingOrder(false);
      // TODO: Payment Refactor Improvement - handle errors here. Currently errors are handled in the pickup card button component.
      return undefined;
    }
  }, [creditCardFormValues, encryptionResult, orderInformation, paymentMethodDetails]);

  const onCommitOrder = useCallback(() => {
    setIsCommitingOrder(true);
    handleCommitOrder();
    setIsCommitingOrder(false);
    onCommitSuccess(serverOrder);
    return emptyCart();
  }, [emptyCart, handleCommitOrder, onCommitSuccess, serverOrder]);

  const { formatMessage } = useIntl();

  // TODO: We will need to reuse the poller to get the live order status.
  const orderIsLoading = isCommitingOrder || orderStatus === OrderStatus.INSERT_REQUESTED;

  const enableImCloseDisplay = useFlag(LaunchDarklyFlag.ENABLE_IM_CLOSE_DISPLAY);

  const shouldShowImCloseText = enableImCloseDisplay && serviceMode !== ServiceMode.CURBSIDE;

  const screenReaderButtonName = shouldShowImCloseText ? 'imClose' : 'imHere';

  const isCashPayment = paymentMethodDetails?.paymentMethod.type === CartPaymentCardType.CASH;

  return (
    <>
      <Box testID="pickup-card">
        <VisuallyHidden
          role="alert"
          accessibilityLabel={formatMessage(
            { id: 'pickUpConfirmationScreenReaderInstructions' },
            {
              screenReaderButtonName,
            }
          )}
        />
        {!confirmed && (
          <>
            <LogoContainer>
              <Favicon />
            </LogoContainer>

            <Heading>{formatMessage({ id: 'savedOrder' })}</Heading>
            <PickupInstructions>
              {formatMessage({
                id: isCashPayment
                  ? shouldShowImCloseText
                    ? TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID
                    : 'tapImHereCashPayment'
                  : shouldShowImCloseText
                  ? TAP_IM_CLOSE_TRANSLATION_ID
                  : 'tapImHere',
              })}
            </PickupInstructions>
          </>
        )}
      </Box>
      {!loadingRestaurant && !!restaurant && (
        <>
          <StoreCard restaurant={restaurant} />
          {!confirmed && paymentIntegration && (
            <>
              <PickupCardButton
                isLoading={orderIsLoading || processingOrder}
                onCommit={onCommitOrder}
                orderStatus={orderStatus}
                restaurant={restaurant}
                shouldShowImCloseText={shouldShowImCloseText}
              />
              <PaymentEntryPoint
                loading={paymentLoading}
                paymentIntegration={paymentIntegration}
                paymentCollectorRef={paymentCollectorRef}
                hidePaymentMethod={true}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PickupCard;
