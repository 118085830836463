// From: https://www.figma.com/file/QjvoOspk3DDfQ8cwn9dDiB/Design-System---FHS?node-id=780%3A18449&mode=dev
export const colors = {
  // Brand
  $houseRedDarken: '#B1080F',
  $houseRed: '#E31837',
  $houseYellow: '#EBA518',

  // Utility
  $black: '#000',
  $white: '#FFFFFF',
  $houseLight: '#F2F2F6',
  $houseDark: '#01000B',
  $houseRed10: '#FCE7EB',

  // Functional
  $error: '#D14545',
  $errorBackground: '#FFE7E7',
  $success: '#387F49',
  $successBackground: '#D1FAE5',
  $warning: '#B06000',
  $warningBackground: '#FFEDD5',
  $placeholderText: 'rgb(113, 113, 122)',
  $disabledText: 'rgba(0,0,0,0.55)',

  // Opacity
  $transparent: 'rgba(0,0,0,0)',

  $blackOpacity04: 'rgba(0,0,0,0.04)',
  $blackOpacity10: 'rgba(0,0,0,0.1)',
  $black10: '#E5E5E5',
  $blackOpacity30: 'rgba(0,0,0,0.3)',
  $blackOpacity55: 'rgba(0,0,0,0.55)',
  $blackOpacity75: 'rgba(0,0,0,0.75)',

  $whiteOpacity04: 'rgba(250, 250, 250, .04)',
  $whiteOpacity10: 'rgba(250, 250, 250, .1)',
  $whiteOpacity30: 'rgba(250, 250, 250, .3)',
  $whiteOpacity75: 'rgba(250, 250, 250, .75)',
  $whiteOpacity90: 'rgba(250, 250, 250, .90)',
} as const;
