import { useEffect, useState } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { PaymentMethod } from 'components/payments/integrations/hooks/types';
import {
  isAllowedReloadMethod,
  isGiftCardMethod,
} from 'components/payments/integrations/hooks/utils';
import { useTotalTipCalculations } from 'pages/cart/your-cart/totals/use-total-tip-calculations';
import { useAuthContext } from 'state/auth';
import { ServiceMode } from 'state/service-mode/types';
import { useUIContext } from 'state/ui';

import { calculateReloadAmount } from './utils';

interface IPrepaidReloadProps {
  paymentMethods: PaymentMethod[];
  serverOrder: IServerOrder | null;
  serviceMode: ServiceMode;
  selectedPaymentMethod: PaymentMethod;
}

interface IPrepaidReload {
  needsReload: boolean;
  reloadAmount: number;
  formattedReloadAmount: string;
  reloadPaymentMethods: PaymentMethod[];
  selectedReloadPaymentMethod: PaymentMethod | undefined;
  setSelectedReloadPaymentMethod: (pm: PaymentMethod | undefined) => void;
  creditCardReloadFormValues: IAddCreditCardFormValues | undefined;
  setCreditCardReloadFormValues: (values: IAddCreditCardFormValues | undefined) => void;
}

export const usePrepaidReload = ({
  paymentMethods,
  selectedPaymentMethod,
  serverOrder,
  serviceMode,
}: IPrepaidReloadProps): IPrepaidReload => {
  const auth = useAuthContext();
  const { formatCurrencyForUser } = useUIContext();
  const { cart } = useTotalTipCalculations({ serverOrder, serviceMode });
  const totalCents = cart?.totalCents || 0;

  const defaultReloadAmt = auth?.user?.details?.defaultReloadAmt ?? 500;

  const reloadAmount = isGiftCardMethod(selectedPaymentMethod)
    ? calculateReloadAmount({
        totalCents,
        currentPrepaidBalance: selectedPaymentMethod.balance,
        defaultReloadAmt,
      })
    : 0;

  const formattedReloadAmount = formatCurrencyForUser(reloadAmount);

  // Determine which payment methods can be used for reload
  const reloadPaymentMethods = paymentMethods.filter(isAllowedReloadMethod);

  const [selectedReloadPaymentMethod, setSelectedReloadPaymentMethod] = useState<
    PaymentMethod | undefined
  >(reloadPaymentMethods[0]);

  const [creditCardReloadFormValues, setCreditCardReloadFormValues] = useState<
    IAddCreditCardFormValues | undefined
  >(undefined);

  useEffect(() => {
    const reloadPaymentMethods = paymentMethods.filter(isAllowedReloadMethod);
    if (!selectedReloadPaymentMethod) {
      setSelectedReloadPaymentMethod(reloadPaymentMethods[0]);
    }
  }, [paymentMethods, selectedReloadPaymentMethod, setSelectedReloadPaymentMethod]);

  return {
    needsReload: reloadAmount > 0,
    reloadAmount,
    formattedReloadAmount,
    reloadPaymentMethods,
    selectedReloadPaymentMethod,
    setSelectedReloadPaymentMethod,
    creditCardReloadFormValues,
    setCreditCardReloadFormValues,
  };
};
