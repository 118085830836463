import { type MenuItem } from '../../types';
import { getUrlForItemDetailPageFromSlug } from '../../utils';

export const menuItemData: Record<string, MenuItem> = {
  'hook-and-ladder': {
    id: 'hook-and-ladder',
    slug: 'hook-and-ladder',
    displayName: 'Hook & Ladder',
    url: 'REPLACE',
    imageUrl:
      'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
    descriptionShort: 'Turkey Breast, Honey Ham, and more.',
    description:
      'Smoked turkey breast, honey ham, and melted Monterey Jack, served with lettuce, tomato, onion, mayo, and deli mustard with a dill pickle spear served on the side.',

    basePrice: 899,
    variants: [
      {
        name: 'Medium',
        price: 899,
      },
      {
        name: 'Large',
        price: 1099,
      },
    ],
  },
  'firehouse-hero': {
    id: 'firehouse-hero',
    slug: 'firehouse-hero',
    displayName: 'Firehouse Hero',
    url: 'REPLACE',
    imageUrl:
      'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
    basePrice: 899,
    descriptionShort:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
    description:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
  },
  'pepperoni-pizza-meatball-sub': {
    id: 'pepperoni-pizza-meatball-sub',
    slug: 'pepperoni-pizza-meatball-sub',
    displayName: 'Pepperoni Pizza Meatball Sub',
    status: 'new',
    url: 'REPLACE',
    imageUrl:
      'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
    basePrice: 899,
    descriptionShort:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
    description:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
  },
};

let count = 0;
export const getMenuItemData = (_slug: string, createUniqueSlug: boolean = true) => {
  const found = Object.assign({}, menuItemData[_slug]);
  if (!found) {
    return null;
  }
  found.url = getUrlForItemDetailPageFromSlug(found.slug);
  if (createUniqueSlug) {
    found.slug = `${found.slug}-${count}`;
    count++;
  }
  return found;
};
