import { router, useLocalSearchParams } from 'expo-router';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { useFirstDataGiftCardNumber } from 'components/payments/integrations/firstdata/components/gift-card-modal/hooks/use-first-data-gift-card-number';
import { usePrepaidsBalance } from 'hooks/prepaid/use-prepaids-balance';
import useErrorModal from 'hooks/use-error-modal';
import { usePathname } from 'hooks/use-pathname';
import { useLocale } from 'state/intl';
import logger from 'utils/logger';
import { relativeRoutes } from 'utils/routing';

export const useFirstDataTransferGiftCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const {
    giftCardNumber,
    errors: giftCardNumberErrors,
    isValidGiftCardNumber,
    handleCardNumberChange,
  } = useFirstDataGiftCardNumber();
  const pathname = usePathname();
  const params = useLocalSearchParams<{
    currentBalance?: string;
  }>();

  const { getPrepaidsBalance } = usePrepaidsBalance({ region });

  const handleCloseModal = useCallback(() => {
    router.back();
  }, []);

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
    onCancel: handleCloseModal,
  });

  const transferCard = useCallback(
    async (_event: GestureResponderEvent) => {
      setIsLoading(true);
      try {
        const { data } = await getPrepaidsBalance({ cardNumber: giftCardNumber });

        const transferCardBalance = data?.prepaidsBalance.currentBalance;
        const successRoute = pathname.replace(
          relativeRoutes.transferGiftCard,
          relativeRoutes.successTransferGiftCard
        );

        // Callback
        router.back();
        router.replace({
          pathname: successRoute,
          params: {
            transferCardBalance,
            cardNumber: giftCardNumber,
            currentBalance: params.currentBalance,
          },
        });
      } catch (error) {
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
        logger.error({ error, message: 'Error transfer gift card' });
      } finally {
        setIsLoading(false);
      }
    },
    [
      getPrepaidsBalance,
      giftCardNumber,
      pathname,

      params.currentBalance,
      openErrorDialog,
      formatMessage,
    ]
  );

  return {
    transferCard,
    isLoading,
    ErrorDialog,
    handleCloseModal,
    isValidGiftCardNumber,
    handleCardNumberChange,
    giftCardNumberErrors,
  };
};
