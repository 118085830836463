import React, { FC } from 'react';

import { IServerOrder } from '@rbi-ctg/menu';
import { ConfirmPickupEntryPoint } from 'components/payments/entry-points/confirm-pickup/confirm-pickup-entry-point';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus } from 'state/order/types';
import { ServiceMode } from 'state/service-mode';

import ConfirmPickup from './confirm-pickup';
import DeliveryConfirmation from './delivery/delivery-confirmation';
import Fallback from './fallback';
import PickupConfirmed from './pickup-confirmed';

interface IOrderConfirmationContentProps {
  loading: boolean;
  serverOrder: IServerOrder | null;
}

export const OrderConfirmationPageContent: FC<
  React.PropsWithChildren<IOrderConfirmationContentProps>
> = (props: IOrderConfirmationContentProps) => {
  const { loading, serverOrder } = props;
  const enableNewPaymentsFrontend = useFlag(LaunchDarklyFlag.ENABLE_NEW_PAYMENTS_FRONTEND);

  if (!serverOrder || loading) {
    return <Fallback />;
  }

  const {
    cart: { serviceMode },
    status: orderStatus,
  } = serverOrder;

  if (serviceMode === ServiceMode.DELIVERY) {
    return <DeliveryConfirmation serverOrder={serverOrder} />;
  }

  switch (orderStatus) {
    case OrderStatus.INSERT_REQUESTED:
    case OrderStatus.PAYMENT_REQUESTED:
    case OrderStatus.PAYMENT_REQUIRED:
    case OrderStatus.PAYMENT_SUCCESSFUL:
    case OrderStatus.PRICE_SUCCESSFUL:
    case OrderStatus.VALIDATION_ERROR:
      return enableNewPaymentsFrontend ? (
        <ConfirmPickupEntryPoint serverOrder={serverOrder} />
      ) : (
        <ConfirmPickup serverOrder={serverOrder} />
      );
    case OrderStatus.INSERT_SUCCESSFUL:
    case OrderStatus.UPDATE_REQUESTED:
    case OrderStatus.UPDATE_ERROR:
    case OrderStatus.UPDATE_SUCCESSFUL:
      return <PickupConfirmed serverOrder={serverOrder} />;
    default:
      return <Fallback />;
  }
};
