import { useLocalSearchParams } from 'expo-router';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { useHomepageReorder } from '@fhs-legacy/frontend/src/state/order/hooks/use-homepage-reorder';
import { Box, VStack } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { BlankScreen } from 'components/blank-screen';
import LoadingAnimation from 'components/loading-animation';
import { useOrderDetails } from 'hooks/use-order-details';
import { useSupportData } from 'hooks/use-support-data';
import LoadingIndicator from 'pages/store-locator/loading-indicator';
import { useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useHomepageReorderEnabled } from 'state/order/hooks/use-homepage-reorder-enabled';
import { useReorder } from 'state/order/hooks/use-reorder';
import { useUIContext } from 'state/ui';
import { everyCartEntriesInMenu } from 'utils/cart';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { CartEntry } from './cart-entry';
import {
  Container,
  Divider,
  Footer,
  LoadingContainer,
  NeedHelpButtonLoadingIndicatorWrapper,
  OrderDetailsScrollContainer,
} from './details-modal.styled';
import { PointsEarnedBlock } from './points-earned';
import { ReceiptDetails } from './receipt-details';
import { ReceiptHeadInfo } from './receipt-head-info';
import { useHelpLinkUrlGenerator } from './use-help-link-url-generator';

export function Receipt() {
  const params = useLocalSearchParams<{ rbiOrderId: string }>();
  const rbiOrderId = params.rbiOrderId?.toString() ?? '';
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const { order, loading: isOrderLoading, refetch } = useOrderDetails(rbiOrderId);

  // If the new homepage reorder is enabled, we want the same experience for the Reorder feature from Recent Orders page
  const isHomepageReorderEnabled = useHomepageReorderEnabled();

  const { handleReorderClick, disabled } = useReorder(order);

  const {
    handleReorderCallback,
    recentOrderLoading,
    isReorderingLoading,
    ConfirmLocationActionSheetComponent,
    ConfirmStoreActionSheetComponent,
  } = useHomepageReorder(order);

  // Just relevant in case the new reorder experience is enabled
  const isReorderCustomLoading = Boolean(
    isHomepageReorderEnabled && (recentOrderLoading || isReorderingLoading)
  );

  const {
    reorder: { reordering },
  } = useOrderContext();
  const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);
  const { isSupportDataLoading } = useSupportData();

  const helpLink = useHelpLinkUrlGenerator(rbiOrderId);
  const disableReorder = useMemo(
    () => disabled || !everyCartEntriesInMenu(order?.cart?.reorderCartEntries || []),
    [disabled, order]
  );

  const loyaltyTransaction = order?.loyaltyTransaction;

  return (
    <View style={{ flex: 1 }}>
      <Container style={{ flex: 1 }}>
        {!isOrderLoading && order ? (
          <OrderDetailsScrollContainer>
            <View>
              <ReceiptHeadInfo order={order} />
              <Divider />
            </View>
            {loyaltyTransaction && (
              <>
                <PointsEarnedBlock lylTransaction={loyaltyTransaction} />
                <Divider />
              </>
            )}
            <View>
              {order.cart.cartEntries.map((entry, i) => {
                if (entry.lineId === 'discount-offer') {
                  return null;
                }
                return (
                  <React.Fragment key={entry.name! + i}>
                    <CartEntry
                      currencyFormatter={formatCurrencyForLocale}
                      entry={entry}
                      index={i}
                    />
                    <Divider />
                  </React.Fragment>
                );
              })}
            </View>
            <View>
              <ReceiptDetails order={order} />
            </View>
            {/* Creates padding from the bottom so the text doesnt get cut off */}
            <View style={{ height: 16 }} />
          </OrderDetailsScrollContainer>
        ) : isOrderLoading ? (
          <LoadingContainer>
            <LoadingAnimation />
          </LoadingContainer>
        ) : (
          <BlankScreen context={{ reason: 'there is not rbiOrderId' }} onReload={refetch} />
        )}
      </Container>
      <Footer>
        <Container>
          <VStack space="$3">
            <ActionButton
              fullWidth
              onPress={() => {
                if (isHomepageReorderEnabled) {
                  handleReorderCallback();
                  return;
                }

                handleReorderClick();
              }}
              isLoading={reordering || isOrderLoading || isReorderCustomLoading}
              disabled={disableReorder || isOrderLoading || isReorderCustomLoading}
            >
              {formatMessage({ id: 'reorder' })}
            </ActionButton>
            <Box>
              {formatMessage({
                id: 'pricesDiscountsAndAvailabilityAreSbujectToChangeWhenYouReorder',
              })}
            </Box>

            {isSupportDataLoading && enableOrderSupport && (
              <NeedHelpButtonLoadingIndicatorWrapper>
                <LoadingIndicator />
              </NeedHelpButtonLoadingIndicatorWrapper>
            )}

            {/*
             * isSupportDataLoading becomes false quickly, so aria-live="polite" on this button is distracting.
             * This will silently appear for screen readers.
             */}
            {!isSupportDataLoading && enableOrderSupport && (
              <Box>
                <ActionButton
                  testID="recent-order-need-help"
                  fullWidth
                  variant={ActionButtonVariants.OUTLINE}
                  size={ActionButtonSizes.SMALL}
                  to={helpLink}
                >
                  {formatMessage({ id: 'needHelp' })}
                </ActionButton>
              </Box>
            )}
          </VStack>
        </Container>
      </Footer>
      <ConfirmLocationActionSheetComponent />
      <ConfirmStoreActionSheetComponent />
    </View>
  );
}

export default Receipt;
