import { IntlFormatters } from 'react-intl';

import {
  IAddCreditCardFormErrors,
  IAddCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/types';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { ALL_COUNTRIES_ZIP_CODE_REGEX, COUNTRY_BASED_ZIP_CODE_REGEX } from 'utils/form';
import { ISOs } from 'utils/form/constants';

import { getBillingCountryError } from './get-billing-country-error';

const validateCardZipCode = (
  zipCode: string,
  country: string,
  formatMessage: IntlFormatters['formatMessage'],
  countryFieldEnabled: boolean
) => {
  let cardZipCodeError;
  const regex = countryFieldEnabled
    ? COUNTRY_BASED_ZIP_CODE_REGEX[country]
    : ALL_COUNTRIES_ZIP_CODE_REGEX;

  if (zipCode && country && !regex.test(zipCode)) {
    cardZipCodeError = formatMessage({
      id: country === ISOs.USA ? 'zipCodeInvalid' : 'postalCodeInvalid',
    });
  }

  return cardZipCodeError;
};

export const getCreditCardFormErrors = (
  state: IAddCreditCardFormValues,
  formErrors: IAddCreditCardFormErrors,
  formatMessage: IntlFormatters['formatMessage'],
  paymentFieldVariations: PaymentFieldVariations = defaultPaymentFieldVariation,
  country: ISOs
): { hasErrors: boolean; formErrors: IAddCreditCardFormErrors } => {
  if (!state.isCardNumberValid) {
    formErrors.cardNumber = formatMessage({ id: 'ccNumberIsNotValid' });
  }
  if (!state.cvv || state.cvv.trim().length < 3) {
    formErrors.cvv = formatMessage({ id: 'cvvMustBeAtLeast3Digits' });
  }
  if (!state.isExpiryValid) {
    formErrors.expiry = formatMessage({ id: 'creditCardExpired' });
  }
  if (!state.expiry?.trim()) {
    formErrors.expiry = formatMessage({ id: 'expirationDateIsRequired' });
  }
  if (paymentFieldVariations.name && !state.nameOnCard?.trim()) {
    formErrors.nameOnCard = formatMessage({ id: 'nameOnCardIsRequired' });
  }
  if (!state.cardNumber.trim()) {
    formErrors.cardNumber = formatMessage({ id: 'cardNumberIsRequired' });
  }
  if (paymentFieldVariations.cvv && !state.cvv?.trim()) {
    formErrors.cvv = formatMessage({ id: 'cvvIsRequired' });
  }
  if (paymentFieldVariations.addressLine1 && !state.billingStreetAddress?.trim()) {
    formErrors.billingStreetAddress = formatMessage({ id: 'addressRequiredError' });
  }
  if (paymentFieldVariations.city && !state.billingCity?.trim()) {
    formErrors.billingCity = formatMessage({ id: 'cityRequiredError' });
  }
  if (paymentFieldVariations.state && !state.billingState?.trim()) {
    formErrors.billingState = formatMessage({ id: 'stateIsARequiredField' });
  }
  if (paymentFieldVariations.zip) {
    const billingZip = state.billingZip.trim();
    const countryToValidateBy = paymentFieldVariations.country ? state.billingCountry : country;
    // Validate zip code based on country if country input is displayed
    if (billingZip && countryToValidateBy) {
      const billingZipError = validateCardZipCode(
        state.billingZip,
        countryToValidateBy,
        formatMessage,
        paymentFieldVariations.country
      );
      if (billingZipError) {
        formErrors.billingZip = billingZipError;
      }
    }

    if (!billingZip) {
      formErrors.billingZip = formatMessage({
        id: countryToValidateBy === ISOs.USA ? 'zipCodeRequiredError' : 'postalCodeRequiredError',
      });
    }
  }
  if (paymentFieldVariations.country && !state.billingCountry) {
    formErrors.billingCountry = getBillingCountryError(formatMessage);
  }
  const hasErrors: boolean = Object.values(formErrors).some(err => err);

  return { hasErrors, formErrors };
};
