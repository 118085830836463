import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { IMutationCommitOrderArgs, IOrder } from 'generated/graphql-gateway';
import {
  CommitOrderDocument,
  GetOrderDocument,
  GetUserOrdersDocument,
} from 'generated/rbi-graphql';

import { IHandleCommitOrder, PaymentError } from './types';
import { buildCommitOrderPayload, getOrderFailureMessageKey } from './utils';

export const useCommitOrder = (rbiOrderId: string) => {
  const [commitOrderMutation] = useMutation<IOrder, IMutationCommitOrderArgs>(CommitOrderDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GetOrderDocument, variables: { rbiOrderId } },
      { query: GetUserOrdersDocument },
    ],
  });

  const handleCommitOrder = useCallback(
    async ({ cardBrand, orderInformation, payment }: IHandleCommitOrder) => {
      const variables = buildCommitOrderPayload({
        cardBrand,
        orderInformation,
        payment,
      });
      try {
        const commitOrderResult = await commitOrderMutation({ variables });
        return commitOrderResult?.data || undefined;
      } catch (err) {
        // Capture Apollo errors and construct a custom payment error to be caught from the payments page
        if (isApolloError(err as Error)) {
          const messageKey = getOrderFailureMessageKey('', err as ApolloError);
          throw new PaymentError((err as Error).message, messageKey);
        }
        throw err;
      }
    },
    [commitOrderMutation]
  );

  return {
    handleCommitOrder,
  };
};
