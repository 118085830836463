import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { AddCreditCardFormInputs, PaymentTotals } from 'components/payments/components';
import { useAddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/hooks/use-add-credit-card-form-inputs';
import { PaymentMethodDropdown } from 'components/payments/components/payment-method-dropdown/payment-method-dropdown';
import { addPaymentMethodDetails } from 'components/payments/components/payment-method-option/constants';
import { PrepaidReload } from 'components/payments/components/prepaid-reload';
import { usePrepaidReload } from 'components/payments/components/prepaid-reload/hooks/use-prepaid-reload';
import { AddGiftCardEntryPoint } from 'components/payments/entry-points';
import {
  PaymentMethod,
  PaymentMethodOptionTypes,
} from 'components/payments/integrations/hooks/types';
import useApplePay from 'components/payments/integrations/hooks/use-apple-pay';
import useGooglePay from 'components/payments/integrations/hooks/use-google-pay';
import { usePaymentMethodsAdapter } from 'components/payments/integrations/hooks/use-payment-methods-adapter';
import {
  isApplePayMethod,
  isCreditCardMethod,
  isGooglePayMethod,
  isNewCreditCard,
} from 'components/payments/integrations/hooks/utils';
import useEffectOnce from 'hooks/use-effect-once';
import {
  IOrderInformation,
  IPaymentCollectorProps,
  IPaymentCollectorRef,
  IPaymentMethodDetails,
  IPlaceOrderWithNewCreditCard,
  IPlaceOrderWithVaultedCard,
  PlaceOrderResult,
} from 'pages/cart/payments/types';
import { useCartContext } from 'state/cart';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { isDelivery } from 'utils/service-mode';

import { useAddPaymentMethod } from './components/firstdata-add-credit-card-modal/hooks/use-add-payment-method/use-add-payment-method';
import { useFirstDataOneTimePaymentMethod } from './hooks/use-firstdata-one-time-payment-method';
import { useFirstDataOneTimePrepaidReload } from './hooks/use-firstdata-one-time-prepaid-reload';
import { useFirstDataVaultedPayment } from './hooks/use-firstdata-vaulted-payment';
import { useFirstDataVaultedPrepaidReload } from './hooks/use-firstdata-vaulted-prepaid-reload';

export const FirstDataPaymentCollector = forwardRef<IPaymentCollectorRef>(
  ({ hidePaymentMethod, isProcessingPlaceOrder = false }: IPaymentCollectorProps, ref) => {
    const migrationEnabled = useFlag(LaunchDarklyFlag.ENABLE_DIGITAL_WALLET_MIGRATION);

    const paymentFieldVariations =
      useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
      defaultPaymentFieldVariation;

    const { serverOrder, serviceMode } = useCartContext();
    const { feCountryCode: billingCountry } = useLocale();

    const [selectedPaymentMethod, setSelectedPaymentMethod] =
      useState<PaymentMethod>(addPaymentMethodDetails);

    const rbiOrderId = serverOrder?.rbiOrderId || '';
    const { addPaymentMethod } = useAddPaymentMethod();
    const { requestOneTimePayment } = useFirstDataOneTimePaymentMethod(rbiOrderId);
    const { requestVaultedPayment } = useFirstDataVaultedPayment(rbiOrderId);
    const {
      creditCardFormErrors,
      creditCardFormValues,
      handleCreditCardFormChanges,
      validateCreditCardFormOnSubmit,
    } = useAddCreditCardFormInputs({
      billingCountry,
      paymentFieldVariations,
    });

    // Get the user's available payment methods
    const { availablePaymentMethods, defaultPaymentMethod, loading } = usePaymentMethodsAdapter();
    // Set the user's default payment method as the selected option.
    // If not, set the next available payment method option.
    // If not, set the generic option so a user can add a payment method.
    useEffectOnce(() => {
      let paymentMethod: PaymentMethod = addPaymentMethodDetails;
      if (defaultPaymentMethod) {
        paymentMethod = defaultPaymentMethod;
      } else if (availablePaymentMethods.length) {
        paymentMethod = availablePaymentMethods[0];
      }
      setSelectedPaymentMethod(paymentMethod);
    });

    const { requestVaultedPrepaidReload } = useFirstDataVaultedPrepaidReload();
    const { requestOneTimePrepaidReload } = useFirstDataOneTimePrepaidReload();
    const { requestGooglePayPrepaidReload } = useGooglePay(rbiOrderId);
    const { requestApplePayPrepaidReload } = useApplePay({
      prepaid: false,
    });

    const {
      needsReload,
      reloadAmount,
      formattedReloadAmount,
      reloadPaymentMethods,
      selectedReloadPaymentMethod,
      setSelectedReloadPaymentMethod,
      creditCardReloadFormValues,
      setCreditCardReloadFormValues,
    } = usePrepaidReload({
      paymentMethods: availablePaymentMethods,
      selectedPaymentMethod,
      serverOrder,
      serviceMode,
    });

    const handlePaymentMethodSelection = (method: PaymentMethod) => {
      setSelectedPaymentMethod(method);
    };

    const isDeliveryOrder = isDelivery(serviceMode);
    const isUsingNewCreditCard = isNewCreditCard(selectedPaymentMethod);
    const showAddPrepaidCardForm =
      selectedPaymentMethod.id === PaymentMethodOptionTypes.ADD_GIFT_CARD;

    const performPrepaidReload = useCallback(async () => {
      // If there is no selected reload method, the inline CC form is displayed
      if (!selectedReloadPaymentMethod) {
        if (!creditCardReloadFormValues) {
          return undefined;
        }
        // If the user elects to save the new card, vault it then reload prepaid with newly vaulted card
        if (creditCardReloadFormValues.saveCard) {
          const addPaymentMethodResponse = await addPaymentMethod({
            paymentMethodValues: creditCardReloadFormValues,
          });
          const reloadResult = await requestVaultedPrepaidReload({
            billingCountry,
            storedPaymentMethodId: addPaymentMethodResponse.id,
            reloadInformation: {
              reloadAmount,
              destinationPaymentMethodId: selectedPaymentMethod.id,
            },
          });
          return reloadResult;
        }
        const reloadResult = await requestOneTimePrepaidReload({
          billingCountry,
          creditCardFormValues: creditCardReloadFormValues,
          reloadInformation: {
            reloadAmount,
            destinationPaymentMethodId: selectedPaymentMethod.id,
          },
        });
        return reloadResult;
      }
      if (isCreditCardMethod(selectedReloadPaymentMethod)) {
        const reloadResult = await requestVaultedPrepaidReload({
          billingCountry,
          storedPaymentMethodId: selectedReloadPaymentMethod.id,
          reloadInformation: {
            reloadAmount,
            destinationPaymentMethodId: selectedPaymentMethod.id,
          },
        });
        return reloadResult;
      }

      if (isApplePayMethod(selectedReloadPaymentMethod)) {
        const reloadResult = await requestApplePayPrepaidReload({
          billingCountry,
          reloadInformation: {
            reloadAmount,
            destinationPaymentMethodId: selectedPaymentMethod.id,
          },
        });
        return reloadResult;
      }

      if (isGooglePayMethod(selectedReloadPaymentMethod)) {
        const reloadResult = await requestGooglePayPrepaidReload({
          billingCountry,
          migrationEnabled,
          reloadInformation: {
            reloadAmount,
            destinationPaymentMethodId: selectedPaymentMethod.id,
          },
        });
        return reloadResult;
      }

      return undefined;
    }, [
      addPaymentMethod,
      billingCountry,
      creditCardReloadFormValues,
      migrationEnabled,
      reloadAmount,
      requestApplePayPrepaidReload,
      requestGooglePayPrepaidReload,
      requestOneTimePrepaidReload,
      requestVaultedPrepaidReload,
      selectedPaymentMethod,
      selectedReloadPaymentMethod,
    ]);

    /**
     * This is used with paymentCollectorRef in the checkout page (payment.tsx). It is a unique case in which we need to be able
     * to call a method within a child to send the current payment details for a user when, and only when, it is needed.
     */
    useImperativeHandle(
      ref,
      () => ({
        getPaymentMethodDetails: (): IPaymentMethodDetails => ({
          paymentMethod: selectedPaymentMethod,
          ...(isUsingNewCreditCard && {
            creditCardFormValues,
          }),
        }),
        placeOrderWithNewCreditCard: async ({
          creditCardFormValues,
          orderInformation,
        }: IPlaceOrderWithNewCreditCard): PlaceOrderResult => {
          const isFormValid = validateCreditCardFormOnSubmit();
          if (!isFormValid) {
            return undefined;
          }
          // If the user selects to save the new card, vault it and place the order with the newly vaulted credit card
          if (creditCardFormValues.saveCard) {
            const addPaymentMethodResponse = await addPaymentMethod({
              paymentMethodValues: creditCardFormValues,
            });

            return requestVaultedPayment({
              accountIdentifier: addPaymentMethodResponse.id,
              cardBrand: addPaymentMethodResponse.brand,
              orderInformation,
            });
          }
          return requestOneTimePayment({
            creditCardFormValues,
            orderInformation,
          });
        },
        placeOrderWithGooglePay: async (): PlaceOrderResult => {
          return undefined;
        },
        placeOrderWithApplePay: async (): PlaceOrderResult => {
          return undefined;
        },
        placeOrderWithVaultedCard: async ({
          orderInformation,
          paymentMethod,
        }: IPlaceOrderWithVaultedCard): PlaceOrderResult => {
          return requestVaultedPayment({
            accountIdentifier: paymentMethod.id,
            cardBrand: paymentMethod.brand,
            orderInformation,
          });
        },
        placeOrderWithGiftCard: async (_orderInformation: IOrderInformation) => {
          if (needsReload) {
            await performPrepaidReload();
          }

          // TODO commitOrder and return result (GST-3600)

          return undefined;
        },
      }),
      [
        selectedPaymentMethod,
        isUsingNewCreditCard,
        creditCardFormValues,
        validateCreditCardFormOnSubmit,
        requestOneTimePayment,
        addPaymentMethod,
        requestVaultedPayment,
        needsReload,
        performPrepaidReload,
      ]
    );

    /**
     * If you need to only access the payment operations you will be able
     * to hide the payment methods selector.
     */
    if (hidePaymentMethod) {
      return null;
    }

    return (
      <>
        <PaymentMethodDropdown
          aria-expanded="true"
          handleSelection={handlePaymentMethodSelection}
          loading={loading}
          paymentMethods={availablePaymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        {isUsingNewCreditCard && (
          <AddCreditCardFormInputs
            errors={creditCardFormErrors}
            values={creditCardFormValues}
            isDelivery={isDeliveryOrder}
            onChange={handleCreditCardFormChanges}
            showSaveCard
          />
        )}
        {showAddPrepaidCardForm && <AddGiftCardEntryPoint />}

        {needsReload && (
          <PrepaidReload
            reloadAmount={reloadAmount}
            formattedReloadAmount={formattedReloadAmount}
            reloadPaymentMethods={reloadPaymentMethods}
            selectedReloadPaymentMethod={selectedReloadPaymentMethod}
            setSelectedReloadPaymentMethod={setSelectedReloadPaymentMethod}
            setCreditCardReloadFormValues={setCreditCardReloadFormValues}
          />
        )}

        <PaymentTotals tipSelectorDisabled={isProcessingPlaceOrder} />
      </>
    );
  }
);
