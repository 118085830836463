import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { PaymentProcessor } from 'generated/rbi-graphql';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { ISOs } from 'utils/form/constants';

import { mapDeliveryAddressToCreditCardForm } from './initial-credit-card-form-values';

export const testCreditCardFormValues = ({
  isDelivery = false,
  paymentFieldVariations = defaultPaymentFieldVariation,
  paymentProcessor,
}: {
  isDelivery?: boolean;
  paymentFieldVariations?: PaymentFieldVariations;
  paymentProcessor?: PaymentProcessor;
} = {}): IAddCreditCardFormValues => {
  if (paymentProcessor === PaymentProcessor.WORLDPAY) {
    return {
      nameOnCard: 'John Smith',
      cardNumber: '4445222299990007',
      isCardNumberValid: true,
      isExpiryValid: true,
      ...mapDeliveryAddressToCreditCardForm({
        addressLine1: '5707 Blue Lagoon Dr',
        addressLine2: '',
        city: 'Miami',
        state: 'FL',
        zip: '33606',
      }),
      cardBrand: 'VISA',
      cvv: paymentFieldVariations.cvv ? '382' : '',
      expiry: paymentFieldVariations.expiration ? '12/26' : '',
      billingCountry: paymentFieldVariations.country ? ISOs.US : null,
      billingAddressSameAsDelivery: isDelivery,
      saveCard: true,
    };
  }
  return {
    nameOnCard: 'John Smith',
    cardNumber: '4111111111111111',
    isCardNumberValid: true,
    isExpiryValid: true,
    ...mapDeliveryAddressToCreditCardForm({
      addressLine1: '100 Universal City Plaza',
      addressLine2: '',
      city: 'Hollywood',
      state: 'CA',
      zip: '11747',
    }),
    cardBrand: 'VISA',
    cvv: paymentFieldVariations.cvv ? '123' : '',
    expiry: paymentFieldVariations.expiration ? '12/25' : '',
    billingCountry: paymentFieldVariations.country ? ISOs.US : null,
    billingAddressSameAsDelivery: isDelivery,
    saveCard: true,
  };
};
