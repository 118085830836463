import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { splitExpiry } from 'components/payments/components/add-credit-card-form-inputs/utils';
import { IEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import { IAddPaymentMethodInput } from 'generated/graphql-gateway';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { sanitizeAlphanumeric } from 'utils/form';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';

/**
 * Build orbital specific mutation input
 * @param formData credit card form data
 * @param encryptionData safetech encryption output
 * @param paymentFieldVariations
 * @param billingCountry
 * @returns
 */
export const buildMutationInput = (
  formData: IAddCreditCardFormValues,
  encryptionData: IEncryptionResult,
  paymentFieldVariations: PaymentFieldVariations,
  billingCountry: ISOs
): IAddPaymentMethodInput => {
  const hiddenFieldValue = null;
  const { currencyCode, countryCode: region } = getCountryAndCurrencyCodes(billingCountry);
  const { expiryMonth, expiryYear } = splitExpiry(formData.expiry ?? '');
  const postalCode = sanitizeAlphanumeric(formData.billingZip);

  const input: IAddPaymentMethodInput = {
    currency: currencyCode,
    paymentMethods: JSON.stringify({
      ORBITAL: {
        type: 'scheme',
        subtype: 'vaulted',
        cardAccountNum: encryptionData.cryptCard,
        cardVerifyNumber: encryptionData.cryptCvv,
        cardBrand: formData.cardBrand.toLowerCase(), // TODO: check mapping
        expiryMonth,
        expiryYear,
        cardholderName: formData.nameOnCard,
        bin: formData.cardNumber.slice(0, 6),
        last4: formData.cardNumber.slice(-4),
        pieSubscriberId: encryptionData.subscriberId,
        pieIntegrityCheck: encryptionData.integrityCheck,
        pieKeyId: encryptionData.keyId,
        piePhaseId: String(encryptionData.phase),
        pieMode: encryptionData.mode,
      },
    }),
    billingAddress: {
      regionCode: region,
      postalCode: paymentFieldVariations.zip ? postalCode : '',
      locality: paymentFieldVariations.city ? formData.billingCity : hiddenFieldValue,
      streetNumber: paymentFieldVariations.addressLine1
        ? formData.billingStreetAddress
        : hiddenFieldValue,
    },
  };

  return input;
};
