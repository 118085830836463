import { BlankScreen } from 'components/blank-screen';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useUserPaymentInformationQuery } from 'generated/graphql-gateway';

import { addGiftCardIntegrationMapping } from './types';

export const AddGiftCardEntryPoint = (): null | JSX.Element => {
  const { data, loading } = useUserPaymentInformationQuery();
  const paymentIntegration = data?.paymentConfig.frontendIntegration;

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  if (!paymentIntegration) {
    return <BlankScreen context={{ reason: 'paymentIntegration is null' }} />;
  }

  // Determine which payment integration to display for handling a gift card purchase.
  const AddGiftCardEntryPointIntegration = addGiftCardIntegrationMapping[paymentIntegration];

  return <AddGiftCardEntryPointIntegration />;
};
