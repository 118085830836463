import React from 'react';
import { useIntl } from 'react-intl';

import { Box, HStack, Text, VStack } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { PrepaidPaymentMethod } from 'components/payments/integrations/hooks/types';
import { theme } from 'styles/configure-theme';

import { PrepaidIcon } from '../icons/prepaid-icon/prepaid-icon';

interface IGiftCardOptionTypeProps {
  disableMethod: boolean;
  fullWidthDescription: boolean;
  method: PrepaidPaymentMethod;
}

export const GiftCardOption = ({
  fullWidthDescription,
  disableMethod,
  method,
}: IGiftCardOptionTypeProps) => {
  const { formatMessage } = useIntl();
  const giftCardBalance = method.balance ?? 0;
  const color = theme.token(disableMethod ? 'text-disabled' : 'text-default');
  const formattedMessage = formatMessage(
    { id: 'payWithMyPrepaidCard' },
    { cardAlias: formatMessage({ id: 'cardAlias' }) }
  );
  return (
    <>
      <Box marginRight={2} justifyContent="center">
        <PrepaidIcon />
      </Box>
      {fullWidthDescription ? (
        <HStack>
          <Text color={color}>
            {formattedMessage}
            {' - '}
          </Text>
          <Currency
            amount={giftCardBalance}
            textProps={{
              margin: 0,
            }}
          />
        </HStack>
      ) : (
        <VStack>
          <HStack alignItems="center">
            <Text color={color}>{formattedMessage}</Text>
          </HStack>
          <HStack alignItems="center">
            <Currency
              amount={giftCardBalance}
              textProps={{
                margin: 0,
                fontSize: 'xs',
              }}
            />
          </HStack>
        </VStack>
      )}
    </>
  );
};
