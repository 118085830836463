import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextInput as RNTextInput } from 'react-native';

import { Box } from '@fhs-legacy/universal-components';
import { TextInput } from 'components/ucl';
import { useLocale } from 'state/intl';
import { CURRENCY_PREFIX } from 'state/intl/types';
import { useOrderContext } from 'state/order';
import { ITipSelection } from 'state/order/types';
import { dollarsToCents } from 'utils';

import { DEFAULT_TIP_AMOUNT, MAX_TIP_LENGTH } from './constants';
import { StyledTipErrorMessage } from './styled';
import { TipOptions } from './tip-list/tip-options';
import { checkIfValidTip } from './utils';

interface ITipSelectorProps {
  subTotalCents: number;
  otherDiscountAmount: number;
  isDisabled: boolean;
}

export const TipSelector: React.FC<React.PropsWithChildren<ITipSelectorProps>> = ({
  subTotalCents,
  otherDiscountAmount,
  isDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const { setTipSelection, tipSelection, updateTipAmount, tipAmount } = useOrderContext();

  const [customTipString, setCustomTipString] = useState(
    `${CURRENCY_PREFIX[locale]}${tipSelection.otherAmount}`
  );
  const [errorString, setErrorString] = useState('');

  const onTipAmountChange = useCallback(
    (newTipAmount: string) => {
      updateTipAmount({
        subTotalCents,
        otherDiscountAmount,
        newTipOption: 'dollar',
        newTipAmount: parseFloat(newTipAmount),
      });
    },
    [otherDiscountAmount, subTotalCents, updateTipAmount]
  );

  const updateOtherTipAmount = (otherAmount: string) => {
    setTipSelection((selection: ITipSelection) => ({
      ...selection,
      isOtherSelected: true,
      otherAmount: Number(otherAmount),
    }));
    onTipAmountChange(otherAmount);
  };

  const handleOtherTipAmountChange = (otherTip: string) => {
    const parsedTip = otherTip.replace(/[^0-9.]/g, '');
    setCustomTipString(`${CURRENCY_PREFIX[locale]}${parsedTip}`);

    if (!parsedTip) {
      setErrorString(formatMessage({ id: 'tipAmountError' }));
      updateOtherTipAmount(DEFAULT_TIP_AMOUNT);
      return;
    }

    // Example: '1.2'
    const tipAmountAsString = parsedTip
      .split('')
      .filter(i => i !== CURRENCY_PREFIX[locale])
      .join('');

    const isValidTip = checkIfValidTip({
      amount: dollarsToCents(Number(tipAmountAsString)),
    });
    if (!isValidTip) {
      setErrorString(formatMessage({ id: 'tipAmountMaxError' }));
      updateOtherTipAmount(DEFAULT_TIP_AMOUNT);
      return;
    }

    setErrorString('');
    updateOtherTipAmount(tipAmountAsString);
  };

  // Show the error message above the Tip select buttons when the tip is > the subtotal
  // If the user has an invalid tip, keep the error message when the user clicks on the 'other' button until the user starts to type in the input
  const showTipSelectButtonError =
    (!tipSelection.isOtherSelected || !customTipString) && tipAmount > subTotalCents;

  const tipOtherInputRef = useRef<RNTextInput>(null);
  const { isOtherSelected } = tipSelection;
  const previousTipOtherSelected = useRef(isOtherSelected);

  // Focus tip input only when "Other" option is first selected
  // This prevents the keyboard from opening on mobile devices when cart renders "Other" by default
  useEffect(() => {
    if (!previousTipOtherSelected.current && isOtherSelected && tipOtherInputRef.current) {
      tipOtherInputRef.current.focus();
    }
  }, [isOtherSelected]);

  return (
    <Box alignItems="center">
      <TipOptions
        isDisabled={isDisabled}
        onTipAmountChange={onTipAmountChange}
        otherDiscountAmount={otherDiscountAmount}
        setErrorString={setErrorString}
        setCustomTipString={setCustomTipString}
        subTotalCents={subTotalCents}
      />

      {showTipSelectButtonError && (
        <StyledTipErrorMessage accessibilityRole="alert" testID="showTipSelectBtnError">
          {formatMessage({ id: 'tipAmountMaxError' })}
        </StyledTipErrorMessage>
      )}

      {isOtherSelected && (
        <TextInput
          testID="customTipInput"
          type="text"
          label={formatMessage({ id: 'tipField' })}
          value={customTipString}
          errorMessage={errorString}
          onChangeText={handleOtherTipAmountChange}
          // mask={currencyMask(locale)} // TODO: RN - Implement mask
          maxLength={MAX_TIP_LENGTH}
          ref={tipOtherInputRef}
          keyboardType="numeric"
          returnKeyType="done"
          isDisabled={isDisabled}
        />
      )}
    </Box>
  );
};
