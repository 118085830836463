import { Box, Icon, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const Wrapper = Box.withConfig({
  flexDirection: 'row',
  marginTop: '$4',
  marginBottom: '$2',
});

export const Badge = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '$6',
  paddingX: '$2',
  backgroundColor: theme.token('background-chip'),
  borderRadius: theme.token('border-radius-chip'),
});

export const Label = Text.withConfig({
  variant: 'copyThree',
  color: theme.token('text-reversed'),
});

export const CalendarIcon = Icon.withConfig({
  variant: 'calendar',
  size: 'xs',
  color: theme.token('icon-button-reversed'),
  marginRight: '$1',
});
