import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ModalContent } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import GiftCardImage from 'components/payments/components/gift-card-image/gift-card-image';
import { CARD_ALIAS_TRANSLATION_ID } from 'components/payments/integrations/hooks/constants';
import { TextInput } from 'components/ucl';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';

import { useFirstDataTransferGiftCard } from './hooks/use-first-data-transfer-gift-card';

export const TransferGiftCardModal = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    transferCard,
    isLoading,
    ErrorDialog,
    handleCloseModal,
    isValidGiftCardNumber,
    handleCardNumberChange,
    giftCardNumberErrors,
  } = useFirstDataTransferGiftCard();

  const [value, setValue] = useState('');

  const handleOnChange = useCallback(
    (changedValue: string) => {
      handleCardNumberChange(changedValue);
      setValue(changedValue);
    },
    [handleCardNumberChange]
  );

  return (
    <Modal
      onDismiss={handleCloseModal}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Transfer gift card',
      }}
    >
      <KeyboardAwareView withScroll>
        <ModalContent>
          <Box testID="transfer-giftcard-form" padding="$5" alignItems="center">
            <Header variant="headerOne" testID="modal-heading" my="$8">
              {formatMessage(
                { id: 'transferBalanceFromPrepaidCard' },
                { cardAlias: formatMessage({ id: CARD_ALIAS_TRANSLATION_ID }) }
              )}
            </Header>
            <Box mb="$8">
              <GiftCardImage />
            </Box>
            <TextInput
              type="text"
              label={formatMessage({ id: 'giftCardNumber' })}
              value={value}
              onChangeText={handleOnChange}
              errorMessage={giftCardNumberErrors?.giftCardNumber}
              autoFocus
              required
              testID="transfer-giftcard-input"
            />
            <ActionButton
              fullWidth
              testID="transfer-now"
              disabled={!isValidGiftCardNumber}
              onPress={transferCard}
              isLoading={isLoading}
              marginTop="$5"
            >
              {formatMessage({ id: 'transfer' })}
            </ActionButton>
          </Box>
        </ModalContent>
      </KeyboardAwareView>

      <ErrorDialog />
    </Modal>
  );
};
