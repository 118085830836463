import { ApolloLink } from '@apollo/client';
import {
  getMainDefinition,
  hasDirectives,
  removeDirectivesFromDocument,
} from '@apollo/client/utilities';
import { DocumentNode, StringValueNode } from 'graphql';

import LaunchDarklyHelper from 'utils/launchdarkly';

const GATEWAY_DIRECTIVE = 'gateway';

export const stripGatewayDirective = new ApolloLink((operation, forward) => {
  if (!hasDirectives([GATEWAY_DIRECTIVE], operation.query)) {
    return forward(operation);
  }

  // Remove @gateway directive
  const strippedQuery = removeDirectivesFromDocument(
    [{ name: GATEWAY_DIRECTIVE }],
    operation.query
  );
  if (strippedQuery) {
    operation.query = strippedQuery;
  }

  return forward(operation);
});

export const isGatewayRequest = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  const foundDirective = definition.directives?.find(
    directive => directive.name.value === GATEWAY_DIRECTIVE
  );

  // If no @gateway then false
  if (!foundDirective) {
    return false;
  }

  // Identify if directive looks like this: @gateway(flag: "enable-some-operation")
  const flagArg = foundDirective.arguments?.find(n => n.name.value === 'flag');
  if (!flagArg) {
    // no param found, so this always goes to Gateway
    return true;
  }

  const GATEWAY_FLAGS = {
    // Flags that might matter in the future
    'enable-gateway-addCreditAccount': false,
    'enable-gateway-createSupportCase': false,
    'enable-gateway-deleteAccount': false,
    'enable-gateway-deleteLoyaltyCard': false,
    'enable-gateway-encryptionDetails': false,
    'enable-gateway-GetLoyaltyCardDetails': false,
    'enable-gateway-getLoyaltyCardDetailsKiosk': false,
    'enable-gateway-getLoyaltyCards': false,
    'enable-gateway-getLoyaltyCardTransactions': false,
    'enable-gateway-getPrepaidsBalance': false,
    'enable-gateway-gifts': false,
    'enable-gateway-loyalty': false,
    'enable-gateway-me': false,
    'enable-gateway-mergeLoyaltyCards': false,
    'enable-gateway-mergePrepaid': false,
    'enable-gateway-prepaidsMerge': false,
    'enable-gateway-prepaidsReload': false,
    'enable-gateway-prepaidsTransactions': false,
    'enable-gateway-purchasePrepaid': false,
    'enable-gateway-restaurants': false,
    'enable-gateway-setLoyaltyDonationUpcharge': false,
    'enable-gateway-setLoyaltyRewardsTier': false,
    'enable-gateway-setLoyaltyScanAndPay': false,
    'enable-gateway-updateMe': false,
    'enable-gateway-userAccounts': false,

    // Flags that should always be on.
    'enable-gateway-createOTP': true,
    'enable-gateway-deliveryRestaurant': true,
    'enable-gateway-evaluateAllUserOffers': true,
    'enable-gateway-evaluateUserOffers': true,
    'enable-gateway-exchangeOTPCodeForCognitoCredentials': true,
    'enable-gateway-listUserCouponTokens': true,
    'enable-gateway-redeemCoupon': true,
    'enable-gateway-requestMyInfo': true,
    'enable-gateway-resendCurrentLoginOTP': true,
    'enable-gateway-resendVerificationEmail': true,
    'enable-gateway-restaurant': true,
    'enable-gateway-signInJWT': true,
    'enable-gateway-signUp': true,
    'enable-gateway-signUpComplete': true,
    'enable-gateway-userFavorites': true,
    'enable-gateway-validateAuthJwt': true,
  };

  const helper = LaunchDarklyHelper.getInstance();

  // apollo does not allow this function to be async...
  // we cannot request client.allFlags() here either because it might not be client.configure()'d yet causing an app crash
  const ldFlags = {
    ...GATEWAY_FLAGS,
    ...helper.allFlagsSync,
  };

  // Check if operation is enabled based on LD flag
  const flagName = (flagArg?.value as StringValueNode).value;
  return ldFlags[flagName] === true;
};
