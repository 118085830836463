import { differenceInCalendarMonths } from 'date-fns';
import { IntlFormatters } from 'react-intl';

import {
  IAddCreditCardFormErrors,
  IAddCreditCardFormValues,
} from 'components/payments/components/add-credit-card-form-inputs/types';
import { CardBrand } from 'generated/graphql-gateway';
import { sanitizeNumber } from 'utils/form';

import { splitExpiry } from './split-expiry';

const visaRegEx: RegExp = /^4[0-9]{12}(?:[0-9]{3})?$/;
const mastercardRegEx: RegExp = /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/;
const amexpRegEx: RegExp = /^3[47][0-9]{13}$/;
const discoverRegEx: RegExp = /^6(?:(011|5[0-9]{2}))[0-9]{12}|62[0-9]{14}$/;
const jcbRegex: RegExp = /^(?:2131|2100|1800|35\d{3})\d{11}$/;
const dinersclubRegex: RegExp = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;

export function validateCreditCardNumber(
  cardNumber: string,
  supportedCardTypes: CardBrand[]
): { isValid: boolean; cardBrand?: CardBrand } {
  let cardBrand: CardBrand | undefined;
  if (visaRegEx.test(cardNumber)) {
    cardBrand = CardBrand.VISA;
  } else if (mastercardRegEx.test(cardNumber)) {
    cardBrand = CardBrand.MASTERCARD;
  } else if (amexpRegEx.test(cardNumber)) {
    cardBrand = CardBrand.AMEX;
  } else if (discoverRegEx.test(cardNumber)) {
    cardBrand = CardBrand.DISCOVER;
  } else if (jcbRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardBrand = CardBrand.JCB;
  } else if (dinersclubRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardBrand = CardBrand.DINERS_CLUB;
  }

  return {
    isValid: Boolean(cardBrand && supportedCardTypes.includes(cardBrand)),
    cardBrand,
  };
}

export const validateExpiry = (expiryMonthAndYear: string) => {
  const { expiryMonth, expiryYear } = splitExpiry(expiryMonthAndYear);
  const monthNumber = Number(expiryMonth);
  if (monthNumber < 1 || monthNumber > 12) {
    return false;
  }
  const now: Date = new Date();
  const expiry: Date = new Date(Number(`20${expiryYear}`), monthNumber - 1);
  return differenceInCalendarMonths(expiry, now) >= 0;
};

enum FormFields {
  CARD_NUMBER = 'cardNumber',
  EXPIRY = 'expiry',
  BILLING_ZIP = 'billingZip',
  ADDRESS_SAME_AS_DELIVERY = 'billingAddressSameAsDelivery',
}

export const onCreditCardFormChange = (
  name: string,
  value: string,
  state: IAddCreditCardFormValues,
  formErrors: IAddCreditCardFormErrors,
  formatMessage: IntlFormatters['formatMessage'],
  supportedCardTypes: CardBrand[]
): { state: IAddCreditCardFormValues; formErrors: IAddCreditCardFormErrors } => {
  if (name === FormFields.CARD_NUMBER) {
    const sanitizedNumber = sanitizeNumber(value);

    const { cardBrand, isValid } = validateCreditCardNumber(sanitizedNumber, supportedCardTypes);

    formErrors.cardBrand = '';
    if (cardBrand && !isValid) {
      formErrors.cardBrand = formatMessage({ id: 'unsupportedCardBrand' }, { cardBrand });
    }

    if (isValid) {
      formErrors.cardNumber = '';
    }

    state.cardNumber = sanitizedNumber;
    state.cardBrand = cardBrand ?? '';
    state.isCardNumberValid = isValid;
  } else if (name === FormFields.EXPIRY) {
    formErrors.expiry = '';
    state.expiry = value;
    state.isExpiryValid = validateExpiry(value);
  } else if (name === FormFields.BILLING_ZIP) {
    formErrors.billingZip = '';
    state.billingZip = value.toUpperCase();
  } else if (name === FormFields.ADDRESS_SAME_AS_DELIVERY) {
    state.billingAddressSameAsDelivery = !state.billingAddressSameAsDelivery;
    if (!state.billingAddressSameAsDelivery) {
      state.billingStreetAddress = '';
      state.billingApt = '';
      state.billingCity = '';
      state.billingState = '';
      state.billingZip = '';
    }
  } else {
    if (typeof state[name] === 'boolean') {
      state[name] = !state[name];
    } else {
      formErrors[name] = '';
      state[name] = value;
    }
  }
  return { state, formErrors };
};
