import { router } from 'expo-router';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { InlineAlert } from '@fhs-legacy/universal-components';
import { AddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/add-credit-card-form-inputs';
import { useAddCreditCardFormInputs } from 'components/payments/components/add-credit-card-form-inputs/hooks/use-add-credit-card-form-inputs';
import { IAddCreditCardFormValues } from 'components/payments/components/add-credit-card-form-inputs/types';
import { getCreditCardFormErrors } from 'components/payments/components/add-credit-card-form-inputs/utils';
import { PaymentMethodDropdown } from 'components/payments/components/payment-method-dropdown/payment-method-dropdown';
import {
  PaymentMethod,
  PaymentMethodOptionTypes,
} from 'components/payments/integrations/hooks/types';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { routes } from 'utils/routing';

import { RELOAD_EXPLANATION_TRANSLATION_ID } from './constants';
import { InstructionText, ReloadWrapper } from './styled';

interface IPrepaidReloadProps {
  reloadAmount: number;
  formattedReloadAmount: string;
  reloadPaymentMethods: PaymentMethod[];
  selectedReloadPaymentMethod: PaymentMethod | undefined;
  setSelectedReloadPaymentMethod: (pm: PaymentMethod | undefined) => void;
  setCreditCardReloadFormValues: (values: IAddCreditCardFormValues | undefined) => void;
}

export const PrepaidReload: React.FC<IPrepaidReloadProps> = ({
  reloadAmount,
  formattedReloadAmount,
  reloadPaymentMethods,
  selectedReloadPaymentMethod,
  setSelectedReloadPaymentMethod,
  setCreditCardReloadFormValues,
}) => {
  const { formatMessage } = useIntl();
  const { feCountryCode: billingCountry } = useLocale();

  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const {
    creditCardFormValues,
    creditCardFormErrors,
    handleCreditCardFormChanges,
    setCreditCardFormErrors,
  } = useAddCreditCardFormInputs({ paymentFieldVariations, billingCountry });

  const onCreditCardFormChange = useCallback(
    (name: string, value: string) => {
      handleCreditCardFormChanges(name, value);
      const { hasErrors, formErrors } = getCreditCardFormErrors(
        creditCardFormValues,
        creditCardFormErrors,
        formatMessage,
        paymentFieldVariations,
        billingCountry
      );

      if (hasErrors) {
        setCreditCardFormErrors(prevState => ({ ...prevState, ...formErrors }));
      } else {
        setSelectedReloadPaymentMethod(undefined);
        setCreditCardReloadFormValues(creditCardFormValues);
      }
    },
    [
      handleCreditCardFormChanges,
      creditCardFormValues,
      creditCardFormErrors,
      formatMessage,
      paymentFieldVariations,
      billingCountry,
      setCreditCardFormErrors,
      setSelectedReloadPaymentMethod,
      setCreditCardReloadFormValues,
    ]
  );

  const handleReloadPaymentMethodSelection = (pm: PaymentMethod) => {
    if (pm.type === PaymentMethodOptionTypes.ADD_CREDIT_CARD) {
      router.navigate(routes.cartAddCard);
    } else {
      setSelectedReloadPaymentMethod(pm);
    }
  };

  const reloadExplanationText = formatMessage(
    { id: RELOAD_EXPLANATION_TRANSLATION_ID, description: {} },
    { reloadAmount: formattedReloadAmount }
  );
  const youWillBeChargedText = formatMessage(
    { id: 'youWillBeCharged', description: {} },
    { reloadAmount: formattedReloadAmount }
  );
  const reloadExplanationAndChargedAmountText = `${reloadExplanationText} ${youWillBeChargedText}`;

  return (
    <>
      {reloadAmount > 0 && (
        <ReloadWrapper>
          <InlineAlert message={reloadExplanationAndChargedAmountText} status="info" />
          {selectedReloadPaymentMethod ? (
            <>
              <InstructionText>
                {formatMessage({ id: 'loadWith', description: {} })}:
              </InstructionText>
              <PaymentMethodDropdown
                aria-expanded="true"
                loading={false}
                handleSelection={handleReloadPaymentMethodSelection}
                paymentMethods={reloadPaymentMethods}
                selectedPaymentMethod={selectedReloadPaymentMethod}
                enableAddCreditCardOption={true}
                enableAddGiftCardOption={false}
              />
            </>
          ) : null}
          {!reloadPaymentMethods.length ? (
            <>
              <InstructionText>
                {formatMessage({ id: 'addCreditCard', description: {} })}:
              </InstructionText>
              <AddCreditCardFormInputs
                onChange={onCreditCardFormChange}
                values={creditCardFormValues}
                errors={creditCardFormErrors}
              />
            </>
          ) : null}
        </ReloadWrapper>
      )}
    </>
  );
};
