import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { DeliveryTerms } from 'components/address-modal/delivery-terms';
import { StoreChangeDisclaimer } from 'pages/store-locator/store-change-disclaimer';

import { ButtonContainer, DeliveryInfoContainer, LoadingText } from './styled';

interface IDeliveryFormFooterProps {
  submitAddress: () => void;
  setCreateAddressView: (show: boolean) => void;
  showDeliveryAddresses: boolean;
  isDisabled: boolean;
  isLoading: boolean;
}

const DeliveryAddressFormFooter: React.FC<React.PropsWithChildren<IDeliveryFormFooterProps>> = ({
  submitAddress,
  setCreateAddressView,
  showDeliveryAddresses,
  isDisabled,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <DeliveryInfoContainer>
        <StoreChangeDisclaimer />
        <DeliveryTerms />
      </DeliveryInfoContainer>
      <Box>
        <ButtonContainer>
          <ActionButton
            onPress={submitAddress}
            fullWidth
            disabled={isLoading || isDisabled}
            isLoading={isLoading}
            testID="deliver-here"
            _text={{ fontSize: 'lg' }}
          >
            {formatMessage({ id: 'deliverHere' })}
          </ActionButton>
        </ButtonContainer>
        {showDeliveryAddresses && (
          <ButtonContainer>
            <ActionButton
              testID="back-to-saved-addresses"
              fullWidth
              variant={ActionButtonVariants.OUTLINE}
              onPress={() => setCreateAddressView(false)}
              _text={{ fontSize: 'md' }}
            >
              {formatMessage({ id: 'backToSavedAddresses' })}
            </ActionButton>
          </ButtonContainer>
        )}
      </Box>
      {isLoading && (
        <Box>
          <LoadingText>{formatMessage({ id: 'deliveryCheckingMessage' })}</LoadingText>
        </Box>
      )}
    </>
  );
};

export default DeliveryAddressFormFooter;
