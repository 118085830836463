import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, KeyboardAvoidingView } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import { ModalContent } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import GiftCardImage from 'components/payments/components/gift-card-image/gift-card-image';
import { TextInput } from 'components/ucl';

import { useFirstDataAddGiftCard } from './hooks/use-first-data-add-gift-card';

export const AddGiftCardModal = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    addGiftCard,
    ErrorDialog,
    handleCloseModal,
    mergePrepaidLoading,
    isValidGiftCardNumber,
    handleCardNumberChange,
    giftCardNumberErrors,
  } = useFirstDataAddGiftCard();

  const [value, setValue] = useState('');

  const handleOnChange = (changedValue: string) => {
    handleCardNumberChange(changedValue);
    setValue(changedValue);
  };

  const modalBtnText = `${formatMessage({ id: 'save' })} & ${formatMessage({ id: 'continue' })}`;

  return (
    <Modal
      onDismiss={handleCloseModal}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Add gift card',
      }}
      header={
        <Header fontSize="3xl" lineHeight="4xl" testID="modal-heading" textAlign="center">
          {formatMessage({ id: 'addNewPaymentMethodGiftCard' })}
        </Header>
      }
    >
      <KeyboardAvoidingView behavior="position">
        <ModalContent>
          <Box testID="add-giftcard-form" padding="$6" alignItems="center">
            <GiftCardImage />
            <TextInput
              type="text"
              label={formatMessage({ id: 'giftCardNumber' })}
              value={value}
              onChangeText={handleOnChange}
              errorMessage={giftCardNumberErrors?.giftCardNumber}
              autoFocus
              required
              testID="add-giftcard-input"
            />
            <ActionButton
              fullWidth
              testID="save-and-continue"
              isLoading={mergePrepaidLoading}
              disabled={!isValidGiftCardNumber}
              onPress={addGiftCard}
              marginTop="$6"
            >
              {modalBtnText}
            </ActionButton>
          </Box>
        </ModalContent>
      </KeyboardAvoidingView>

      <ErrorDialog />
    </Modal>
  );
};
