import { Link } from 'expo-router';
import { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Text, tokens } from '@fhs/ui';

import { SetDonationAmount, useMutation } from '../../screens/client';

// const background = require('./background.png');
// const logo = require('./logo.png');

type Props = {
  donationAmount: number;
};

const donationValues = [
  { label: 'None', value: 0 },
  { label: '$0.50', value: 50 },
  { label: '$1.00', value: 100 },
  { label: '$5.00', value: 500 },
];

export function DonationSwitch(props: Props) {
  const [donationAmount, setDonationAmount] = useState(props.donationAmount);
  const [, updateDonationAmount] = useMutation(SetDonationAmount);

  return (
    <View style={styles.container}>
      {/* <Image source={background} style={styles.backgroundImage} /> */}
      <View style={styles.backgroundImageFade} />
      <View style={styles.top}>
        {/* <Image source={logo} style={styles.logo} /> */}
        <Text.Heading type="two" style={styles.heading}>
          HELP SAVE LIVES
        </Text.Heading>
        <Link href="#" asChild>
          <Pressable>
            <Text.Ui size="xs" style={styles.learnMore}>
              Learn More
            </Text.Ui>
          </Pressable>
        </Link>
      </View>

      <SegmentedControl
        options={donationValues}
        value={donationAmount}
        onSelected={newAmount => {
          setDonationAmount(newAmount);
          updateDonationAmount({ donationAmount: newAmount }).catch(() => {
            alert('an error occurred updating your donation amount');
            setDonationAmount(props.donationAmount);
          });
        }}
      />
    </View>
  );
}

type Option<V> = { label: string; value: V };
function SegmentedControl<V extends any>(props: {
  options: Option<V>[];
  value: V;
  onSelected(value: V): void;
}) {
  return (
    <View style={styles.segmentedContainer}>
      {props.options.map(v => (
        <Pressable
          key={v.value + v.label}
          onPress={() => props.onSelected(v.value)}
          style={[styles.segmentedButton, props.value === v.value && styles.selectedSegmentButton]}
        >
          <Text.Ui
            size="sm"
            style={[props.value === v.value && styles.selectedSegmentedButtonText]}
          >
            {v.label}
          </Text.Ui>
        </Pressable>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  learnMore: {
    color: tokens.colors.$white,
    textDecorationLine: 'underline',
  },
  heading: {
    color: tokens.colors.$white,
    padding: 8,
    flex: 1,
  },
  top: {
    height: 47,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 46,
    height: 47,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  backgroundImageFade: {
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  container: {
    gap: 12,
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 131,
    width: 343,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
  },

  // Segmented Controls
  segmentedContainer: {
    borderRadius: 4,
    width: '100%',
    height: 48,
    overflow: 'hidden',
    gap: 1,
    backgroundColor: tokens.colors.$black10,
    flexDirection: 'row',
  },

  segmentedButton: {
    backgroundColor: tokens.colors.$white,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  selectedSegmentButton: {
    backgroundColor: tokens.colors.$black,
  },
  selectedSegmentedButtonText: {
    color: tokens.colors.$white,
  },
});
