import { IntlFormatters } from 'react-intl';

import { IAddCreditCardFormErrors } from 'components/payments/components/add-credit-card-form-inputs/types';

interface GetErrorMessageOptions {
  errors: IAddCreditCardFormErrors;
  formatMessage: IntlFormatters['formatMessage'];
}

export const getErrorMessage = ({
  errors,
  formatMessage,
}: GetErrorMessageOptions): string | null => {
  const errorKeys = Object.keys(errors);
  const numberOfErrors = errorKeys.filter(error => errors[error]).length;
  if (!numberOfErrors) {
    return null;
  }
  const id = numberOfErrors === 1 ? 'thereIsAnErrorInThisForm' : 'thereAreErrorsInThisForm';
  return numberOfErrors ? formatMessage({ id }, { numberOfErrors }) : null;
};
