import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';
import { LogLevel } from 'utils/logger';

import { brand, env, getCurrentCommitHash, isSSG } from '../environment';

import { IDatadogLogger } from './types';

const UNKNOWN_ERROR_MESSAGE: string = 'Unknown application error. Unable to parse error message.';

export function dataDogLogger({ message, context = {}, status }: IDatadogLogger): void {
  if (isSSG) {
    return;
  }
  const currentEnv = env();
  const ctx = {
    appVersionCode: getCurrentCommitHash(),
    commit: getCurrentCommitHash(),
    brand: brand(),
    platform: 'web',
    stage: currentEnv,
    userId: AuthStorage?.getItem(StorageKeys.USER_AUTH_TOKEN) ?? undefined,
    ...context,
  };

  const strMessage: string =
    typeof message === 'string' ? message : message?.message || UNKNOWN_ERROR_MESSAGE;

  switch (status) {
    case LogLevel.trace:
    case LogLevel.debug:
      datadogLogs.logger.debug(strMessage, ctx);
      break;
    case LogLevel.info:
      datadogLogs.logger.info(strMessage, ctx);
      break;
    case LogLevel.warn:
      datadogLogs.logger.warn(strMessage, ctx);
      break;
    case LogLevel.error:
    case LogLevel.fatal:
      // @ts-ignore
      const error = ctx && ctx.error;
      datadogRum.addError(error || strMessage, ctx);
      break;
    default:
      void datadogLogs.logger.warn(`Log level ${status} not supported`);
  }
}

export function addContext(key: string, value: string) {
  datadogLogs.logger.addContext(key, value);
}

export function setUser(user: { id: string }) {
  if (user.id) {
    datadogRum.setUser(user);
  } else {
    datadogRum.removeUser();
  }
}
