import React from 'react';

import { usePaymentMethodsAdapter } from 'components/payments/integrations/hooks/use-payment-methods-adapter';
import { useUpdateDefaultPaymentMethod } from 'components/payments/integrations/hooks/use-update-default-payment-method';

import { PaymentMethodFlatListWithButton } from '../../components/payment-method-flat-list-with-button';
import { useDeletePaymentMethod } from '../hooks/use-delete-payment-method';

const WorldpayPaymentMethods: React.FC = () => {
  const { availablePaymentMethods, defaultPaymentMethod, loading } = usePaymentMethodsAdapter();

  const { isDeleting, deletePaymentMethod } = useDeletePaymentMethod();
  const { updateDefaultPaymentMethod } = useUpdateDefaultPaymentMethod();

  return (
    <PaymentMethodFlatListWithButton
      availablePaymentMethods={availablePaymentMethods}
      defaultPaymentMethod={defaultPaymentMethod}
      deletePaymentMethod={deletePaymentMethod}
      loading={loading || isDeleting}
      updateDefaultPaymentMethod={updateDefaultPaymentMethod}
    />
  );
};

export default WorldpayPaymentMethods;
