import { TipAmounts } from 'state/order';

/**
 * Checks if the tip amount entered by the user is valid
 * @param amount tip amount
 * @param subTotalCents order subtotal in cents
 * @returns {boolean} whether the current tip amount is valid
 */
export const checkIfValidTip = ({ amount }: { amount?: number }) => {
  const isTipLessThanMax: boolean = Number(amount) <= TipAmounts.MAX_AMOUNT;
  return isTipLessThanMax;
};
