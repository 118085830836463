import { router, useLocalSearchParams } from 'expo-router';
import { View } from 'react-native';

import { AddItemToCart, useMutation } from '@fhs/cart';
import { Button, NotFound } from '@fhs/ui';

import { MenuItemDetails } from '../components/menu-item-details';
import { getMenuItemData } from '../data/__mocks__/menu-item-data';
import type { ScreenMenuItemProps } from '../types';

export function ScreenMenuItem(props: ScreenMenuItemProps) {
  const [{ fetching }, addItemToCart] = useMutation(AddItemToCart, { itemId: props.item.id });

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <MenuItemDetails item={props.item} />
      </View>
      <View style={{ padding: 16 }}>
        <Button
          loading={fetching}
          onPress={async () => {
            await addItemToCart();
            router.push('/v2/menu');
          }}
        >
          <Button.Text>Add To Cart</Button.Text>
        </Button>
      </View>
    </View>
  );
}

export function ScreenMenuItemWithMock() {
  const params = useLocalSearchParams<{ slug: string }>();
  const menuItemData = getMenuItemData(params.slug);
  if (!menuItemData) {
    return <NotFound buttonText="Go To Menu" buttonUrl="/v2/menu" />;
  }

  return <ScreenMenuItem item={menuItemData} />;
}
