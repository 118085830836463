import { differenceInMinutes } from 'date-fns';
import { camelCase, capitalize, isNil } from 'lodash';
import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

import { Box } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import { DiscountTypes } from 'enums/menu';
import {
  IOffersEligibleItemsFragment,
  IOffersFragment,
  IRewardFragment,
  PromotionType,
  ServiceMode,
} from 'generated/graphql-gateway';
import { CartEntryType } from 'utils/cart/types';

import {
  IIncentiveEvaluationResult,
  IncentiveEligibleItem,
  IncentiveEvaluationErrorCodes,
  IncentiveEvaluationMap,
} from '../types';

import { makeIncentiveOutOfDayPartMessage } from './incentives-availability';

interface IEvaluationResultMessageParams {
  evaluationResult: IIncentiveEvaluationResult;
  formatters: IntlShape;
  incentiveType: PromotionType;
}

export const getEvaluationResultMessage = ({
  evaluationResult,
  formatters,
  incentiveType: type,
}: IEvaluationResultMessageParams): string | ReactNode => {
  const { formatDate, formatMessage, formatTime } = formatters;
  const { code, currentValue, targetValue } = evaluationResult;
  const incentiveType = type.toLowerCase();
  let message: string | ReactNode = formatMessage(
    { id: 'incentiveFeedbackDefault' },
    { incentiveType }
  );

  switch (code) {
    case IncentiveEvaluationErrorCodes.BELOW_MINIMUM_SPEND: {
      if (isNil(currentValue) || isNil(targetValue)) {
        break;
      }

      message = formatMessage(
        { id: 'incentiveFeedbackSubtotal' },
        {
          incentiveType,
          currentValue: `${currentValue / 100}`,
          targetValue: `${targetValue / 100}`,
          calculatedValue: `${(targetValue - currentValue) / 100}`,
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_DAY_OF_THE_WEEK: {
      if (!targetValue?.length) {
        break;
      }
      message = formatMessage(
        {
          id: 'incentiveFeedbackInvalidDay',
        },
        {
          incentiveType,
          targetValue:
            targetValue.length > 1 ? (
              <Box>
                {targetValue.map((day: string) => (
                  <Box key={day}>{`${formatMessage({ id: day })}`}</Box>
                ))}
              </Box>
            ) : (
              formatMessage({ id: targetValue[0] })
            ),
        }
      );
      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_TIME_SPAN: {
      const { endTime, startTime } = targetValue;
      if (!startTime || !endTime) {
        break;
      }

      message = formatMessage(
        {
          id: 'incentiveFeedbackInvalidTime',
        },
        {
          incentiveType,
          startTime: formatTime(timeToDate(startTime)),
          endTime: formatTime(timeToDate(endTime)),
        }
      );
      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_END_DATE: {
      const { endDate } = evaluationResult.targetValue;
      if (!endDate) {
        break;
      }

      message = formatMessage(
        {
          id: 'incentiveFeedbackExpired',
        },
        {
          incentiveType,
          endDate: formatDate(endDate, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE: {
      const serviceModes = targetValue;
      if (!serviceModes?.length) {
        break;
      }

      const messageKey = serviceModes.some((sm: ServiceMode) => sm === ServiceMode.DELIVERY)
        ? ServiceMode.DELIVERY
        : ServiceMode.TAKEOUT;

      message = formatMessage(
        {
          id: 'incentiveFeedbackServiceMode',
        },
        {
          incentiveType,
          targetValue: formatMessage({ id: serviceModeLocalizedKeysMap[messageKey] }),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD: {
      const paymentMethod = targetValue;
      if (!paymentMethod) {
        break;
      }
      const formattedMessage = formatMessage({ id: camelCase(paymentMethod) });

      message = formatMessage(
        {
          id: 'incentiveFeedbackPaymentMethod',
        },
        {
          incentiveType,
          targetValue: capitalize(formattedMessage),
        }
      );

      break;
    }

    case IncentiveEvaluationErrorCodes.EXCEEDS_MAX_REDEMPTION: {
      message = formatMessage({ id: 'incentiveFeedbackMaxRedemptions' }, { incentiveType });
      break;
    }

    case IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS: {
      message = formatMessage({ id: 'incentiveFeedbackCartRequirement' }, { incentiveType });
      break;
    }

    case IncentiveEvaluationErrorCodes.INVALID_STORE_ID: {
      message = formatMessage({ id: 'incentiveFeedbackInvalidStore' }, { incentiveType });
      break;
    }

    case IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART: {
      const { incentive, dayParts } = targetValue;
      message = makeIncentiveOutOfDayPartMessage({ incentive, dayParts, formatters });
      break;
    }

    case IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE: {
      message = formatMessage({ id: 'incentiveFeedbackNotAvailableInStore' });
      break;
    }

    case IncentiveEvaluationErrorCodes.OFFER_NOT_AVAILABLE: {
      message = formatMessage({ id: 'incentiveFeedbackOfferNotAvailable' });
      break;
    }

    case IncentiveEvaluationErrorCodes.WITHIN_COOL_DOWN_PERIOD: {
      const calculatedValue = differenceInMinutes(new Date(targetValue), new Date(currentValue));

      if (!isNaN(calculatedValue)) {
        message = formatMessage(
          { id: 'incentiveFeedbackWithinCooldown' },
          { calculatedValue, incentiveType }
        );
      }
      break;
    }

    // All errors will be transformed into messages here
    default: {
      return message;
    }
  }

  return message;
};

type IncentiveType = IRewardFragment | IOffersFragment;

export const createIncentivesFeedbackMaps = (
  incentives: readonly IncentiveType[]
): {
  incentiveFeedbackMap: IncentiveEvaluationMap;
  incentivesMapById: { [key in string]: string };
} => {
  const { incentiveFeedbackMap, incentivesMapById } = incentives.reduce(
    (mapsAccum, incentive) => {
      const incentiveId = incentive.id;

      mapsAccum.incentivesMapById[incentiveId] = incentiveId;
      if (incentive?.errors?.length) {
        mapsAccum.incentiveFeedbackMap[incentiveId] = incentive.errors;
      }

      return mapsAccum;
    },
    { incentiveFeedbackMap: {}, incentivesMapById: {} }
  );

  return { incentiveFeedbackMap, incentivesMapById };
};

export const createIncentiveEligibleItems = (
  incentives: readonly IOffersEligibleItemsFragment[]
): IncentiveEligibleItem[] => {
  const incentiveEligibleItems = incentives.reduce<IncentiveEligibleItem[]>(
    (eligibleItems, incentive) => {
      const incentiveId = incentive.id;

      incentive?.eligibleItems?.forEach(eligibleItem => {
        if (eligibleItem?.lineId && eligibleItem.timesApplied) {
          eligibleItems.push({
            incentiveId,
            lineId: eligibleItem.lineId,
            timesApplied: eligibleItem.timesApplied,
          });
        }
      });

      return eligibleItems;
    },
    []
  );

  return incentiveEligibleItems;
};

const timeToDate = (time: string): Date => {
  const today = new Date();

  const [hr, min, sec] = time.split(':');
  today.setHours(+hr);
  today.setMinutes(+min);
  today.setSeconds(+sec);

  return today;
};

const serviceModeLocalizedKeysMap = {
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.CATERING_DELIVERY]: 'cateringDelivery',
  [ServiceMode.CATERING_PICKUP]: 'cateringPickUp',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'pickUp',
};

export const getServerOrderSubtotalBeforeOfferDiscounts = (serverOrder: IServerOrder | null) => {
  if (!serverOrder?.cart) {
    return 0;
  }
  const hasOfferDiscountApplied = serverOrder.cart?.cartEntries?.some(
    entry => entry.type === CartEntryType.offerDiscount
  );
  const orderDiscounts = serverOrder.cart?.discounts ?? [];
  const orderSubtotalCents = serverOrder.cart?.subTotalCents ?? 0;
  const offersDiscountsCents = orderDiscounts.reduce(
    (acc, discount) =>
      discount.name === DiscountTypes.COMBO_AND_OFFER_DISCOUNTS ? acc + discount.value : acc,
    0
  );

  return hasOfferDiscountApplied ? orderSubtotalCents + offersDiscountsCents : orderSubtotalCents;
};
