import React from 'react';

import { PaymentIntegrationType } from 'components/payments/entry-points/types';
import { FirstDataAddCreditCardModal } from 'components/payments/integrations/firstdata/components/firstdata-add-credit-card-modal';
import { OrbitalAddCreditCardModal } from 'components/payments/integrations/orbital/components/orbital-add-credit-card-modal';
import { WorldPayAddCreditCardModal } from 'components/payments/integrations/worldpay/components/worldpay-add-credit-card-modal/worldpay-add-credit-card-modal';
import { usePaymentConfigQuery } from 'generated/graphql-gateway';

type AddCreditCardModalMapping = Record<string, React.FC>;
const addCreditCardModalMapping: AddCreditCardModalMapping = {
  [PaymentIntegrationType.FIRSTDATA]: FirstDataAddCreditCardModal,
  [PaymentIntegrationType.ORBITAL]: OrbitalAddCreditCardModal,
  [PaymentIntegrationType.WORLDPAY]: WorldPayAddCreditCardModal,
};

export const AddCreditCardModalEntryPoint: React.FC = () => {
  const { data } = usePaymentConfigQuery();
  const paymentIntegration = data?.paymentConfig.frontendIntegration;

  // TODO: Payments Refactor - Determine what to display should paymentIntegration be undefined/null
  if (!paymentIntegration) {
    return null;
  }

  // Determine which payment integration to display for collecting a payment in checkout.
  const AddCreditCardModal = addCreditCardModalMapping[paymentIntegration];

  return <AddCreditCardModal />;
};

export default AddCreditCardModalEntryPoint;
